/* Slide Up style */
.slide-up {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background: rgba(233, 233, 233, 1);
  z-index: 9000;
  margin: 1rem 0 0 0;
  padding: 3rem 0 ;
  @include transition(opacity .2s ease-in-out, transform .2s ease-in-out, visibility .2s ease-in-out);
  .slide-up-close {
    position: absolute;
    right: 1rem;
    top: 1rem;
    z-index: 100;
    cursor: pointer;
    color: #fff;
    border-radius: 50%;
    width: 3rem;
    background: #262626;
    line-height: 3rem;
    text-align: center;
    @include bp-tablet {
      right: 2rem;
      top: 2rem;
    }
  }
  button.slide-up-close {
    width: 3rem;
    height: 3rem;
    background: $blue;
    border: none;
    i {
      font-size: 1.5rem;
      line-height: 3rem;
    }
    &:hover, &:focus {
      background-color: $dark-blue;
      box-shadow: inset 0 0 0 0.1rem #ffffff;
    }
  }
  button.slide-up-submit {
    @extend %ntux-button;
  }
  label {
    font-size: 2rem;
    color: $gray-base;
    font-weight: 800;
    line-height: 1.2;
    margin-top: 10px;
  }
  body.side-panel-open & {
    width: calc(100% - 30rem);
  }
  body.side-panel-open.menu-open & {
    width: 100%;
  }
  body.side-panel-open.menu-open .main-ui-view #mainContent & {
    width: 100%;
    position: relative;
    margin: 0px;
    box-sizing:border-box;
    overflow-y: hidden;
    max-height: 0px;
    padding: 0 0;
    float: left;
    top: 0;
    @include transition(all .3s ease-out);
    &.open {
      max-height: 500px;
      @include transition(all .3s ease-in);
      padding: 3rem 0;
    }
  }
  body.side-panel-open.menu-open .main-ui-view & {
    position: absolute;
    top: -1rem;
    &#quick-add, &#quick-msg {
      height: 22rem;
    }
  }
  textarea {
    &:focus {
      background: #ffffff;
      outline: none;
      border: 1px solid #00a8cc;
    }
  }
  &-content {
    text-align: left;
    position:relative;
    max-width: 90%;
    margin:0 auto;
    @include bp-tablet {
      max-width: 70%;
    }
    @include bp-desktop {
      max-width: 60%;
    }
    &>p {
      padding-left: .5rem;
      text-align: left;
    }
    &>p, &>p strong {
      font-size: 1.6rem;
      margin-bottom: 2rem;
    }
    .easy-write {
      background:none;
      border: none;
      margin-top: 3rem;
      &-status {
        margin-top: 0rem;
        position: absolute;
        p:before {
          content: '\e806';
          font-family: utestfont;
          font-size: 1.3rem;
          color: #22c064;
          margin-right: 1rem;
          display: inline
        }
        .btn {
          margin-left: 1rem;
          color: #262626;
        }
      }
      &-content {
        .write-status {
          width: 100%;
          @include bp-tablet {
            width: calc(100% - 6rem);
          }
        }
      }
    }
    .comment-form {
      label {
        font-weight: 700;
      }
      &-rating {
        .project &, .article &, .discussion &, .status & {
          display: none !important;
        }
        h4 {
          margin-bottom: 1rem;
        }
      }
      &-reply {
        .reply, .reply-preview {
          width: 100%;
          height: 20rem;
          margin: .5rem 0;
          background:#fff;
          border-radius:0.3rem;
          padding:1rem;
        }
        &-wrapper {
          position: relative;
        }
        .reply-preview {
          border:0;
          position: absolute;
          overflow:auto;
          top:0;
          left:0;
        }
        .reply-guide {
          h4 {
            margin-top: 0;
          }
          h6 {
            margin-bottom: 1rem;
          }
          .code-example {
            padding:1rem;
            border: 1px solid #ccc;
            border-radius:.3rem;
            margin:0 2rem 0 0;
          }
          img {
            margin:0 0 1.5rem 0;
          }
        }
        .reply-emoji {
          text-align: center;
          img {
            cursor: pointer;
            margin:.5rem;
          }
        }
        &-actions {
          text-align: right;
          margin-bottom: -0.5rem;
          button {
            border:0;
            background:none;
            opacity:0.4;
            transition:opacity 200ms;
            padding: 0.2rem;
            &:hover, &.active {
              opacity:0.8;
            }
          }
          img {
            height:1.8rem;
            margin:0;
          }
          .comment-form-reply-edit-action {
            img {
              height:1.5rem;
              padding: 0rem .2rem .4rem;
            }
          }
        }
      }
      &-btn {
        text-align: center;
        .btn {
          margin-top: 2rem;
        }
      }
    }
  }
  &.flag-form {
    h4 {
      padding-left:0;
      padding-right:0;
    }
    * {
      font-size: 1.4rem !important;
    }
  }
}
