$font-family-sans-serif: 'proxima-nova', sans-serif;

$container-large-desktop: 930px;
$container-desktop: 930px;

$navbar-height: 70px;
$navbar-margin-bottom: 0;
$navbar-collapse-max-height: 4000px;

// high contrast background color
// for when the search element 
// has focus or text in it
$navbar-search-hc-bg: #005EB8;

$nav-tabs-active-link-hover-color: #262626;

$gray-base: #262626;
$gray-darker: #2d3234;
$gray-dark: #3c4042;
$gray-cc-dark: #757575;
$gray: #bac1c6;
$gray-shade-1: #f6f8f9;
$gray-shade-2: #34495e;
$gray-light: #d2d6d9;
$gray-lighter: #eeeeee;
$gray-lightest: #f6f8f9;
$gray-on-white: #697886;
$white: #fff;
$green-base: #22c064;
$blue: #027CC2;
$dark-blue: #015e93;
$light-blue: #029AF2;
$lightest-blue: #c7e4f4;
$applause-yellow: #FCBA00;

$gray-500: #86878A;
$gray-600: #75767a;
$gray-700: #595959;

$text-color: $gray-base;

$input-border-focus: $gray-light;
$brand-primary: #00a8cc;
$brand-primary-darker: rgba(9, 111,134, 1);

$input-color: $gray-dark;

$facebook-main: #3b5998;
$twitter-main: #55acee;
$linkedin-main: #007bb5;

$flag-icon-color: #ef5454;
$follow-icon-color: #2ebf68;

$icon-color: #888888;
$icon-border-red: #f6573c;
$icon-border-yellow: #fcba00;
$icon-border-green: #338037;
$icon-color-red: #D93D22;
$icon-color-orange: #CC8500;

$action-disabled-color: #9B9B9B;
