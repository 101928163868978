.ut-aside {
  background:$gray-base;
  width:30rem;
  height:100%;
  overflow:auto;
  position: absolute;
  z-index:0;
  .brand {
    padding-bottom:2.1rem;
    text-align: center;
    margin-bottom:2rem;
    border-bottom:.1rem solid $gray-dark;
    .close {
      float:left;
      color:#fff;
    }
    img {
      max-width: 30%;
      max-height:27px; /* ie11 fix */
    }
  }
  .nav-tabs {
    margin-top:0;
    border-bottom:1px solid $gray-dark;
    li {
      padding-top:0;
      a {
      color:#fff;
        &:hover {
          color:#fff;
        }
      }
    }
    > li.active > a, .nav-tabs > li.active > a:hover, .nav-tabs > li.active > a:focus {
      background:none;
      color:#fff;
    }
  }
}

.nav-bar-wrapper {
  padding-bottom: 1.3rem;
}

.ut-navbar-easy-write {
  padding-top:1.3rem;

  > div {
    padding-right: 5rem;
  }
  .ut-help {
    float:right;
    .btn {
      padding-top: 1.2rem;
      padding-bottom: 1.1rem;
      text-transform: none;
      min-width: auto;
    }
  }
  .ut-navbar-avatar {
      float:left;
      width:4rem;
      height:4rem;
      border:0.1rem solid #fff;
  }
  .write-status {
    padding-left:5.5rem;
    text-align: right;
    input[type="text"] {
      float:left;
      width:calc(100% - 5.9rem);
      padding: 0.9rem 0.9rem 1rem 0.9rem;
      border-radius: .3rem 0 0 .3rem;
      &:focus {
        background:#fff;
      }
    }
  }
  @media (max-width:$screen-sm-max) {
    > div {
      padding-right:0;
    }
  }
  @media (max-width: 830px) {
    .ut-navbar-avatar {
      display:none;
    }
    .ut-help {
      display: none;
    }
  }

  &.guest {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
    text-align: right;
    font-size: 1.2rem;
    padding-right:.8rem;
    a {
      margin:0 2.5rem 0 0.5rem;
      &:last-child {
        margin-right:0;
      }
      @media(max-width: $screen-xs-max) {
        margin:0 .5rem;
      }
    }
  }

  .i-circle {
    background: #D3D3D3;
    color: #fff;
    font-weight: 600;
    padding: .6rem 1.5rem;
    border-radius: 50%;
    font-size: 2rem;
    margin-left: 2rem;
    cursor: pointer;
    &:hover {
      background: grey;
    }
  }
}

.ut-hamburger {
  position: absolute;
  top:0;
  left:0;
  border:0;
  background:none;
  width:7rem;
  height:7rem;
  transition:none;
  span {
    display:block;
    transition:.18s ease-in-out;
  }
  .hamburger {
    position:absolute;
    height:1.4rem;
    width:1.4rem;
    top:47%;
    left:50%;
    transform:translate(-50%, -50%);
    span {
      width:1.4rem;
      height:.2rem;
      background:$gray-dark;
      position:relative;
      margin:.3rem 0;
      &:nth-child(1){
        transition-delay:.5s;
      }
      &:nth-child(2){
        transition-delay:.625s;
      }
      &:nth-child(3){
        transition-delay:.75s;
      }
    }
  }
  .cross {
    position:absolute;
    height:2rem;
    width:2rem;
    top:50%;
    left:50%;
    transform:translate(-50%, -50%) rotate(45deg);
    span{
      background:$gray-lightest;
      &:nth-child(1){
        height:0%;
        width:.2rem;
        position:absolute;
        top:10%;
        left:50%;
        margin-left:-.1rem;
        transition-delay:0s;
      }
      &:nth-child(2){
        width:0%;
        height:.2rem;
        position:absolute;
        left:10%;
        top:50%;
        margin-top:-.1rem;
        transition-delay:.25s;
      }
    }
  }
  .nav-menu-open & {
    .hamburger {
      span {
        width:0%;
        &:nth-child(1){
          transition-delay:0s;
        }
        &:nth-child(2){
          transition-delay:.125s;
        }
        &:nth-child(3){
          transition-delay:.25s;
        }
     }
    }
    .cross {
      span {
        &:nth-child(1){
          height:80%;
          transition-delay:.625s;
        }
      }
      span {
        &:nth-child(2){
          width:80%;
          transition-delay:.375s;
        }
      }
    }
  }
}

.ut-aside-header {
  background:#1c1c1c;
  .ut-aside-inner {
    padding: 2.1rem 2rem 1rem 2rem;
  }
  .ut-list-group {
    border-bottom: none;
  }
}

.ut-aside-inner {
  padding: 0 2rem 1rem 2rem;
}

.ut-list-group {
  border-bottom:1px solid $gray-dark;
  margin:0;
  padding:1.2rem 0 1.4rem 0;
  font-size: 1.6rem;
  line-height: 1.8rem;
  list-style:none;
  color:#fff;
  .badge {
    min-width:2.8rem;
    background:#22c064;
    color:$gray-base;
    margin-top:1rem;
    font-size: 1rem;
    line-height: 1.3rem;
  }
  .list-group-item {
    background:none;
    border:0;
    padding: 0;
  }
  a {
    display:inline-block;
    padding:0.85rem 0;
    color:#fff;
    &:hover {
      color: rgba(255,255,255,.7);
    }
  }
  &-sm {
    font-size: 1.2rem;
    border:0;
    font-weight: 400;
    margin-top: 0.6rem;
    color: rgba(255,255,255,.7);
    a {
      padding:.5rem 0;
      color: rgba(255,255,255,.7);
      &:hover {
      color: rgba(255,255,255,.5);
    }
    }
  }
  &-sub-menu {
    margin: 0 0 0 1rem;
  }
}

.ut-form-control {
  font-size: 1.2rem;
  height:auto;
  padding:0.6rem 1rem;
  color: $gray-base;
  border: $gray-base;
  font-weight: normal;
  background:#fff;
  border:1px solid #d2d6d9;
  @include placeholder(#d2d6d9);

  &::placeholder {
    color: $gray-700;
  }

  &:focus, &:active {
    background: #fff !important;
  }
}

.ut-navbar-old {
  .ut-search {
    position: absolute;
    width: 25%;
    .ut-form-control {
      font-weight: normal;
      @include placeholder(#1c1c1c);
    }
    button {
      border:0;
      background:none;
      color:#1c1c1c;
      position: absolute;
      right:0;
      top:0;
      height:100%;
    }
  }
  @media (max-width: 830px) {
    .ut-search  {
      position: relative;
      width: 80%;
      float:right;
    }
  }
}

.nav-sm {
  display:none;
}

@media (max-width: 830px) {
  .nav-sm {
    display:block;
    .btn {
      padding-top: 1.2rem;
      padding-bottom:1.1rem;
      text-transform: none;
      min-width: auto;
    }
  }
}

.ut-user-card {
  @include clearfix;
  .profile-thumb {
    float:left;
  }
  &-content {
    float:left;
    padding-left:1.5rem;
  }
  p {
    margin:0;
    color: #fff;
    font-size: 1.2rem;
    line-height: 1.2em;
    font-weight: 400;
    a {
      color:inherit;
    }
    small {
      font-size: 1rem;
      color: rgba(255,255,255,0.7);
      font-weight: 400;
    }
    strong {
      font-weight: 800;
    }
    &:last-child {
      margin-top:0.8rem;
    }
  }
  .ut-badge {
    width:2.8rem;
  }
  p.ut-user-card-title {
    font-size: 1.6rem;
    font-weight: 700;
    vertical-align: top;
    a {
      &:hover {
        color:$brand-primary;
      }
    }
  }
}

div.ntux-background-color {
  background-color: #F3F4F5;
}
