//custom style
.defaultValue--root.h2 {
  text-transform: capitalize;
}

.discussion #editor .section-inner p {
    display: none;
}

#editor .section-inner {
  p, p strong, p span, b, p em, a, li, li em, i, u  {
    font-size:1.6rem;
    line-height: 1.69;
  }
  ul {
    margin:1.6rem 0;
    padding: 0 0 0 4rem;
  }
}

.editor-footer {
  width: 100%;
  position:relative;
  bottom:0;
  padding-bottom: 2rem;
  margin-top: 2rem;
  @include bp-phone {
    position: relative;
  }
  &-content {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;

    &-item {
      flex: 1 0 25%;
      min-width: 22rem;
      margin: 0 .5rem;

      @media(max-width: 1004px) {
        margin-bottom: 2rem;
      }
    }
    .ui-select-container {
      height: 3.9rem;
      padding: 0.3rem 0 0.2rem;
      border-color: #d2d6d9;
      > div {
        max-height: 100%;
        overflow: hidden;

        > input.ui-select-search {
          position: absolute;
        }
      }
      &.ui-select-multiple {
        padding: 0.5rem;
      }
    }

    @include bp-phone {
      justify-content: flex-end;
    }

    .editor-footer-action-btn {
      &.topic {
        @extend %ntux-button;
        margin: 0 .5rem;
        margin-bottom: 2rem;
      }
      flex: 1 0 25%;
      margin: 0 .5rem;
      height: 3.9rem;
      margin-bottom: 2rem;
      vertical-align: top;

      @include bp-tablet {
        flex: 0 1 12rem;
      }

      @include bp-phone {
        margin-right: 0;
        margin-left: 0;
      }
    }
    label {
      font-size: 1.2rem;
      color:#7b8a97
    }
    .submit-delete-container {
      display: inline-block;

      @include bp-phone {
        width: 100%;
      }
    }

    .editor-footer-buttons {
      @include bp-phone {
        flex: auto;
      }
    }
  }
  .filter {
    display: block;
    &.select-category .filter-options{
      width: 100%;
    }
    .filter-Val, .filter-Toggle {
      background: #fff;
      border: 1px #d2d6d9 solid;
      height: 4rem;
    }
    .filter-Val {
      border-right: none;
      line-height: 2.4rem;
    }
    .filter-Toggle {
      border-left: none;
      cursor: pointer;
      padding: 0 1rem;
      line-height: 4rem;
      &:hover {
        box-shadow: none;
      }
    }

    .filter-options {
      bottom: 4rem;
      border: 0px #d2d6d9 solid;
      border-radius: 4px;
      box-shadow: 0 0 0 transparent;
      height: 20em;
      li:last-child {
        margin-bottom: 2rem;
      }
    }
  }
}

.markup--anchor {
  text-decoration: none;
  color:#00a8cc;
  cursor:pointer;
}

.section-single-body-content {
  .imageCaption .defaultValue {
    display:none;
  }
  .graf--figure.is-mediaFocused .graf-image, .graf--figure.is-mediaFocused iframe {
    box-shadow: none;
  }
}
