.search-result{
  &-section {
    margin-bottom: 5rem;
    h6 {
      color:#1c1c1c;
      font-weight: 600;
      padding-bottom: .8rem;
      margin: 3rem 0 2rem;
      letter-spacing: 1px;
      border-bottom: 1px #3c4042 solid;
    }
    .tab-content .course .tags {
      display: none;
    }
  }
}
