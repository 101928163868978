//alert
.alert {
  border-radius: 0;
  margin-bottom: 0;
  padding: 2.5rem 3rem;
  padding-top: 2.5rem;
  @include transition(all .3s ease-in-out);
  div span {
    color: #262626;
    font-weight: 600;
    line-height: 1.4;
    display: block;
    max-width: 89rem;
    margin: 0 auto;
    padding: 0 1rem;
  }
  button span {
    font-size: 2.5rem;
  }
  &.alert-success {
    background: #effbf4;
    border-bottom: 1px #22c064 solid;
    button span {
      color: #22c064;
    }
  }
  &.alert-danger {
    background: #fef3f3;
    border-bottom: 1px #ef5454 solid;
    button span {
      color: #ef5454;
    }
  }
  &.alert-warning {
    background: #fffaed;
    border-bottom: 1px #fdb900 solid;
    button span {
      color: #A38D00;
    }
  }

  &.cookie-alert {
    background: #fff;
    padding: 1.5rem 3rem;
  }
  &.alert-to-do {
    background: #fff;
    border: 1px #ef5454 solid;
    border-radius: 3px;
    padding: 2rem;
    width: 100%;
    height: auto;
    min-height: 6rem;
    margin-top: 2rem;
    div span, div i, div a {
      font-size: 1.2rem;
      color: #3c4042;
      line-height: 1.7;
      display: inline;
      padding: 0;
    }
    div a, div i {
      color: #00a8cc;
    }
    button {
      right:-.5rem;
      top: -1.5rem;
      span {
        color: #ef5454;
      }
    }
  }
  &.small {
    font-size:1.2rem;
    padding:1rem 2rem 1rem 2rem;
    margin-bottom: 1rem;
    position: relative;
    &.alert-warning {
      border-bottom-color:#faebcc;
    }
    .close {
      position: absolute;
      top:50%;
      transform:translateY(-50%);
      right:1rem;
    }
  }
}

.am-wrapper {
  max-height: 20rem;
  overflow-y: auto;
  .message {
    font-size: 1.4rem;
    padding: 1.5rem 5rem;
    button span {
      font-size: 2.7rem;
    }
    .close {
      top: -5px;
    }
    div span {
      padding-right: .5rem;
    }
    @media (max-width:$screen-sm-max) {
      font-size: 1.2rem;
      padding: .7rem 5rem;
    }
  }
}

.cookie-alert {
  @media (max-width:$screen-sm-max) {
    font-size: 1.2rem;
    padding: .7rem 5rem;
  }
}

.alert-form {
  border-width: 1px;
  text-align: left;
  padding:15px;
  border-radius: 5px;
  margin-bottom: 20px;
}

.alert-form.alert-success {
  border-color: #22c064;
  &, a {
    color: #22c064;
  }
}

.alert-form.alert-danger {
  border-color: #ef5454;
  &, a {
    color:#ef5454;
  }
}

@keyframes alertClose {
  0% {
    opacity:1;
    transform: scale(1,1);
  }
  100% {
    opacity:0;
    transform: scale(0.7,0.7);
  }
}

.alert-close {
  .alert {
    @include animation(alertClose 800ms 1 forwards);
  }
}

#nprogress {
  .bar {
    background:#fdb900;
    height:.3rem;
  }
  .peg {
    box-shadow: 0 0 10px #fdb900, 0 0 5px #fdb900;
  }
}

@keyframes alertServiceIn {
  0% {
    opacity:0;
    top:-2rem;
  }
  100% {
    opacity:1;
    top:0rem;
  }
}

@keyframes alertServiceOut {
  0% {
    opacity:1;
    top:0rem;
  }
  100% {
    opacity:0;
    top:2rem;
  }
}

.alert-sm {
  font-size:1rem;
  padding:10px 20px;
  border:0;
}

.alert-service {
  position: fixed;
  top:calc(50% - 3rem);
  left:50%;
  width:32rem;
  max-width:70%;
  z-index:9999;
  line-height: 1.2em;
  transform: translateX(-50%);
  * {
    line-height: inherit;
  }
  .alert {
    @include animation(alertServiceIn 800ms 1 forwards);
    border-radius: .5rem;
    margin-bottom: .5rem;
    padding:2.5rem 3rem;
    position: relative;
    &.ng-leave {
      @include animation(alertServiceOut 800ms 1 forwards);
    }
    &.ng-leave-active {
      padding:2.5rem 3rem;
      max-height:none;
    }
    div span {
      padding:0;
      line-height: 1.2em;
      color:#2d3234;
    }
  }
}
