/* Overlay style */
.overlay {
  position: fixed;
  width: 100%;
  -webkit-transform: translate3d(0,0,0);
  top: 0;
  left: 0;
  background: rgba(60, 64, 66, 0.95);
  z-index: 900;
  overflow-y: scroll;
  padding: 10rem 0;
  @include bp-tablet {
    body.side-panel-open & {
      width: calc(100% - 30rem);
    }
    body.side-panel-open.menu-open & {
      width: 100%;
    }
  }
  .overlay-close {
    position: relative;
    float: right;
    margin: 0 2rem 0 -2rem;
    cursor: pointer;
    color: #fff;
    border-radius: 50%;
    width: 3rem;
    height: 3rem;
    background: #262626;
    line-height: 3rem;
    text-align: center;
    border: none;
    padding: 0;

    &:hover {
      background: #2d3234;
    }
  }
  &-content {
    color: #fff;
    max-width: 50rem;
    margin: 3rem auto;
    text-align: center;
    #signUp & {
      max-width: 73rem;
    }
    @include bp-tablet {
      top: 5%;
    }
    @include bp-phone {
      margin: 2rem;
    }
    h1 {
      font-size: 3rem;
      color: #fff;
    }
    h2 {
      font-size: 2.4rem;
      color: #fff;
      margin-bottom: 4rem;
    }
    article{
      border-top: 1px #5d6163 solid;
      padding: 1.5rem;
      text-align: left;
      &:last-child {
        border-bottom: 1px #5d6163 solid;
      }
      .profile-thumb, h5, .cus-checkbox-follow {
        display: inline-block;
        vertical-align: middle;
      }
      .profile-thumb {
        margin-right: 1.5rem;
      }
    }
    .overlay-small-type {
      color: #edf0f2;
      font-size: 1.2rem;
      font-weight: 400;
      margin: 2.5rem 0;
      a {
        font-size: 1.2rem;
        color: #edf0f2;
        text-decoration: underline;
      }
      &.terms {
        margin-top: -1rem;
      }
    }
    #gravatar {
      width: 100%;
    }
    .separate-line {
      font-weight: 800;
      position:relative;
      margin: 5rem 0 2rem;
      &:before, &:after {
        content:'';
        width: 12rem;
        height: 1px;
        display: block;
        position: absolute;
        background: #66696b;
        @include bp-phone {
          width: 32%;
        }
      }
      &:before {
        top: 1rem;
        left: 2%;
      }
      &:after {
        bottom: 1rem;
        right: 2%;
      }
    }
  }
  p {
    font-size: 1.6rem;
    color: rgba(255, 255, 255, 0.8);
    line-height: 1.25;
    max-width: 86%;
    margin:0 auto 3rem auto;
  }
  .btn.btn-info {
    border:none;
    color: rgb(255, 254, 254);
  }
}

.modal-backdrop {
  background:none;
}
.modal-content {
  background:#3C4043;
  border:0;
}
.modal-header {
  border:0;
}
.modal-title {
  color:#fff;
  font-size: 2rem;
}
.modal-body {
  padding:0 1.5rem 3rem;
}

.modal .close {
  color:#fff;
  text-shadow:none;
  opacity: 1;
  font-size: 1.8rem;
  margin-top:.5rem;
  width: 30px;
  height: 30px;
  .icon-cancel {
    font-size: 1.8rem;
  }
  &:focus, &:hover, &:active {
      border: 1px dashed $black;
  }
}

.modal {
  .user-list article {
    border-color: #636668;
  }
  .user-list article:last-child {
    border-color: #636668;
  }
  a {
    color: #fff;
  }
  .community-follow-btn {
    float: right;
  }
}
