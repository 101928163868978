.academy, .academy-course {
  button, a.academy-link-button {
    @extend %ntux-button;
  }

  .academy-header {
    @extend %component-header;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: normal;
    letter-spacing: 0.1em;
  }

  .course-view, .no-course {
    @extend %community-card-shared;
    overflow-wrap: break-word;
  }

  .no-course {
    font-size: 16px;
  }

  .course-title {
    font-size: 36px;
    font-weight: 600;
    line-height: 42px;
  }

  h1, h2, h3, h4, h5, h6 {
    font-weight: 600;
  }

  .course-section {
    h1 {
      font-size: 20px;
    }

    ul {
      padding-left: 40px;
      margin-bottom: 10px;
    }
  }

  .course-section-title {
    font-size: 24px;
    font-weight: 600;
    margin-top: 20px;
    margin-bottom: 10px;
  }

  .course-view-actions {
    display: block;
    overflow: hidden;
    font-size: initial;
    padding-bottom: 15px;
    padding-left: 5px;
    padding-right: 5px;
    .previous-course {
      width: 160px;
      float: left;
      @extend %ntux-hollow-button-on-gray;
    }
    .next-course, .course-call-to-action {
      width: 160px;
      float: right;
    }
    .return-to-academy {
      float: right;
      display: flex;

      i {
        margin-right: 5px;
      }
    }
    button {
      font-size: inherit;
    }
  }

  .course-track-nav {
    @extend %community-card-shared;
    padding: 18px;
    max-width: 340px;
    @media only screen and (max-width: $screen-lg-min) {
      margin-left: auto;
      margin-right: auto;
    }
    .course-track-name {
      @extend %component-header;
      margin: 0px;
      font-size: 16px;
      text-transform: uppercase;
      font-weight: normal;
      letter-spacing: 0.02em;
      border-bottom: 1px solid $gray-lighter;
      padding-bottom: 8px;
    }
    .course-track-courses {
      padding: 15px 0px;
    }

    .course-track-link {
      display: flex;
      align-items: center;
      &:hover, &:active, &:focus {
        text-decoration: none;
      }
      .course-track-title {
        max-width: fit-content;
      }
    }
    .course-track-course {
      padding: 15px 10px;
      font-size: 14px;
      min-height: 40px;
      overflow: hidden;
      list-style: none;
      margin-bottom: 2px;

      &:hover {
        border-radius: 8px;
        background: $gray-100;
        cursor: pointer;
      }
      &.current-course {
        font-weight: bold;
        border-radius: 8px;
        background: $gray-100;
      }
      span {
        color: $gray-base;
        float: left;
        width: 80%;
      }
      .material-icons {
        margin-left: auto;
        font-size: 20px;
        font-weight: 500;
        color: #666;
        float: right;

        &.completed {
          color: $blue;
          float: right;
        }
        &.incorrect {
          margin-left: 0px;
          margin-right: auto;
          color: $icon-color-red;
          float: left;
        }
      }
      &:focus-within {
        border-radius: 8px;
        background: $gray-100;
      }
    }
    .course-track-nav-quiz {
      border-top: 1px solid $gray-lighter;
      line-height: 26px;

      .course-track-nav-quiz-button-container {
        display: block;
      }

      .take-quiz {
        @extend %ntux-button;
        width: 160px;
        display: block;
      }
      .take-quiz:not(.active) {
        background-color: $gray-500;
        border-color: $gray-500;
        :hover {
          background-color: $gray-500;
          border-color: $gray-500;
        }
      }
      .quiz-completed {
        display: flex;
        align-items: center;
        margin-left: auto;
        i {
          color: $icon-border-yellow;
          font-size: 20px;
          height: 20px;
        }
      }
      .instructions {
        font-size: 12px;
        text-align: center;
        margin: 10px 0px;
      }
      .test-cycle-fail-message {
        margin-left: 10px;
        margin-right: 10px;
        .material-icons {
          color: $icon-color-red;
          font-size: 16px;
        }
      }
    }
    .practice-test-cycle {
      margin: 18px -18px -18px -18px;
      padding: 15px;
      overflow: hidden;
      padding-top: 16px;
      .locked, .unlocked {
        width: 34px;
        height: 34px;
        i {
          background: $gray-lighter;
          border-radius: 17px;
        }
        float: left;
      }
      .locked {
         i {
          border: 2px solid $icon-color-red;
         }
      }
      .unlocked {
        i {
          border: 2px solid $icon-border-green;
        }
      }
      .material-icons {
        padding: 5px;
        font-size: 20px;
      }
      .test-cycle-title {
        font-weight: 600;
        text-transform: capitalize;
      }
      .test-cycle {
        margin-left: 40px;
      }
      .academy-return {
        width: 120px;
        display: block;
      }
    }
    .incomplete-quiz {
      background: repeating-linear-gradient(
        45deg,
        $gray-lighter,
        $gray-lighter 1px,
        #ffffff 1px,
        #ffffff 6px
      );
    }
  }

  .course-track-nav-quiz__nav-item {
    display: flex;
    padding: 15px 10px 15px 30px;
    font-size: 14px;
    min-height: 40px;
    overflow: hidden;

    &.taking-quiz {
      margin-top: 15px;
      border-radius: 8px;
      background-color: $gray-100;
      font-weight: 600;
    }
  }

  .course-track-quiz {
    @extend %community-card-shared;
  }

  .course-track-quiz-progress-bar {
    display: flex;
    justify-content: center;

    :first-child .course-track-quiz-progress-bar-step__chunk {
      border-radius: 100px 0 0 100px;
    }

    :last-child .course-track-quiz-progress-bar-step__chunk {
      border-radius: 0 100px 100px 0;
    }
  }

  .course-track-quiz-progress-bar-step {
    display: flex;
    flex: 1 1 0;
    flex-direction: column;
    max-width: 80px;
    align-items: center;
    padding: 0 2px;
    margin-bottom: 18px;
  }

  .course-track-quiz-progress-bar-step__number {
    color: #666;
  }

  .course-track-quiz-progress-bar-step__number--selected {
    color: $blue;
    font-weight: 600;
  }

  .course-track-quiz-progress-bar-step__chunk {
    width: 100%;
    height: 13px;
    background: #949494;
  }

  .course-track-quiz-progress-bar-step__chunk--selected {
    background: $blue;
  }

   .course-track-quiz-progress-bar-step__chunk--completed {
    background: $lightest-blue;
  }

  .course-track-quiz-question-header {
    text-transform: uppercase;
  }

  .course-track-quiz-question-body {
    font-size: 20px;
  }

  .course-track-quiz-question-body__text {
    margin-bottom: 24px;
    margin-top: 6px;
    font-weight: 400;
    font-size: 24px;
    &:focus {
      outline: none;
    }
  }

  .course-track-quiz-question-choice {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    position: relative;
  }

  .course-track-quiz-question-choice__letter {
    position: absolute;
    left: 0;
  }

  .course-track-quiz-question-choice-radio-button {
    opacity: 0;
  }

  .course-track-quiz-question-choice-option {
    width: 100%;
    min-height: 78px;
    border-radius: 39px;
    background-color: $gray-lighter;
    border: 2px solid transparent;
    font-weight: normal;
    padding: 16px 36px;
    margin-left: 36px;
    display: flex;
    align-items: center;
    line-height: 24px;

    &:hover, &:active, &:focus {
      cursor: pointer;
      background-color: $gray-light;
    }
  }

  .course-track-quiz-question-answers input[type="radio"]:checked + label {
    border: 2px solid $blue;
  }

  .course-track-quiz-question-answers input[type="radio"]:focus + label {
    background-color: $gray-light;
    border-color: $blue;
  }

  .course-track-quiz-question-choice-option--selected {
    background-color: #F2F7FB;
    border: 2px solid $blue;
  }

  .course-track-quiz-question-nav {
    margin-top: 24px;

    .course-track-quiz-question__next-button {
      button {
        margin: 0 0 0 auto;
      }
      button:disabled {
        cursor: not-allowed;
        background-color: $action-disabled-color;
        border-color:  $action-disabled-color;
      }
    }
  }

  .course-track-report-card {
    .report-card-header, .report-card-body {
      @extend %community-card-shared;

      .academy-return {
        display: block;
        margin: 0 auto;
        width: 200px;
      }
    }

    .report-title {
      text-align: center;
      margin-top: 70px;
      font-size: 34px;
      font-weight: 600;
    }

    .report-title-emoji img {
      width: 36px;
    }

    p {
      text-align: center;
    }

    .report-message {
      margin-top: 24px;
      margin-bottom: 20px;
      font-size: 18px;
    }
    .report-card-body {
      font-size: 16px;

      h2 {
        margin-top: 0;
        font-size: 20px;
        font-weight: 600;
      }
      .quiz-results {
        padding-left: 12px;

        .quiz-result-item {
          border-bottom: 1px solid #eee;
          margin-bottom: 12px;
          padding-bottom: 12px;
          display: list-item;
        }
        .answer-status {
          float: left;
          i {
            margin: 10px 0px;
            font-size: 20px;
          }
          .right-answer {
            color: $icon-border-green;
          }
          .wrong-answer {
            color: $icon-color-red;
          }
        }
        .answer-details {
          margin-left: 30px;
          .answer-label {
            font-weight: 600;
          }
        }
        .answer-solution {
          .highlight {
            display: inline-block;
            padding: 5px 10px;
            margin-right: 10px;
            border-radius: 15px;
            background-color: #f3f4f5;

            a {
              text-decoration: underline;
              color: #0274B6;

              &:focus {
                outline: 1px dashed !important;
              }
            }
          }
          button {
            display: inline-block;
            padding: 6px 24px;
          }
        }
      }
    }
  }

  // for this page: https://www.utest.com/academy/tracks/41/courses/Charles-Proxy-for-Mobile we had to manually add a css.
  // first wanted to add the style directly inside the course html but it was not working because `@media` is converted to a mention.
  @media only screen and (max-width: 360px) {
    .two-column-table { margin-left: -90px; }
  }

  @media only screen and (min-width: 361px) and (max-width: 460px) {
    .two-column-table { margin-left: -75px; }
  }
}

%academy-card-shared {
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.25);
  margin-bottom: 12px;
  padding-left: 36px;
  padding-right: 36px;
  padding-top: 30px;
  padding-bottom: 24px;
}

.academy-light-tester-upgrade {
  @extend %academy-card-shared;
  display: flex;
  justify-content: center;
  border-left: 8px solid #0272B4;
  border-radius: 4px;
  padding: 20px 24px 20px 24px;
  gap: 12px;
  @media screen and (max-width: 574px) {
    flex-direction: column;
  }
}

.academy-light-tester-upgrade__cta {
  display: flex;
  align-items: center;
  justify-content: center;
}

.academy-light-tester-upgrade__button {
  margin: 0;
  @extend %ntux-button;
  @extend %ntux-button-small;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  text-align: center;
  white-space: nowrap;
}

.academy-light-tester-upgrade__title {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
}


.disabled-academy-tooltip {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8px;

  width: 220px;
  height: 64px;

  background: #282828;
  border-radius: 4px;

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;

  .disabled-academy-tooltip-text {
    width: 204px;
    height: 48px;

    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;

    color: #FFFFFF;

    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
  }
}

.academy-home {
  button {
    @extend %ntux-button;
  }
  .academy-home-intro {
    text-align: center;
    .academy-home-title {
      display: inline-block;
      @extend %component-header;
    }
  }

  .academy-home-description-content {
    font-size: 16px;
    margin-bottom: 10px;
  }

  .course-track-list {
    margin-top: 30px;
  }

  .course-track-card {
    @media only screen and (max-width: $screen-xs-max) {
      max-width: 360px;
      margin-left: auto;
      margin-right: auto;
    }
    @media only screen and (min-width: $screen-sm-min) {
      height: 250px;
    }
    border-radius: 4px;
    border: none;
    background-color: #FFFFFF;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.25);
    margin-top: 0px;
    display: grid;

    .course-track-name {
      font-weight: 600;
      font-size: 18px;
    }
    .course-track-view {
      width: 140px;
      @extend %ntux-button;
    }

    .course-track-view[disabled] {
      cursor: not-allowed;
      background-color: $action-disabled-color;
      border-color:  $action-disabled-color;
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;
    }

    .course-track-view.completed {
      @extend %ntux-hollow-button-on-white;
    }

    .course-track-list-details {
      display: inline;
      button {
        font-weight: 600;
        background: none!important;
        border: none;
        color: $blue;
        display: inline;
        margin: 0;
        padding: 0;
        min-width: 50px;

        &:hover, &:focus, &:focus-visible {
          text-decoration: underline;
          box-shadow: none;
          border-radius: 0;
          transition: none;
        }
      }
    }

    .course-track-list-popover {
      position: absolute;
      visibility: hidden;
      width: 276px;
      z-index: 1000;
      transform: translateX(-50%);
      .course-track-list-popover__body {
        padding: 2rem;
        background-color: #fff;
        border: 1px solid rgba(0, 0, 0, 0.2);
        border-radius: 6px;
        box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
        h3 {
          font-size: 14px;
          margin: 0;
          font-weight: 600;
          padding-bottom: 8px;
        }
        li {
          margin-left: 2rem;
        }
        li.additional-courses {
          list-style-type: none;
          font-weight: 600;
        }
        li.additional-courses::before {
          content: '+';
          font-weight: 600;
          display: inline-block;
          width: 1.1em;
          margin-left: -1.1em;
        }
      }
    }

    .course-track-list-popover__arrow {
      border-width: 11px;
      left: 50%;
      margin-left: -11px;
      border-top-width: 0;
      border-color: transparent;
      border-bottom-color: #999999;
      top: -10px;
      position: absolute;
      display: block;
      width: 0;
      height: 0;
      border-style: solid;

      &::after {
        position: absolute;
        display: block;
        width: 0;
        height: 0;
        border-color: transparent;
        border-bottom-color: #fff;
        border-style: solid;
        border-width: 10px;
        content: ' ';
        top: 1px;
        margin-left: -10px;
        border-top-width: 0;
      }
    }

    .course-track-list-accordion {
      margin-top: 25px;
      ul {
        margin-left: 25px;
      }
      li.additional-courses {
        list-style-type: none;
        font-weight: 600;
      }
      li.additional-courses::before {
        content: '+';
        font-weight: 600;
        display: inline-block;
        width: 1.1em;
        margin-left: -1.1em;
      }
    }
  }

  .panel-done {
    height: 20px;
    i {
      font-size: 20px;
      font-weight: 600;
      color: $icon-border-green;
    }
  }

  .panel-bar {
    height: 8px;
    border-radius: 4px 4px 0 0;
    background-color: $blue;
  }

  .panel-heading, .panel-body {
    padding-right: 15px;
    padding-left: 15px;
  }

  .panel-body {
    padding-top: 0px;
    padding-bottom: 0px;
    overflow: hidden;
    overflow-y: auto;
  }

  .panel-footer {
    margin: auto;
    position: relative;
    bottom: 0;
    height: 75px;
    border-top: none;
    background: white;
    padding-top: 16px;
  }
}

#mainContent {
  &:focus {
    outline: none;
  }
}

.quiz-completed {
  display: flex;
  align-items: center;
  margin-left: auto;
  i {
    color: $icon-border-yellow;
    font-size: 20px;
    height: 20px;
  }
}
