@use '../base/variables';

.ntux-nav-header {
  background: linear-gradient(270deg, #0369B0 0%, #00A8CC 60%);
  border: 0px;
  padding-top: 2.5px;

  .ntux-nav-header-container {
    padding-left: 50px;
    padding-right: 50px;
  }

  .ntux-nav-header-search {
    position: absolute;
    display: flex;
    align-items: center;
    width: 30%;

    input::placeholder {
      color: #fff;
      opacity: 1;
    }

    input::-ms-input-placeholder {
      color: #fff;
      opacity: 1;
    }
  }

  .ntux-nav-header-search__input {
    background-color: transparent !important;
    border: none !important;
    font-size: 16px;
    font-weight: 500;
    color: #fff;
    margin: 10px auto;

    &:focus {
      outline: 1px solid white !important;
    }

    &:focus,&:not(:placeholder-shown) {
      background-color: variables.$navbar-search-hc-bg !important;
    }
  }

  .ntux-nav-header-search__icon {
    border: 0;
    background: none;
    color: #fff;
    height: 100%;
    font-size: 0px;
    cursor: default;
  }

  .ntux-nav-header-buttons {
    float: right;
    display: flex;
    margin-top: 14.5px;
  }

  .ntux-nav-header-buttons__item {
    margin: 0px 10px;
  }

  .ntux-nav-header-buttons__link {
    color: #fff;
    vertical-align: middle;

    &:focus {
      outline: 1px dashed $white !important;
    }
  }

  .ntux-nav-header-chat {
    font-size: 2em;
    position: relative;
  }

  .ntux-nav-header-notifications {
    font-size: 2em;
    position: relative;
  }

  .ntux-nav-header-notifications__dot {
    border-radius: 50%;
    width: 0.3em;
    height: 0.3em;
    background-color: #d93d22;
    display: inline-block;
    position: absolute;
    top: 0.2em;
    right: 0.1em;
  }

  .ntux-nav-header-notifications__dot--with-number {
    height: 16px;
    width: 16px;
    text-align: center;
    font-weight: 700;
    font-size: 11px;
    color: white;
    top: 4px;
    left: 14px;
  }

  .ntux-nav-header-chat__dot {
    border-radius: 50%;
    width: 0.3em;
    height: 0.3em;
    background-color: #d93d22;
    display: inline-block;
    position: absolute;
    top: 0.2em;
    left: 0.6em;
  }

  .ntux-nav-header-chat__dot--with-number {
    height: 16px;
    width: 16px;
    text-align: center;
    font-weight: 700;
    font-size: 11px;
    color: white;
    top: 2px;
    left: 16px;
  }

  .ntux-nav-header-help {
    font-size: 2em;
  }

  .nav-sidebar-toggle {
    position: absolute;
    background: transparent;
    border: none;
    margin: 1.25em .5em;
    color: white;

    i {
      vertical-align: middle;
    }
    &:focus {
      i {
        outline: 1px dashed #fff;
      }
    }
  }

  .ntux-nav-bar-header-unauth {
    color: #FFF;
    font-size: 1.3rem;

    a {
      color: #FFF;
      font-weight: bold;
      font-size: 1.4rem;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .nav-menu-open & {
    .nav-sidebar-toggle {
      display: none;
    }
  }

  @media (min-width: 769px) {
    .nav-sidebar-toggle {
      display: none;
    }
  }

  @media (max-width: 768px) {
    .ntux-nav-header-buttons__item {
      margin: 0px 5px;
    }

    .ntux-nav-header-search {
      margin-left: 7%;
    }
  }
}
