/*!
 * Copyright (C) 2020 to Present Applause App Quality, Inc. All rights reserved.
 */

@use "ng-falcon";
@use "sass:map";
@use "variable";

// TODO: deprecated, remove later
@import "ngf-button-icon.directive";

@mixin ngfButtonSmall() {
  --ngf-button__height: 28px;
  --ngf-button__font-size: 14px;

  --ngf-button__mat-icon--size: 20px;

  --ngf-button--text-button__min-width: 80px;
  --ngf-button--text-button__padding: #{4px - variable.$border-width} #{16px - variable.$border-width};
  --ngf-button--text-button--with-icon__side-padding: #{8px - variable.$border-width};

  --ngf-button--icon-button__padding: #{4px - variable.$border-width};
}

a[ngfButton],
button[ngfButton] {
  color: var(--ngf-button__color);
  background-color: var(--ngf-button__background-color);

  border: variable.$border-width solid var(--ngf-button__border-color);
  border-radius: 4px;

  height: var(--ngf-button__height, 36px);
  font-size: var(--ngf-button__font-size, 16px);

  box-sizing: border-box;

  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 8px;

  line-height: 20px;
  font-weight: 400;

  font-family: proxima-nova, "Helvetica Neue", Helvetica, Arial, sans-serif;

  cursor: pointer;
  text-decoration: none;

  @include ng-falcon.transition_short-linear(
                  background-color,
                  border-color,
                  color,
                  box-shadow,
                  height,
                  padding,
                  font-size
  );

  &:hover:not(:disabled),
  &:active:not(:disabled),
  &[active="true"]:not(:disabled),
  &:focus-visible {
    color: var(--ngf-button-active__color);
    background-color: var(--ngf-button-active__background-color);
    border-color: var(--ngf-button-active__border-color);
  }

  &:active:not(:disabled),
  &:focus-visible {
    box-shadow: 0 0 3px 2px #46BAFD; // TODO-MKU: ask Ben about adding new color to palette
  }

  &:focus-visible {
    outline: none;
  }

  &:disabled {
    cursor: not-allowed;

    color: var(--ngf-button-disabled__color);
    background-color: var(--ngf-button-disabled__background-color);
    border-color: var(--ngf-button-disabled__border-color);
  }

  &[passive] {
    pointer-events: none;

    color: inherit;
    background-color: transparent;
    border-color: transparent;
  }

  &.ngf-button--text-button {
    min-width: var(--ngf-button--text-button__min-width, 100px);
    padding: var(--ngf-button--text-button__padding, #{8px - variable.$border-width} #{24px - variable.$border-width});

    &.ngf-button--with-left-icon {
      padding-left: var(--ngf-button--text-button--with-icon__side-padding, #{16px - variable.$border-width});
    }

    &.ngf-button--with-right-icon {
      padding-right: var(--ngf-button--text-button--with-icon__side-padding, #{16px - variable.$border-width});;
    }
  }

  &.ngf-button--icon-button {
    padding: var(--ngf-button--icon-button__padding, #{6px - variable.$border-width});
  }

  .mat-icon {
    // any direct icon inside button, should not be clipped or be using too much space
    flex: 0 0 auto;

    $default-size: 24px;
    font-size: var(--ngf-button__mat-icon--size, $default-size);
    width: var(--ngf-button__mat-icon--size, $default-size);
    height: var(--ngf-button__mat-icon--size, $default-size);

    @include ng-falcon.transition_short-linear(font-size, width, height);
  }

  &[size="small"] {
    @include ngfButtonSmall();
  }
}

.ngf-button--group {
  display: inline-flex;
  flex-wrap: nowrap;
  gap: 8px;

  &[vertical="true"] {
    flex-direction: column;
  }
}

[ngfButtonForNgfTableDensity] {
  a[ngfButton],
  button[ngfButton] {
    --ngf-button--text-button__padding: #{4px - variable.$border-width} #{12px - variable.$border-width};

    &[size="small"] {
      // small buttons are not available in ngf-table
      // instead ngf-button size is determined by ngf-table density
      display: none;
    }
  }
}

[ngfButtonForNgfTableDensity="compact"] {
  a[ngfButton],
  button[ngfButton] {
    @include ngfButtonSmall();
  }
}

a, button, ngf-button-split {
  @mixin theme($colors) {
    @if not (map.has-key($colors, 'default', 'border-color')) {
      $colors: map.set($colors, 'default', 'border-color', 'transparent');
    }

    @if not (map.has-key($colors, 'default', 'border-color--inner')) {
      $colors: map.set($colors, 'default', 'border-color--inner', map.get($colors, 'default', 'border-color'));
    }

    @if not (map.has-key($colors, 'active', 'color')) {
      $colors: map.set($colors, 'active', 'color', map.get($colors, 'default', 'color'));
    }

    @if not (map.has-key($colors, 'active', 'background-color')) {
      $colors: map.set($colors, 'active', 'background-color', map.get($colors, 'default', 'background-color'));
    }

    @if not (map.has-key($colors, 'active', 'border-color')) {
      $colors: map.set($colors, 'active', 'border-color', map.get($colors, 'default', 'border-color'));
    }

    @if not (map.has-key($colors, 'disabled', 'color')) {
      $colors: map.set($colors, 'disabled', 'color', map.get($colors, 'default', 'color'));
    }

    @if not (map.has-key($colors, 'disabled', 'background-color')) {
      $colors: map.set($colors, 'disabled', 'background-color', map.get($colors, 'default', 'background-color'));
    }

    @if not (map.has-key($colors, 'disabled', 'border-color')) {
      $colors: map.set($colors, 'disabled', 'border-color', map.get($colors, 'default', 'border-color'));
    }

    --ngf-button__color: #{map.get($colors, 'default', 'color')};
    --ngf-button__background-color: #{map.get($colors, 'default', 'background-color')};
    --ngf-button__border-color: #{map.get($colors, 'default', 'border-color')};
    --ngf-button__border-color--inner: #{map.get($colors, 'default', 'border-color--inner')};

    --ngf-button-active__color: #{map.get($colors, 'active', 'color')};
    --ngf-button-active__background-color: #{map.get($colors, 'active', 'background-color')};
    --ngf-button-active__border-color: #{map.get($colors, 'active', 'border-color')};

    --ngf-button-disabled__color: #{map.get($colors, 'disabled', 'color')};
    --ngf-button-disabled__background-color: #{map.get($colors, 'disabled', 'background-color')};
    --ngf-button-disabled__border-color: #{map.get($colors, 'disabled', 'border-color')};
  }

  //TODO-MKU: default button variant should be "primary"
  &[ngfButton=""],
  &[ngfButton="tertiary"] {
    @include theme((
            default: (
                    color: ng-falcon.$color_applause-blue-50,
                    background-color: ng-falcon.$color_white,
                    border-color--inner: ng-falcon.$color_applause-grey-300,
            ),
            active: (
                    background-color: ng-falcon.$color_applause-blue-10
            ),
            disabled: (
                    color: ng-falcon.$color_applause-grey-500,
                    background-color: ng-falcon.$color_applause-grey-100,
            )
    ));
  }

  &[ngfButton="primary"] {
    @include theme((
            default: (
                    color: ng-falcon.$color_white,
                    background-color: ng-falcon.$color_applause-blue-50,
                    border-color--inner: ng-falcon.$color_white,
            ),
            active: (
                    background-color: ng-falcon.$color_applause-blue-60
            ),
            disabled: (
                    background-color: ng-falcon.$color_applause-grey-400
            )
    ));
  }

  &[ngfButton="secondary"] {
    @include theme((
            default: (
                    color: ng-falcon.$color_applause-blue-50,
                    background-color: ng-falcon.$color_white,
                    border-color: ng-falcon.$color_applause-grey-300,
            ),
            active: (
                    color: ng-falcon.$color_applause-blue-50,
                    background-color: ng-falcon.$color_applause-blue-10,
                    border-color: ng-falcon.$color_applause-blue-50
            ),
            disabled: (
                    color: ng-falcon.$color_applause-grey-500,
                    background-color: ng-falcon.$color_applause-grey-100,
                    border-color: ng-falcon.$color_applause-grey-300
            )
    ));
  }

  &[ngfButton="primary-green"] {
    @include theme((
            default: (
                    color: ng-falcon.$color_white,
                    background-color: ng-falcon.$color_applause-green-50,
                    border-color--inner: ng-falcon.$color_white,
            ),
            active: (
                    background-color: ng-falcon.$color_applause-green-60
            ),
            disabled: (
                    background-color: ng-falcon.$color_applause-grey-400
            )
    ));
  }

  &[ngfButton="primary-red"] {
    @include theme((
            default: (
                    color: ng-falcon.$color_white,
                    background-color: ng-falcon.$color_applause-red-50,
                    border-color--inner: ng-falcon.$color_white,
            ),
            active: (
                    background-color: ng-falcon.$color_applause-red-60
            ),
            disabled: (
                    background-color: ng-falcon.$color_applause-grey-400
            )
    ));
  }

  //TODO-MKU: deprecated, marked for removal
  &[ngfButton="ghost"] {
    @include theme((
            default: (
                    color: ng-falcon.$color_applause-blue-50,
                    background-color: ng-falcon.$color_white,
                    border-color: ng-falcon.$color_applause-blue-50,
            ),
            active: (
                    color: ng-falcon.$color_white,
                    background-color: ng-falcon.$color_applause-blue-50
            ),
            disabled: (
                    color: rgba(ng-falcon.$color_applause-blue-50, 0.5),
                    background-color: ng-falcon.$color_white,
                    border-color: rgba(ng-falcon.$color_applause-blue-50, 0.5)
            )
    ));
  }
}
