@use 'ng-falcon/src/lib/colors';
@use 'ng-falcon/src/lib/fonts';

$badgeWidth: 20px;
$badgeHeight: 20px;

$badgeOffsetTop: 11px;
$badgeOffsetRight: 11px;

.ngf-badge-container {
  position: relative;

  &[href] {
    display: inline-flex;
    min-width: 24px;
    min-height: 24px;
    justify-content: center;
    align-items: center;
    border: none;
    background: none;
    padding: 0;
    margin: 0;
    line-height: 0;
    text-decoration: none;

    .ngf-badge {
      top: -$badgeWidth/2;
      right: -$badgeHeight/2;
    }
  }

  .ngf-badge {
    position: absolute;
    display: inline-block;
    width: $badgeWidth;
    height: $badgeHeight;

    @include fonts.font-xs-500();
    line-height: $badgeHeight;

    border-radius: 50%;

    white-space: nowrap;
    text-overflow: ellipsis;
    pointer-events: none;

    text-align: center;
    top: 0;
    right: 0;

    &.ngf-badge-hidden {
      visibility: hidden;
    }

    background: colors.$applause-red-50;
    color: white;
  }
}
