//basic button
.btn {
  min-width: 20rem;
  text-align:center;
  display:inline-block;
  padding: 1rem 2rem;
  border-radius: 100px;
  border: 1px solid;
  color: #34495e;
  font-size: 1.4rem;
  font-weight:600;
  opacity: 1;
  z-index: 1;
  @include bp-phone {
    width: 100%;
    display: inline-block;
  }
  @include transition(all .3s ease);
  &:hover {
  }

  span {
    vertical-align: middle;
  }

  i {
    vertical-align: middle;
  }
  &.small {
    width:auto;
    min-width: 5rem;
    height: 2.4rem;
    padding: .2rem 1rem;
    color: $gray-600;
    border-radius: 1.5rem;
    border: 1px solid;
    font-size:1.2rem;
    line-height: 1.5;
    font-weight:500;
    text-transform: capitalize;
    margin-right:.5rem;
    letter-spacing:0rem;
    &:last-child {
      margin-right: 0;
    }
    span {
      line-height: 1.75rem;
    }
  }
  &.btn-blue {
    background: $blue;
    border-color: $blue;
    color: #fff;
    &:focus-visible {
      background: $dark-blue;
      background-color: $dark-blue;
      outline: auto 2px; 
      text-decoration: none;
      transition: none;
      outline-offset: -4px;
    }
    &:hover {
      background: $dark-blue;
      background-color: $dark-blue;
      text-decoration: none;
      .icon:before {
        color: #fff;
      }
    }
  }
  &.btn-green {
    background: #1CB78D;
    border-color: #1CB78D;
    color: #fff;
    &:hover {
      background: #1CB78D;
    }
  }
  &.btn-grey {
    min-width: 15rem;
    background: #B9B9B9;
    border-color: #B9B9B9;
    color: #fff;
    &:hover {
      background: #c5c2c2;
    }
  }
  &.btn-rounded {
    border-radius:30px;
  }
  &.btn-circle {
    width: 2rem;
    min-width: 2rem;
    height: 2rem;
    padding: 0;
    border-radius: 50%;
    margin: 0 .5rem;
    &:hover {
      border-color: #00a8cc;
       &:hover {color: #00a8cc;}
    };
    &:before {
      position:relative;
      margin: 0;
      top: -1px;
    }
  }
  &.btn-yellow {
    background: #fdb900;
    border-color: #fdb900;
    color: #262626;
    font-size: 1.2rem;
    font-weight: 600;
    &:hover {
      background: #FFCD00;
      color: #262626;
    }
  }
  &.btn-black {
    background: #262626;
    border-color: #262626;
    color: #fff;
    font-size: 1.2rem;
    display: inline-block;
    vertical-align: top;
    &:after {
      opacity: 0.1;
    }
    &:hover {
      background: #3B3B3B;
    }
  }
  &.btn-outline {
    background: transparent;
    border-width: 1px;
    &.btn-blue {
      color: #00a8cc;
      &:hover {
        background: transparent;
        color: #34495e;
        border-color: #34495e;
      }
    }
    &.btn-grey {
      color: #657481;
      &:before {
        color: #657481;
      }
      &:hover {
        background: transparent;
      }
    }
  }
  &.btn-save {
    background: #d2d6d9;
    color: #262626;
    border: none;
    font-size: 1.2rem;
    font-weight: 600;
    position: relative;
    overflow: hidden;
    &:before, &:after {
      vertical-align: middle;
      font-family: "utestfont";
      position: absolute;
      font-weight: 600;
      left: 0;
      right: 0;
    }
    &:before {
      @include transition(all .2s ease-in);
      font-size: 14px;
      content: 'Save';
      top: 1.1rem;
      left: 0;
      font-family: $font-family-sans-serif;
    }
    &:after {
      content: '\e806';
      font-size: 1.3rem;
      top: 4rem;
      left: 6rem;
    }
    &.saved {
      background: #22c064;
      color: #fff;
      &:before {
        content: 'Saved';
        left: -1rem;
      }
      &:after {
        top: 1.1rem;
      }
    }
  }
  &.edit-article {
    @include bp-phone {
      display: none;
    }
  }
}
#coverBtn.btn {
  @extend %ntux-button;
  height: 2.4rem;
  line-height: 2rem;
  font-size: 1.5rem;
  padding: 0px 8px;
  margin: 0;
  @include bp-phone {
    float: none;
    margin: .2rem 0 2rem 0;
  }
  &.new-article {
    float: left;
    &:hover {
      .icon-write:before {
        color: $white;
      }
    }
  }
  &.new-topic {
    float: right;
  }
}
.section-single-cover.courses .btn {
  color: #ffffff;
  border: 1px #ffffff solid;
}
//remove btn for todo list item
.delete-btn, .cus-checkbox {
  &.inline {
    display:inline-block;
    padding-right: .5rem;
  }
}
.delete-btn {
  &:before {
    content:'\e816';
    font-family: "utestfont";
    display: block;
    padding: 0 .4rem;
    border-radius: 4px;
    color: #7b8a97;
    @include transition(all .3s ease);
  }
  &:hover:before {
    color:#ef5454;
  }
}

//checkboxes button
.cus-checkbox {
  margin-bottom:1rem;
  vertical-align:text-top;
  margin-top: .1rem;
  input[type='checkbox']{
    display: none;
    &:checked ~ .checkbox-toggle:before {
      content:'\e806';
      background:#22c064;
      border: 1px #22c064 solid;
    }
  }
  .checkbox-toggle {
    display: block;
    cursor: pointer;
    font-size: 1.3rem;
    color: #7b8a97;
    padding-left:1.5rem;
    position:absolute;
    &:hover:before {
      border: 1px #22c064 solid;
    }
    &:before {
      content:'';
      border-radius: 3px;
      border: 1px #7b8a97 solid;
      background: #fff;
      width:1.8rem;
      height:1.8rem;
      position: absolute;
      left:0;
      font-family: "utestfont";
      font-size: 1.3rem;
      color:#fff;
      line-height: 1.8rem;
      text-align: center;
      @include transition(all .3s ease);
    }
  }
}

.cus-radio {
  margin-bottom:1rem;
  vertical-align:text-top;
  margin-top: .1rem;
  input[type='radio']{
    display: none;
    &:checked ~ .checkbox-toggle {
      border: .1rem #fff solid;
      background:none;
      &:before {
        content: '';
        margin:0;
        display:block;
        background:$brand-primary;
        width:0.7rem;
        height:0.7rem;
        position: absolute;
        top:50%;
        left:50%;
        transform: translate(-50%, -50%);
        border-radius: 50%;
      }
    }
  }
  .checkbox-toggle {
    margin-top:.1rem;
    display: inline-block;
    cursor: pointer;
    font-size: 1.3rem;
    color: #7b8a97;
    position: relative;
    border-radius: 50%;
    border: 1px #7b8a97 solid;
    width:1.8rem;
    height:1.8rem;
    position: absolute;
    left:0;
    font-family: "utestfont";
    font-size: 1.3rem;
    color:#fff;
    line-height: 1.8rem;
    text-align: center;
    @include transition(all .3s ease);
    &:hover {
      border: 1px $brand-primary solid;
    }
    &:before {
      width:0;
      height:0;
      transition: all 300ms;
    }
  }
}

.cus-switch {
  display: inline-block;
  vertical-align: middle;
  input[type='checkbox']{
    display: none;
    &:checked ~ .switch-toggle {
      background:#22c064;
      &:before {
        right: 1.5rem;
        border: 1px #22c064 solid;
      }
    }
  }
  .switch-toggle {
    user-select: none;
    display: block;
    cursor: pointer;
    background: #262626;
    width: 3.5rem;
    height: 2rem;
    border-radius: 2rem;
    position:relative;
    @include transition(all .3s ease);
    &:hover:before {
      border: 1px #22c064 solid;
    }
    &:before {
      content:'';
      border-radius: 50%;
      border: 1px #262626 solid;
      background: #fff;
      width:2rem;
      height:2rem;
      position: absolute;
      right:0;
      @include transition(all .3s ease);
    }
  }
}

//checkable button
.cus-checkbox-btn {
  margin-bottom:.5rem;
  input[type='checkbox']{
    display: none;
    &:checked ~ .checkbox-toggle{
      background:#d1ecf2;
      color: #00a8cc;
      border: 1px #00a8cc solid;
    }
    &:checked ~ .checkbox-toggle:before {
      content:'\00D7';
      font-size: 1.4rem;
    }
  }
  .checkbox-toggle {
    display: block;
    cursor: pointer;
    font-size: 1.2rem;
    font-weight: 600;
    color: #7b8a97;
    padding-left: 2.5rem;
    position:relative;
    padding: 2px 1.2rem;
    border-radius: 3rem;
    border: 1px #7b8a97 solid;
    background:none;
    @include transition(all .2s ease);
    &:hover {
      color: #00a8cc;
      border: 1px #00a8cc solid;
    }
    &:before {
      content:'';
      position: absolute;
      right:1rem;
      font-family: "utestfont";
      font-size: 1.7rem;
      color:#00a8cc;
      line-height: 1.7rem;
      text-align: center;
      top:-0.9rem;
      margin-top:1.1rem;
    }
  }
}

//follow button
.cus-checkbox-follow {
  margin-top:.5rem;
  display:inline-block;

  label:hover {
    &:after {
      cursor: pointer;
    }
  }

  label:focus {
    outline: none;
    &:after {
      background: $blue;
      border-color: $blue;
    }
  }

  label:after {
    @extend %ntux-button;
    content: 'Follow';
    padding: 8px 28px;
    margin-top: 10px;
    color: #FFFFFF;
    margin-left: auto;
    margin-right: auto;
    border: 2px solid $blue;
    border-radius: 48px;
    text-align: center;
    font-weight: bold;
    font-size: 16px;
    background-color: $blue;
    width: 100px;
    top: -20px;
    position: relative;

    &:hover {
      cursor: pointer;
    }
  }

  input[type='checkbox']{
    display: block;
    width: 100px;
    margin: 0 auto;
    visibility: hidden;

    &:checked + label:after {
      content: 'Following';
      color: $dark-blue;
      border: 2px solid $dark-blue;
      background-color: $white;
    }

    &:checked + label:hover:after {
      content: 'Unfollow';
    }

    &:checked ~ .checkbox-toggle{
      border: 1px #22c064 solid;
    }
    &:checked ~ .checkbox-toggle:hover {
    }
    &:checked ~ .checkbox-toggle:before {
      content:'Following';
      color: #22c064;
    }
    &:checked ~ .checkbox-toggle:hover:before{
      content:'Unfollow';
      color: #22c064;
      top: -.1rem;
    }
  }
  .checkbox-toggle {
    width:8rem;
    height:2.4rem;
    display: block;
    cursor: pointer;
    position:relative;
    border-radius:3rem;
    border: 1px #bac1c6 solid;
    background:none;
    @include transition(all .2s ease);
    &:hover {
      border: 1px #22c064 solid;
      &:before {
        color: #22c064;
      }
    }
    &:before {
      content:'Follow';
      position: relative;
      color: #bac1c6;
      text-align: center;
      line-height: 1.5rem;
      width: 100%;
      font-size: 1.2rem;
      left: 0;
      top: -0.1rem;
      @include transition(all .2s ease);
    }
  }
  &.long .checkbox-toggle {
    width:8rem;
    text-align: initial;
    &.center {
      text-align: center;
    }
  }
}
//helper class
.noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
//Project Verify Checkbox
.verify-checkbox {
  position: relative;
  padding: 0 0 .8rem 0;
  margin: 0 0 1.3rem 0;
  width: 20rem;
  .check-button-text {
    margin: 0 0 0 3rem;
    line-height: 1.75rem;
    color: #262626;
    width: 20rem;
    text-align: left;
  }
  label:before {
    margin: 1rem 0 0 0;
  }
}

//aligning icon-share
.icon-share:before {
  margin-right: 0;
}

//shortener button
.shortener {
  display:inline-block;
  color: #ffffff;
  float: right;
  input[type='checkbox']{
    display: none;
    &:checked ~ .checkbox-toggle {
      &:before {
        content:'Show';
        color: #00a8cc;
      }
      &:after {
        height: 100%;
      }
    }
  }
  .checkbox-toggle {
    width:8rem;
    height:2.5rem;
    display: block;
    cursor: pointer;
    position:relative;
    border-radius:3rem;
    overflow: hidden;
    z-index: 1;
    border: 1px #ffffff solid;
    background:none;
    @include transition(all .2s ease);
    @include bp-tablet {
      display: none;
    }
    &:before {
      content:'Hide';
      position: absolute;
      font-size: 1.2rem;
      line-height: 2.3rem;
      text-align: center;
      z-index: 1;
      width:100%;
      @include transition(all .2s ease);
    }
    &:after {
      width: 100%;
      height: 0;
      top: -0.1;
      left: 0;
      background: #fff;
      content: '';
      position: absolute;
      z-index: 0;
      @include transition(all .3s ease);
    }
  }
}
.tags a {
  @include transition(all .2s ease);
  &:hover, &:focus {
    color: #3c4042;
  }
  @include bp-phone{
  	&:nth-child(n+3){
		display: none;
	}
  }
  &.small {
    text-transform: none;
  }
}
//blue submit button
.btn.blue-tab {
  width:6rem;
  min-width: 6rem;
  height:4.2rem;
  margin-left:-.5rem;
  color: #ffffff;
  padding-left:0;
  padding-right:0;
  border-top-left-radius:0px;
  border-bottom-left-radius:0px;
  vertical-align: top;
  font-size: 1.2rem;
  line-height: 2.4rem;
  letter-spacing: .05rem;
}

.ui-select-container {
  .btn.ui-select-match-item {
    width: auto;
    min-width: 7rem;
    height: 2.4rem;
    color: $gray-base;
    border-image-source: initial;
    border-image-slice: initial;
    border-image-width: initial;
    border-image-outset: initial;
    border-image-repeat: initial;
    font-size: 1.2rem;
    line-height: 1.5;
    cursor: auto;
    text-align: center;
    display: inline-block;
    font-weight: 500;
    text-transform: capitalize;
    background: transparent;
    margin-right: 0.5rem;
    letter-spacing: 0rem;
    padding: 0.2rem 1.5rem;
    border-radius: 1.5rem;
    border: 1px solid;
    &:focus, &:hover {
      color: rgb(123, 138, 151);
      background: transparent
    }
    span {
      font-size: 1.2rem;
    }
    .close.ui-select-match-close {
      line-height: 2rem;
      transition:all .2s ease-in-out;
      font-weight: 100;
      color: $gray-700;
      opacity: 1;
      font-size: 1.5rem;
      cursor: pointer;
      &:hover {
        color: #ef5454;
      }
    }
  }
  .btn {
    min-width: 0;
    text-align: left;
    text-transform: none;
    letter-spacing: 0;
    padding:0.6rem 0.7rem;
    .close.ui-select-match-close {
      font-size:2.1rem;
      line-height:1;
    }
  }
  &.btn-save:before, &.btn-save.saved:before {
    top: 1.1rem;
  }
}
.btn-auto {
  width:auto;
  max-width: 100%;
  min-width: 0;
}
.register-wrapper {
  margin: 0 1.25rem;
  @include bp-phone {
    margin: 1.25rem 0;
  }
  &:first-of-type{
    margin-left: 0;
  }
  &:last-of-type{
    margin-right: 0;
  }
  @include bp-phone {
    width: 100%;
  }
}

.back-to-top {
  @extend %ntux-button;
  @extend %ntux-hollow-button-on-white;
  position:absolute;
  bottom:0;
  right:0;
  &.ng-hide {
    display:none;
  }
  &.back-to-top--push-down {
    transform: translateY(200%);
  }
}

@media (max-width: 345px) {
  .back-to-top {
    position: unset;
    display: block;
  }
}

.btn.btn-img-icon {
  padding-left:3.2rem;
  img {
    position: absolute;
    left:0.5rem;
    top:0.9rem;
    width:2.2rem;
    height:2.2rem;
    margin: -.5rem 0.4rem -.4rem 0;
  }
  &.small {
    padding-left:2.3rem;
    img {
      width:1.4rem;
      height:1.4rem;
    }
  }
}

.btn-helper-text {
  font-size: 12px;
  line-height: 1.25;
  margin-top: 8px;
}
