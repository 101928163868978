//basic feed style
.feed-like, .feed-comment, .feed-view {
  color: #7b8a97;
  margin-left: 1rem;
  cursor: pointer;
  &:after {
    color: #7b8a97;
    content: attr(data-value);
  }
  &:hover:after {
    color: #262626;
  }
}
.feed-like:not(.clickable) {
  cursor:default;
  &:hover:after {
    color: #7b8a97;
  }
}
.feed-like + .func-link {
  margin-left:1rem;
}
.feed-like {
  &.active, &.clickable:hover {
    color:#fe1186;
  }
  &:focus {
    outline: none;
    text-decoration: underline;
  }
}
.feed-comment {
  &.active, &:hover {
    color: #8F6900;
  }
}
.feed-pin {
  &.active, &:hover {
    .icon {
      color:rgba(253,185,0,1);
    }
  }
}
.feed-view {
  .course &, .project &, .tool &, .article &, .status &{
    display: none;
  }
  &.active, &:hover {
    color:#1599e7;
  }
}
.status-btns {
  float: right;
}

.feed {
  padding:3rem 0;
  float: left;
  width: 100%;
  &-date {
    font-size: 2rem;
    font-weight: 400;
    color: #7b8a97;
    margin: 0.5rem 0.5rem 0.5rem 0;
    span{
      margin-right: 1.5rem;
      font-weight: 600;
      color: #262626;
      display: block;
      font-size: 2rem;
      text-transform: uppercase;
      @include bp-tablet {
        display: inline;
      }
    }
  }
  &.discussion, &.course {
    .time-stamp {
      color: #7b8a97;
      font-size: 1.2rem;
      line-height: .8rem;
      margin: .4rem 0 0 0;
      @include bp-phone {
        font-size: 1.4rem;
        line-height: 2.2rem;
      }
    }
    .rating {
      vertical-align: top;
    }
    &.sticky {
      background:#edf0f2;
      .heading, .time-stamp {
        padding-left: 1.7rem;
      }
    }
  }
  h4 {
    a {
      color: #262626;
      &:hover {
        color: #000000;
      }
    }
  }
  h3 {
    display: none;
    font-size: 2.2rem;
    margin-top: 0;
    margin-bottom: 1.2rem;
    a {
      color: #262626;
    }
    &:before {
      content:'Re: ';
    }
  }
  h2 {
    font-size: 3rem;
    margin-top: .5rem;;
    margin-bottom: 1.2rem;
    line-height: 1;
    @include bp-phone {
      font-size: 2.5rem;
    }
    a {
      color: #262626;
      &:hover {
        color: #000000;
      }
    }
  }
  p {
    margin: 0 0 1.5rem 0;
    font-size: 1.6rem;
    a {
      font-size: 1.6rem;
    }
  }
  &-header{
    margin-bottom: .6rem;
    &-info {
      float: left;
      &-icon, &-name {
        display: inline-block;
        vertical-align: top;
      }
      &-name {
        line-height: 1;
      }
      &-icon {
        margin-right: .5rem;
        line-height: 1;
        .profile-thumb-logo {
          display: none;
        }
      }
      &-name {
        h5 a{
          margin: 0;
          line-height: 1.4rem;
          font-weight: 600;
          color: #262626;
        }
        .time-stamp, .feed-type {
            font-size: 1.2rem;
            color:#7b8a97;
            a {
              font-size: 1.2rem;
              color:#7b8a97;
            }
        }
        .time-stamp {
          line-height:1.4;
        }
        .feed-type {
          text-transform: capitalize;
        }
      }
    }
  }
  &-img{
    margin-bottom: 2rem;
    border-radius:5px;
    overflow:hidden;
    width: 100%;
    height:10rem;
    display: none;
    @include bp-tablet {
      width:10rem;
      float: left;
      margin: 0 2rem 0 0;
    }
  }
  &-content{
    display: inline-block;
    vertical-align: bottom;
    width: 100%;
    &-heading {
      position: relative;
      margin-top: 1rem;
      margin-bottom: .5rem;
    }
    .discussion-cate {display:none;}
  }
  &.people {
    padding: 2rem 0 1rem;
  }
  .func-btns {
    @include bp-phone {
      float: right;
      line-height: 1;
    }
    &.deleted {
      @include bp-xs {
        margin-bottom: 50px;
      }
    }
  }
  &.article, &.course, &.tool, &.status, &.reply {
    .icon-thumb {display:none;}
  }
  &.discussion, &.project{
    .feed-type {display:none;}
  }
  &.project{
    .profile-thumb {display:none;}
  }
  &.discussion{
    .profile-thumb {display:inline-block;}
  }
  &.discussion, &.article, &.course, &.tool, &.status, &.reply{
    .feed-date {display: none;}
  }
  &.discussion{
    .discussion-cate {display:block;}
  }

  &.project, &.article, &.tool {
    .feed-content-main {
      display: block;
      display: -webkit-box;
      height: 5.3rem;
      margin-bottom: 2rem;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      @include bp-phone {
        max-height: 7.9rem;
        margin-bottom: 2rem;
        -webkit-line-clamp: 3;
      }
    }
  }

  &.tool, &.status_badge {
    .feed-header {
      padding: 0 0 1.2rem 0;
    }
    .feed-img {
      display: inline-block;
    }
    .feed-content {
      @include bp-tablet{
        width: calc(100% - 12rem);
      }
      min-height: 5rem;
    }
    h2, .rating {
      display: inline-block;
    }
    h2{
      float: left;
      margin: 0 1rem .4rem 0;
    }
    .rating {
      top: 0.5rem;
      color: #9ea4a9;
      @include bp-phone {
        top: 0.1rem;
      }
      span {
        top: .4rem;
        margin-right: .3rem;
      }
    }
  }
  &.user {
    .func-btns, .feed-content {
      display: none;
    }
    .cus-checkbox-follow {
      float: right;
    }
    .community-follow-btn {
      float: right;
    }
  }
  &.status_badge {
    .feed-img {
      background-size: 10rem !important;
      @include bp-phone {
        margin-top: 1.5rem;
      }
    }
    .feed-content {
      @include bp-phone {
        text-align: center;
      }
    }
  }

  &.project .feed-date {
    font-size: 16px;
    line-height: 24px;
    color: $gray-700;
    display: inline-block;
    margin: 0;
  }

  &.project .feed-date span {
    font-size: 16px;
    font-weight: normal;
    text-transform: inherit;
  }

  &.project .feed-countries {
    display: inline-block;
    vertical-align: top;

    i {
      vertical-align: top;
      margin-right: 4px;
      color: #838E95;
    }

    span {
      line-height: 24px;
      font-size: 16px;
      display: inline-block;
      color: $gray-dark;
      vertical-align: top;
      max-width: 90%;
    }
  }
  &.ongoing h2 a{
    color: $black;
    font-weight: 600;
  }

  &.ongoing, &.urgent {
   .feed-header-info-icon, .feed-header-info-name {
    vertical-align: middle;
    }
  }

  &.ongoing .feed-header-info-icon i {
    color: $blue;
    vertical-align: middle;
  }

  &.urgent .feed-header-info-icon i {
    color: $icon-border-red;
    vertical-align: middle;
  }
  &.urgent h2 a{
    font-weight: 600;
    color: $black;
  }
  &.archived {
    opacity: .5;
    h2 a{
      color: #7b8a97;
      &:hover {
        color: #262626;
      }
    }
  }

  &.project .feed-header-featured {
    display: inline-block;
    margin-left: 28px;

    i {
      display: inline-block;
      color: #B38300;
      vertical-align: middle;
      margin-right: .5rem;
    }

    h5 {
      display: inline-block;
      vertical-align: middle;
    }
  }
  &.featured {
    background-color: #f3f4f5;
    border-radius: 25px;
    padding: 1.5rem;
    margin-top: 1.5rem;
    margin-bottom: 1rem;
  }
  &.reply {
    .profile-thumb, .feed-type, h3, .rating{display:inline-block;}
    /*--h2, .discussion-cate, .feed-img, p, .profile-thumb-logo {
      display: none;
    }--*/
    .rating .icon-star:before, .rating .icon-star-rated:before {
      font-size: 1.5rem;
    }
    .rating {
      top: 0rem;
    }
    h3 {
      float: left;
      margin-right: 1rem;
    }
    .feed-content {
      width: 100%;
    }
    blockquote {
      display: block;
      margin-top: .5rem;
    }
  }
  &.industry-news {
    .profile-thumb-logo {
      display: inline-block !important;
    }
    .profile-thumb, .icon-thumb {
      display: none;
    }
    .rating {
      margin-top: 0.3rem;
    }
  }
  &.my-article {
    padding-bottom: 0;
    @include bp-tablet {
      padding-bottom: 3rem;
    }
    .feed-header-info-name {
      margin-bottom: 1rem;
      display: block;
      .feed-type, .time-stamp {
        font-size: 1.4rem;
      }
    }
    .my-article-content {
      @include bp-tablet {
        width: calc(100% - 30rem);
        display: inline-block;
      }
      h4 {
        margin:0;
      }
    }
    .func-btns {
      float: none;
      margin: 2rem auto 0rem;
      width: 100%;
      text-align: right;
      @include bp-tablet {
        text-align: right;
        margin:0;
        float: right;
        display: inline-block;
        width: 25rem;
      }
      @include bp-phone {
        text-align: left;
      }
      .edit {
        display: none;
        @include bp-tablet {
          display: inline-block;
        }
      }
      .delete-confirmation{
        color: #7b8a97;
        font-size: 1.2rem;
        text-transform: capitalize;
        .delete {
          color: #ef5454;
        }
      }
    }
  }

  &.pending-article {
    padding-bottom: 1.5rem;
   .feed-header-info-name {
      margin-bottom: 1rem;
      display: inline-block;
      .feed-type, .time-stamp {
        font-size: 1.4rem;
      }
    }
    .pending-article-title {
      @include bp-tablet {
        width: 100%;
        display: inline-block;
      }
      h4 {
        margin:0;
      }
    }
    .pending-article-content {
      padding-top: .7rem;
      @include bp-tablet {
        width: 100%;
        display: inline-block;
      }
      p {
        margin-bottom: .8rem;
      }
      h4 {
        margin:0;
      }
    }

    .func-btns {
      float: none;
      margin: .5rem auto 0rem;
      width: 100%;
      text-align: right;
      @include bp-tablet {
        text-align: right;
        margin:0;
        float: right;
        display: inline-block;
        width: 25rem;
      }
    }
  }
  .feed-content-main {
    display: block;
    display: -webkit-box;
    height: 5.3rem;
    margin-bottom: 2rem;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    @include bp-phone {
      max-height: 7.9rem;
      margin-bottom: 2rem;
      -webkit-line-clamp: 3;
    }
  }
  .status p {
    font-family: 'Open Sans', sans-serif;
    font-weight: 800;
    color: #262626;
    font-size: 3rem;
    margin-top: .5rem;
    margin-bottom: 1.2rem;
    line-height: 1.1;
  }
}

.feed.leaderboard {
  border-top:1px solid $gray-light;
  &:first-child {
    border-top:0;
  }
}
.feed.my-article,
.feed.pending-article,
.feed.gamification {
  border-bottom:1px solid $gray-light;
  &:first-child {
    border-bottom:1px solid $gray-light;
  }
}

.feed.status p {
  font-family: 'Open Sans', sans-serif;
  font-weight: 800;
  color: #262626;
  margin-top: .5rem;
  margin-bottom: 1.2rem;
  &, a {
    font-size: 3rem;
    line-height: 1.1;
  }
}

.func-btns {
  .publish {
    transition:0.3s linear all;
    span {
      color:inherit;
      font-size: 1.2rem;
      font-weight: 500;
    }
    &:after {
      content:'\e806';
      font-family: "utestfont";
      font-size: 1.5rem;
      margin-top: .1rem;
      line-height: 0;
      top: 1rem;
    }
    &.done {
      border-color: #22c064;
      color:#22c064;
    }
  }
}

td.func-btns {
  display: table-cell;
  float:none;
  white-space: nowrap;
}

.search-result {
  .section-home-feed .discussion .status-btns {
    display:none !important;
  }
  .section-home-feed .discussion .heading {
    max-width:37rem;
  }

}
.important {
  .last .feed {
    border-bottom: none;
  }
  &.one {
    .feed {
      h2 {
        font-size: 3rem !important;
      }
    }
    .nav-tabs {
      li.active > a {
        border-bottom: 4px #ef5454 solid;
      }
    }
  }
}

feed-item {
  & + .load-more-btn {
    border-top: 1px #d2d6d9 solid;
  }
}

.feed-item {
  padding: 2.2rem 0;
  border-top: 1px #d2d6d9 solid;
  & + .load-more-btn {
    border-top: 1px #d2d6d9 solid;
  }
  .actions {
    font-size:1.5rem;
  }
  &.feed-item-single-view {
    padding-bottom:0;
  }
  &.compressed {
    padding:1.8rem 0;
    position: relative;
  }

  .flag_reason {
    .heading {
      border-bottom: 1px #d2d6d9 solid;
      padding-bottom:1rem;
      font-size: 1.5rem;
      font-weight: 600
    }
    .reason {
      font-size: 1.5rem;
    }

    small {
      color: $gray-on-white ;
      span > a {
        color: #262626
      }
    }
  }
}

.feed-item-header {
  text-align: right;
  padding-bottom: .5rem;
  font-size: 1.5rem;
  .feed-item-info {
    float:left;
  }
  .actions {
    a {
      margin-left: 0.4rem;
      font-size: 1.8rem;
      vertical-align: middle;
    }
    .flag-this {
      font-size: 1.8rem;
      top: -.2rem;
      position: relative;
      vertical-align: middle;
      * {
        font-size:inherit;
        vertical-align: middle;
      }
    }
  }
  .feed-item.feed-item-type-user & {
    padding-bottom: 0;
    .checkbox-toggle {
      margin-bottom: 0;
    }
  }
  .feed-item.compressed &{
    padding-bottom: 1.8rem;
  }
}

.feed-item-info {
  text-align: left;
  padding-left: 4.6rem;
  min-height: 3.5rem;
  position: relative;
  .profile-thumb, .icon-thumb {
    position: absolute;
    left:0;
    font-size: 1.6rem;
    line-height: 1.6em;
  }
  a {
    color:#262626;
    display:block;
    font-weight: 600;
    font-size: 1.4rem;
    line-height: 1.25;
    text-decoration: none;
  }
  small {
    display:block;
    color: $gray-on-white;
    font-size: 1.2rem;
    line-height: 1.3;
    padding:0.2rem 0 0;
    span {
      font-size: inherit;
    }
  }
  .feed-item.compressed & {
    padding-left: 3.6rem;
    min-height: 2.9rem;
    .profile-thumb {
      width: 2.4rem;
      height: 2.4rem;
    }
    .avatar-badges-wrapper {
      top:-0.2rem;
    }
    a, small {
      font-size: 1.2rem;
      line-height: 1.1em;
    }
  }
  &:hover, &:focus {
    text-decoration: none;
      .feed-item-info-name {
        text-decoration: underline;
      }
  }
}

.feed-item-header-title {
  font-size: 1.2rem;
  font-weight: 500;
}

.comment-header-title {
  padding: .3rem 0 .5rem 0
}

.feed-item-img {
  float:left;
  width:9.5rem;
  height:9.5rem;
  margin:0.7rem 2rem 0 0;
}

.feed-item-sub-title {
  margin: -0.5rem 0 0.5rem 0;
  color:#2d3234;
  overflow: hidden;
  white-space: nowrap;
  text-overflow:ellipsis;
  a {
    color: inherit;
  }
  .discussion-cate span:before {
    width:1.5rem;
    height:1.5rem;
    margin-top:-0.2rem;
  }
  .feed-item.compressed & {
    font-size: 1.2rem;
    .discussion-cate span:before {
      width:1.2rem;
      height:1.2rem;
      margin-top:-0.2rem;
    }
  }
}

.feed-item-title {
  margin:0 0 0.9rem 0;
  font-size: 2rem;
  line-height: 1.2em;
  color:#262626;
  font-weight: 800;
  display: block;
  a {
    font-size: inherit;
    color: inherit;
    .emojione {
      margin: auto;
    }
  }
  .feed-item.compressed & {
    font-size: 1.6rem;
    overflow: hidden;
    text-overflow:ellipsis;
    white-space: nowrap;
    margin-bottom: 0;
    margin-right:3rem;
    @media all and (max-width: $screen-sm) {
      overflow: visible;
      white-space: normal;
      margin-bottom: 1rem;
    }
  }
}

.feed-item-description {
  display:block;
  display: -webkit-box;
  max-height: 4.3rem;
  line-height:1.3;
  font-size: 1.6rem;
  overflow:hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  margin: 0 0 1rem 0;
  .feed-item-type-status & {
    font-size: 2.6rem;
    line-height:1.6;
    max-height:none;
    -webkit-line-clamp: initial;
  }
}

.feed-item-footer {
  padding: 1.3rem 0 0 0;
  text-transform: none;
  .actions {
    float: right;
  }
  .feed-item.compressed & {
    position: absolute;
    right:0;
    bottom:1.8rem;
    .actions {
      float:none;
      font-size: 1.2rem;
      [likeable] {
        display:none;
      }
    }
    @media all and (max-width: $screen-sm) {
      position: relative;
      text-align: right;
      bottom:auto;
    }
  }
}

.feed-item-type-badge_activity {
  // .feed-item-description {
  //   font-weight: 600;
  //   max-width:30rem;
  //   line-height:1.5em;
  //   word-break: normal;
  // }
  .feed-item-title {
    font-weight: 600;
    font-size:2.5rem;
    margin-top:2rem;
  }
  .actions {
    display:none;
  }
}

.feed-loading-message {
  text-align: center;
  font-size: 1.2rem;
  font-weight: 800;
  color:#545963;
  padding: 3rem 0;
}

.section-single-comment-short-footer {
  text-align: center;
  background:#f6f8f9;
  padding:1rem 2rem;
  display:block;
}
status-attachment {
  .gif-control {
    &:focus-within {
      .ff-container {
        outline: auto;
        outline: -webkit-focus-ring-color auto 5px;
        &.ff-active {
          .ff-overlay {
            display: block;
            background-image: url('/static/assets/images/pause-button.png');
          }
        }
      }
      img {
        outline: none;
      }
    }
  }
}

.status-attachment {
  iframe {
    width: 45rem;
    height: 30rem;
  }
  .img-crop {
    padding:.5rem;
    border:1px solid $gray-lighter;
    &:hover {
      .ff-container {
        &.ff-active {
          .ff-overlay {
            display: block;
            background-image: url('/static/assets/images/pause-button.png');
          }
        }
      }
    }
  }
  @media (max-width: $screen-sm) {
    iframe {
      width:auto;
      width: 100%;
      min-height:25rem;
      height:auto;
    }
  }
  .feed-item.feed-item-single-view & {
    text-align: center;
    > .img-crop {
      max-width: 70%;
      max-height:none;
      display:inline-block;
      cursor: default;
    }
  }
}

.status-attachment_link {
  display:block;
  text-align: left;
}

.img-crop {
  max-width: 20rem;
  max-height: 20rem;
  overflow:hidden;
  position: relative;
  display: block;
  img {
    max-width: 100%;
  }
  .section-single-body &.clickable {
    max-width:60%;
    max-height:none;
    margin:0 auto 1.5rem auto;
  }
  .section-single-body &:not(.clickable) {
    text-align: center;
    max-width: none;
    max-height: none;
    margin:0 0 1.5rem 0;
  }
}

.img-crop-footer {
  position: absolute;
  height:3rem;
  width:100%;
  bottom:0;
  display:none;
  transition: all 300ms;
  box-shadow: inset 0 -30px 30px -30px rgba(0,0,0,.2);
}

.img-crop.clickable:hover > .img-crop-footer {
  box-shadow: inset 0 -20px 20px -20px rgba(0,0,0,.3);
}
.img-crop.clickable > .img-crop-footer {
  display:block;
}

.img-crop-modal {
  background:rgba(60, 64, 67, .8);
  .modal-dialog {
    width:auto;
    max-width:80%;
  }
  .modal-header {
    display:none;
  }
  .modal-content {
    background:none;
    box-shadow: none;
  }
  .modal-body {
    padding:1.5rem;
  }
  .close {
    position: fixed;
    top:3rem;
    right:3rem;
    z-index:99999;
  }
}

.emoji-list {
  list-style:none;
  margin:0;
  padding:0;
  li {
    float:left;
    width:33.33%;
    white-space: nowrap;
    overflow:hidden;
    padding:.5rem 1rem;
    border-radius:.3rem;
    @media(max-width: $screen-sm) {
      width: 50%;
    }
    @media(max-width: $screen-xs) {
      width: 100%;
    }
  }
  code {
    overflow:hidden;
  }
}

.emoji-tone-picker {
  display:inline-block;
  margin-top: 1rem;
  line-height: 3rem;
  vertical-align: middle;
  transition: all 200ms;
  opacity: 0.3;
  &.clickable {
    opacity: 1;
    &:hover {
      font-size: 120%;
    }
    &:active {
      font-size: 140%;
    }
    &.active {
      font-size: 140%;
    }
  }
}

@keyframes reloadBtnLabel {
  from {
    opacity:0;
    max-width: 0%;
  }
  to {
    opacity:1;
    max-width: 100%;
  }
}
@keyframes reloadBtnIcon {
  from {
    opacity:0;
    transform: rotate(0deg);
  }
  to {
    opacity:1;
    transform: rotate(-540deg);
  }
}

project-feed-item {
  border-bottom: 1px solid $gray-light;
}

.feed-actions {
  float:left;
  @media (max-width: $screen-sm) {
    float:none;
  }
}

.tab-pane {
  @extend .clearfix;
}
