%ntux-button {
  min-width: 160px;
  padding: 8px 28px;
  margin-top: 10px;
  color: #FFFFFF;
  margin-left: auto;
  margin-right: auto;
  border: 2px solid $blue;
  border-radius: 48px;
  text-align: center;
  font-weight: bold;
  font-size: 16px;
  transition:0.2s linear all;
  background-color: $blue;
  &:active, &:focus {
    border: 2px solid $dark-blue;
    background: $dark-blue;
    background-color: $dark-blue;
    box-shadow: 0 0 3px 2px lighten($blue, 25%);
    text-decoration: none;

  }
  &:hover {
    border: 2px solid $dark-blue;
    background: $dark-blue;
    background-color: $dark-blue;
    text-decoration: none;
  }
}

%ntux-button-small {
  font-size: 14px;
  padding: 8px 28px;
}

%show-more-button {
  text-align: center;
  margin-top: 10px;
  padding: 14px 0 14px 0;
  cursor: pointer;
  font-size: 14px;
  display: block;

  &:hover {
    text-decoration: underline;
    color: $blue;
  }
}
%ntux-hollow-button-on-white {
  color: $dark-blue;
  border: 2px solid $dark-blue;
  background-color: $white;

  &:hover, &:active, &:focus {
    background-color: $gray-50;
  }
}

%ntux-hollow-button-on-gray {
  color: $dark-blue;
  border: 2px solid $dark-blue;
  background-color: $gray-50;

  &:hover, &:active, &:focus {
    background-color: $white;
  }
}

%component-header {
  margin-top: 40px;
  font-size: 24px;
  font-weight: 600;
  padding-bottom: 24px;
}
