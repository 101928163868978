.community-feed-item-hide, .community-feed-item-report, .community-feed-item-subscribe, .community-archive-btn, .community-feed-item-pin {
    background: none;
    border: none;
    color: black;
    padding: 0 2px;
    i {
      font-size: 18px;
      color: $gray-base;
    }
  }

  .community-feed-item-hide {
    &:focus {
      i.material-icons.hide-icon {
        color: $icon-color-red;
        text-decoration: underline;
      }
    }
    &:hover {
      i.material-icons.hide-icon {
        color: $icon-color-red;
      }
    }
  }

.community-feed-item-report {
  &:focus {
    .flag-icon {
      color: $icon-color-red;
      text-decoration: underline;
    }
  }
  &:hover {
    .flag-icon {
      color: $icon-color-red;
    }
  }
}

  .community-feed-item-subscribe {
    &:focus {
      .subscribe-icon {
        color: $icon-border-green;
        text-decoration: underline;
      }
    }
    &:hover {
      .subscribe-icon {
        color: $icon-border-green;
      }
    }
    &.active {
      .subscribe-icon {
        color: $icon-border-green;
      }
      &:hover {
        .subscribe-icon {
          color: $icon-border-green;
        }
      }
    }
  }

  .community-archive-btn {
    a.active, &:hover {
      i {
        color: $icon-color-orange;
      }
    }
  }

  .community-archive-btn {
    a:focus {
      i.material-icons {
        text-decoration: underline;
      }
    }
  }

.community-feed-item-pin {
  &.active, &:hover {
    i.material-icons {
      color: rgba(253,185,0,1);
    }
  }
}
