/*!
 * Copyright (C) 2020 to Present Applause App Quality, Inc. All rights reserved.
 */

@import "../colors";
@import "../fonts";

.ngf-dialog {
  mat-dialog-container {
    padding: 20px 24px !important;
  }

  &.ngf-dialog-without-padding {
    mat-dialog-container {
      padding: 0 !important;
    }
  }

  .mat-dialog-actions {
    padding: 0;
    margin-bottom: 0;
  }
}

.ngf-dialog-backdrop {
  background-color: $ngf-grey-900;
  opacity: 0.6 !important;
}

[mat-dialog-title] {
  @include font-large;
  color: $ngf-grey-900;
}

[mat-dialog-content] {
  @include font-base;
  color: $ngf-grey-900;
}
