.community-nav {
  // background: linear-gradient(270deg, #0369B0 0%, #00A8CC 60%); 
  // remove linear gradient in favor of solid color, to better
  // meet contrast requirements for WCAG 1.4.3 Contrast (Minimum)
  // mainly this targes the space behind the nav link items' text
  background-color: #007DA3;
  width: 100%;
  min-width: 250px;
  text-align: center;
  height: 45px;
  padding-top: 2px;
  position: absolute;
  z-index: 2000;

  .community-nav-item {
    width: 10%;
    min-width: 75px;
    display: inline-flex;
    box-sizing: content-box;
    &:hover {
      border-bottom: solid 2px #FFFFFF;
    }
  }

  .community-nav-item--selected {
    border-bottom: solid 2px #FFFFFF;
    &:hover {
      border-bottom: solid 2px #FFFFFF;
    }
  }

  .community-nav-item__link {
    color: #FFFFFF;
    width: 100%;

    &:hover {
      text-decoration: none;
    }
  }

  .community-nav-item__link--selected {
    color: #FFFFFF;
  }

  .community-nav-item__text {
    font-size: 1em;
    font-weight: 500;
    padding: 1rem 0;
    letter-spacing: 1px;
    text-transform: uppercase;
  }

  .community-nav-item__text--selected {
    font-weight: bold;
  }
}
