/*!
 * Copyright (C) 2023 to Present Applause App Quality, Inc. All rights reserved.
 */

@use "@angular/material" as mat;

@use 'sass:map';
@use 'ng-falcon-theme';
@use 'ng-falcon';
@use 'calendar-override';

$calendar-selected-background-color: ng-falcon.$color_applause-blue-60;
$calendar-today-border-color: ng-falcon.$color_applause-blue-60;
$calendar-range-background-color: ng-falcon.$color_applause-blue-10;
$calendar-highlighted-background-color: ng-falcon.$color_applause-blue-30;

@include mat.datepicker-color(ng-falcon-theme.$ngf-theme);
@include mat.datepicker-date-range-colors($calendar-range-background-color);
@include calendar-override.today-border-color($calendar-today-border-color);
@include calendar-override.highlighted-background($calendar-highlighted-background-color);
