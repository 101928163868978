/*!
 * Copyright (C) 2023 to Present Applause App Quality, Inc. All rights reserved.
 */

@mixin today-border-color($border-color) {
  .mat-calendar-body-today {
    @include _unselected-cell {
      border-color: $border-color;
    }
  }
}

@mixin highlighted-background($background-color) {
  .cdk-keyboard-focused .mat-calendar-body-active,
  .cdk-program-focused .mat-calendar-body-active {
    @include _highlighted-cell($background-color);
  }

  @media (hover: hover) {
    .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover {
      @include _highlighted-cell($background-color);
    }
  }
}

@mixin _highlighted-cell($background-color) {
  & > .mat-calendar-body-cell-content {
    @include _unselected-cell {
      background-color: $background-color;
    }
  }
}

@mixin _unselected-cell {
  &:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    @content;
  }
}
