/*!
 * Copyright (C) 2020 to Present Applause App Quality, Inc. All rights reserved.
 */

@use "ng-falcon";

$button-height-default: 44px;
$button-height-reduced: 36px;
$button-height-small: 24px;
$button-icon-small: 14px;
$button-icon-reduced: 26px;

button, a {
  &[ngfButtonIcon=""] {
    @include ng-falcon.font_font-base();
    @include ng-falcon.transition_short-linear(background-color, color, box-shadow);

    display: inline-flex;
    align-items: center;
    justify-content: center;

    width: $button-height-default;
    height: $button-height-default;
    padding: 0;

    color: ng-falcon.$color_applause-blue-50;
    background-color: ng-falcon.$color_white;

    font-family: proxima-nova, "Helvetica Neue", Helvetica, Arial, sans-serif;

    cursor: pointer;
    text-decoration: none;

    &:hover:not(:disabled),
    &:active:not(:disabled),
    &:focus-visible {
      color: ng-falcon.$color_applause-blue-60;
      background-color: ng-falcon.$color_applause-grey-50;
    }

    &:active:not(:disabled),
    &:focus-visible {
      box-shadow: 0 0 3px 2px #46BAFD; // TODO-MKU: ask Ben about adding new color to palette
    }

    &:focus-visible {
      outline: none;
    }

    &:disabled {
      cursor: not-allowed;

      color: rgba(ng-falcon.$color_applause-blue-50, 0.5);
      background-color: ng-falcon.$color_white;
    }

    &[small] {
      @include ng-falcon.font_font-small();
      line-height: 22px;
      height: $button-height-small;
      width: $button-height-small;

      .mat-icon {
        height: $button-icon-small;
        width: $button-icon-small;
        font-size: $button-icon-small;
      }
    }

    // TODO: reduced vs small? we should have only one
    &[reduced] {
      height: $button-height-reduced;
      width: $button-height-reduced;

      .mat-icon {
        height: $button-icon-reduced;
        width: $button-icon-reduced;
        font-size: $button-icon-reduced;
      }
    }

    &[box] {
      border: 1px solid ng-falcon.$color_applause-grey-100;
      border-radius: 4px;

      &[leftCornersSquare] {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }

      &[rightCornersSquare] {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }

      &[noRightBorder] {
        border-right: none;
      }

      &[noLeftBorder] {
        border-right: none;
      }

      &[noBorder] {
        border: 0;
      }
    }
  }

  &[ngfButtonIcon="legacy"] {
    font-family: proxima-nova, "Helvetica Neue", Helvetica, Arial, sans-serif;

    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: ng-falcon.$color_applause-grey-500;
    border: none;
    background: none;
    padding: 0;
    margin: 0;
    line-height: 0;

    cursor: pointer;
  }
}
