.rating {
  display:inline-block;
  vertical-align:middle;
  position: relative;
  color: #7b8a97;
  &:focus {
    outline: 0;
  }
  .icon-star:before,  .icon-star-rated:before {
    color:#d2d6d9;
    font-size: 2rem;
    margin: .2rem 0;
    .comment-form & {
      font-size: 1.8rem;
    }
  }
  .icon-star-rated:before {
    color: #fdb900;
  }
  .project &, .article &, .discussion &, .status & {
    display: none !important;
  }
  span {
    margin: 0 1px 0 0;
    &:focus {
      outline: 0;
    }
  }
}

.section-home-cover {
  .icon-star:before   {
    color: #7b8a97;
  }
  .icon-star-rated:before {
  }
}
