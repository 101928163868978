//filter dropdown
.filter {
  &:focus-within {
    outline: 2px solid #000000;
    transition: none;
    .filter-Val {
      color: $gray-dark;
    }
  }
  background:#edf0f2;
  border-radius: 4px;
  position: relative;
  display: inline-block;
  &.search-filter {
    width: 23%;
    float: right;
    @include bp-phone {
      display: none;
    }
  }
  &.to-do-filter {
    background:#edf0f2;
    width:100%;
    margin-top:.5rem;
    .to-do-completed & {
      background: #fff;
    }
    .main-content-to-do & {
      margin-top: 2rem;
      display: inline-block;
      @include bp-tablet {
        width: 30%;
        float:right;
      }
    }
  }
  &.course-filter, &.project-filter, &.article-filter, &.tool-filter, &.discussion-filter, &.liked-content-filter {
    background:#edf0f2;
    width: 100%;
    float: right;
    margin-bottom:1rem;
    @include bp-tablet {
      width: 20rem;
      margin-left: 1rem;
    }
  }
  &.article-filter {
    margin-top: 2rem;
  }
  &.project-filter {
    min-width: 25rem;
    // do not use float, it messes the logical order of the elements
    float: none;
  }
  &.discussion-filter {
    &.category {
      float: none;
      margin: 1rem 0;
    }
    @include bp-tablet {
      width: auto;
      min-width: 25rem;
      .discussion-cate, .discussion-cate span {
        font-size: 1.2rem;
      }
    }
  }

  .filter-Val {
    width: calc(100% - 3.2rem);
    padding: .7rem .5rem .7rem 1.2rem;
    display: inline-block;
    font-size: 1.2rem;
    font-weight: 600;
    color: #636D74;
    letter-spacing:.4px;
    background: none;
    outline: none;
    border:0px;
    height: 100%;
    &:hover {
      color: $gray-dark;
    }
  }
  .check-toggle {
    display: none;
    &:checked ~ .filter-options {
      display: block;
      overflow: auto;

    }
  }
  .filter-Toggle {
    width: 3.2rem;
    display: inline-block;
    float: right;
    padding:.55rem 1rem;
    border-top-right-radius: 4px;
    border-bottom-right-radius:4px;
    margin-bottom:0;
    cursor:pointer;
    &:after {
      content:'\e80c';
      font-family: "utestfont";
      font-size: 1.2rem;
      position:relative;
    }
  }

  .filter-options {
    position:absolute;
    left: 0rem;
    width: 100%;
    background:#fff;
    border-radius: 4px;
    box-shadow: 0 2px 10px #ccc;
    padding: 1rem 0;
    z-index: 11;
    li {
      list-style: none;
      padding: .5rem 1.2rem;
      font-size: 1.2rem;
      font-weight: 600;
      color:#3c4042;
      letter-spacing: .4px;
      cursor: pointer;
      border-left: 4px solid transparent;
      &:focus {
        outline: 2px solid #000000;
        border-radius: .1rem;
      }
      &:active, &:hover  {
        background: $gray-light;
      }
    }
    .filter-options__item {
      &.selected {
        background-color: $blue;
        color: $white;
        &:active, &:hover {
          box-shadow: 0px 0px 4px 3px rgba(0, 0, 0, 0.5);
        }
      }
    }
  }

}
