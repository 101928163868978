//global activity style
#activity{
  .profile-activity-feed-item {
    border-top: 1px #d2d6d9 solid;
    padding: 2rem 0;
    display: block;
    .activity-icon {
      &:after {
        display: inline-block;
        font-family: utestfont;
        font-size: 1.5rem;
        vertical-align: top;
        margin-right:.6rem;
      }
    }
    .activity-feed-content {
      font-size: 1.2rem;
      color:#3c4042;
      line-height: 1.6rem;
      display: inline-block;
      width: 88%;
      padding-top: 0rem;
      > .profile-thumb {
        display: none;
      }
      .content-extra{
        display:none;
      }
      > a {
        color:#3c4042;
        font-size:1.4rem;
        font-weight: 600;
        &:hover {
          color:#00a8cc;
        }
      }
    }
    .follow-btn {
      display:none;
    }
    .user-tooltip .follow-btn {
      display:block;
    }
  }
  .time-stamp {
    font-size: 1.2rem;
    padding-left:2.3rem;
  }
  .see-all-activity {
    width:100%;
    margin-bottom: 7rem;
    border-top: 1px #d2d6d9 solid;
    text-align: center;
    padding: 2rem 0;
    a {
      font-weight: 600;
      color:#00a8cc;
    }
  }
}
.follow_activity:after {
  content:'\e808';
  color: #1599e7;
}
.like_activity:after {
  content:'\e807';
  color:#fe1186;
}
.comment_activity:after {
  content:'\e80b';
  color:rgba(253,185,0,1);
}
.mention_activity:after {
  content:'\e814';
  color:rgba(0, 168,204, 1);
}
.badge_activity:after {
  content:'\e808';
  color:rgba(0, 168,204, 1);
}
.at_mention_activity:after {
  content: '\e814';
  color:#00a8cc;
}

//full page activity style modification
.activity-feed-wrapper,
#mainContentActivity {
  @extend #activity;
  .profile-activity-feed-item {
    .activity-icon:after {
      font-size: 1.6rem;
      margin-top: 1rem;
      margin-right: 1rem;
      width: 1.6rem;
    }
    .activity-feed-content {
      //width:calc(100% - 4rem);
      display:inline-block;
      width:80%;
      > .profile-thumb {
        width:2.3rem;
        height:2.3rem;
        margin-top: 1.2rem;
        margin-right: .75rem;
        vertical-align: middle;
        display: inline-block;
      }
      .activity-header {
        display: inline-block;
        vertical-align: middle;
        width: 90%;
        @include bp-phone {
          width: 89%;
        }
        .time-stamp {
          padding-left: .1rem;
          padding-top: 1.8rem;
        }
      }
    }
    .follow-btn {
      display:block;
      @media (max-width: $screen-xs-max) {
        display:none;
      }
    }
  }
  .content-extra {
    font-size: 1.2rem;
    padding: 1.5rem 1.5rem 0 1.5rem;
    border-radius: .3;
    display: block;
    background:#f6f8f9;
    margin:1.5rem 0 0 3rem;
    a {color: #00a8cc;}
    p {
      font-size: 1.2rem;
      margin: 0;
      padding-bottom: 1.5rem;
    }
    li {
      font-size: 1.2rem;
    }
  }
  .time-stamp {
    text-align: left;
    position: relative;
    margin-top: -1.1rem;
    padding-left: 6.3rem;
    color:#7b8a97;
  }
}

.activity-feed-wrapper {
  .time-stamp {
    padding-left: 3.4rem;
  }
  .activity-icon {
    display:none;
  }
}

@media (max-width: $screen-xs-max) {
  .activity-feed-wrapper,
  #mainContentActivity {
    .time-stamp {
      margin-top: 0.5rem;
      padding-left:0;
    }
    .content-extra {
      margin-top: 0.5rem;
      margin-left:0;
    }
    .profile-activity-feed-item .activity-icon {
      display:none;
    }
    .profile-activity-feed-item .activity-feed-content {
      width:100%;
      > .profile-thumb {
        margin-top:0;
        margin-bottom:.5rem;
      }
    }
  }
}
