.button {
  display: inline-block;
  border:2px solid $gray-shade-2;
  background: $gray-shade-2;
  font-size: 1.3rem;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
  line-height:1.1em;
  vertical-align:middle;
  cursor: pointer;
  color:#fff;
  letter-spacing: 1px;
  padding: 13px 14px 11px;
  max-width: 100%;
  transition: all 300ms;
  min-width: 232px;
  &:focus {
    outline:none;
  }
  &:active {
    transition: all 100ms;
  }
}

@mixin button($color, $textColor: $white) {
  background: $color;
  border-color: $color;
  &,
  &:visited {
    color: $textColor;
  }
  &:hover {
    background: mix($white, $color, 10%);
    border-color: mix($white, $color, 10%);
  }
  &:active {
    background: mix(#000, $color, 10%);
    border-color: mix(#000, $color, 10%);
  }
}

@mixin buttonSemiGhost($color, $textColor: $white) {
  background: transparentize($color, 0.3);
  border-color: $color;
  color: $textColor;
  &:hover,
  &:visited {
    color: $textColor;
  }
  &:hover {
    background: mix($white, transparentize($color, 0.3), 10%);
    border-color: mix($white, transparentize($color, 0.3), 10%);
  }
  &:active {
    background: mix(#000, transparentize($color, 0.3), 10%);
    border-color: mix(#000, transparentize($color, 0.3), 10%);
  }
}

@mixin buttonGhost($color, $textColor: $white) {
  border-color: $color;
  background: transparent;
  &, &:visited {
    color: $textColor;
  }
  &:hover {
    border-color: mix($white, $color, 10%);
  }
  &:active {
   border-color: mix(#000, $color, 10%);
  }
}

.button--primary {
  @include button($brand-primary);
}

.button--primary-semi-ghost {
  @include buttonSemiGhost($brand-primary);
}

.button--primary-ghost {
  @include buttonGhost($brand-primary);
}


