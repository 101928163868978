/*!
 * Copyright (C) 2020 to Present Applause App Quality, Inc. All rights reserved.
 */

// TODO use those styles after simpleMDE editor removal
//@import '~easymde/dist/easymde.min.css';
@import 'ngx-easymde/lib/index.scss';

.editor-toolbar {

	button:not(.activation-btn):not(.embed-button) {
		width: 30px;
		height: 30px;
		border: 1px solid transparent;
		border-radius: 3px;
		color: #7b8a97;
		background-color: transparent;

		&:focus, &:hover, &:active {
			border: 1px solid #95a5a6;
			background-color: #fcfcfc;
		}
	}

	&.disabled-for-preview button:not(.no-disable) {
		background: none;
		opacity: .3;
		pointer-events: none;
	}

	&:hover {
		opacity: 1;
	}
}

.editor-preview-side {
	z-index: 2500;
}

.community .community-feed-item-comment-form__input .CodeMirror {
	min-height: 50px;
	height: auto;
  max-height: 500px;

  .CodeMirror-scroll {
    min-height: 50px !important;
    height: auto;
    max-height: 500px;
    margin-bottom: -25px !important;

    .CodeMirror-sizer {
      margin-bottom: 0 !important;
    }
  }

  .cm-s-easymde .CodeMirror-wrap {
    height: auto !important;
  }
}

.CodeMirror-fullscreen, .editor-preview-side.editor-preview-active-side {
	z-index: 2000;
}
