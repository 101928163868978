.section-home-cover {
  width:100%;
  padding:1rem 0 3rem 0;
  position: relative;

  .btn-yellow {
    @extend %ntux-button;
    background: #fdb900;
    border-color: #fdb900;
    &:active, &:focus {
      border: 2px solid #fdb900;
      background: #fdb900;
      background-color: #fdb900;
      box-shadow: 0 0 3px 2px lighten(#fdb900, 25%);
      text-decoration: none;
    }
    &:hover {
      border: 2px solid #fdb900;
      background: #fdb900;
      background-color: #fdb900;
    }
  }

  @include bp-phone {
    text-align: center;
  }
  .section-title {
    color: #fff;
    margin: 0 0 0 0;
    @include bp-phone {
      text-align: center;
      width: 100%;
    }
  }
  .section-desc {
    color:#fff;
    font-size: 1.6rem;
    line-height: 1.6;
    a {
      color: #fff;
      font-size:1.6rem;
      text-decoration: underline;
    }
    .show-all {
      text-decoration: underline;
      cursor: pointer;
    }
    p {
      color:#fff;
      font-size: 1.6rem;
      line-height: 1.6;
    }
  }
  .section-title-unauth {
    @extend .section-title;
    text-align: center;
    color: #000000;
    margin-top: 30px;
    font-size: 3rem;
  }
  .section-big-title-unauth {
    @extend .section-title-unauth;
    font-size: 3.6rem !important;
    margin-top: 42px;
  }
  .section-desc-unauth {
    @extend .section-desc;
    text-align: center;
    hyphens: none;
    color: #000000;
    margin-bottom: 50px;
    a {
      color: #000000;
    }
  }
  .section-big-title-unauth-redesign {
    font-weight: 800;
    font-size: 48px;
    line-height: 57.6px;
    letter-spacing: -1%;
    text-align: center;
  }
  .section-desc-unauth-redesign {
    font-weight: 400;
    font-size: 16px;
    line-height: 26.1px;
    letter-spacing: 0%;
    text-align: center;
    max-width: 660px;
    font-style: normal;
    color: #1B1B1B;
    flex: none;
    align-self: center;
    margin: 0 auto;
    hyphens: none;
  }

  .tool-category{
    h2{
      width: 100%;
    }
    .tool-btn{
      float: right;
      width: 100%;
      margin-top:1rem;
    }
    p{
      float: left;
      text-align: left;
      padding: 1rem 0;
      width:100%;
    }
    @include bp-phone {
      p{
        text-align: center;
      }
    }
  }
}

.section-home-unauth-cover {
  @extend .section-home-cover;
  padding-bottom: 0;
}

.section-bottom-bg {
  background: #5CC7DE;
}

.unauth-shadow-box {
  background: white;
  box-shadow: 0 0 4px rgba(0,0,0,0.14);
  -webkit-box-shadow: 0 0 4px rgba(0,0,0,0.14);
  border-radius: 4px;
}

.higher-z-index {
  z-index: 2;
  position: relative;
}

.low-z-index {
  z-index: 1;
  position: relative;
}

.margin-top-1 {
  margin-top: 1px;
}

.margin-bottom-8 {
  margin-bottom: 8px;
}

.margin-bottom-top-0 {
  margin-bottom: 0;
  margin-top: 0;
}

.padding-sides-20-px {
  padding-left: 20px;
  padding-right: 20px;
}

.padding-sides-40 {
  padding-left: 40px;
  padding-right: 40px;
}

.padding-60px {
  padding-top: 60px;
  padding-bottom: 60px;
}

.padding-top-55 {
  padding-top: 55px;
}

.padding-bottom-35 {
  padding-bottom: 35px;
}

.padding-bottom-20 {
  padding-bottom: 20px;
}

.central {
  text-align: center;
  vertical-align: middle;
}

.horizontal-center {
  text-align: center;
}

.employee-image-header {
  font-size: 16px;
  font-weight: bold;
}

.employee-image {
  min-width: 180px;
  height: 170px;
  padding-right: 10px;
}

.mission-image {
  margin-top: -120px;
  max-width: 360px;
}

.statistics {
  border-left: #00A8CC 3px solid;
  margin-top: 10px;
  margin-bottom: 10px;
}

.link-without-decorations {
  text-decoration: none;
  color: #262626;
}

.section-home-cover-bg {
  position: absolute;
  top:0;
  left:0;
  width:100%;
  height:100%;
  z-index:0;
  overflow:hidden;
  background:#151515;
  div {
    width:100%;
    height:100%;
  }
  + div {
    position: relative;
    z-index:1;
  }
}

.section-home-unauth-cover-bg {
  @extend .section-home-cover-bg;
  overflow: visible;
}

.section-home-cover-random {
  width:100%;
  float: left;
  background: rgba(255,255,255, .8);
  border-radius: .4rem;
  padding:2rem;
  margin-top:2.5rem;
  text-align: left;
  a.discussion-title-link {
    color: $gray-base;
    font-weight: 800;
    font-size: 2.2rem;
    line-height: 1.2;
  }
  &.discussion {
    .discussion-cate, .discussion-cate span {
      font-size:1.6rem;
    }
  }
  &.discussions {
    background: $white;
  }
  &.project {
    h5 {
      margin-bottom: .3rem;
      margin-top: .8rem;
      text-transform: uppercase;
      .location {
        font-weight:400;
        color: #3c4042;
        font-size: 1.6rem;
        text-transform: none;
      }
    }
    h3 {
      margin-top:0;
      margin: 0 0 1.2rem 0;
    }
  }
  &.project {
    p {margin: 0 0 0.8rem 0;}
    h3{
      margin: 0 0 1rem 0;
      a {color:#ef5454;}
    }
    h5 {
      margin: 0;
      font-weight: 600;
      letter-spacing: .05rem;
    }
    &.ongoing h3 a {
      color: #00a8cc;
      &:hover {
        color: #34495e;
      }
    }
    &.urgent h3 a {
      color: #ef5454;
      &:hover {
        color: #AF3E3E;
      }
    }
  }
  &.course {
    p {margin-top: 0;}
    h3, p {
      margin-bottom: 1.2rem;
    }
    .rating {
      margin: 0 0 4px 0;
      .icon-star:before, .icon-star-rated:before {
        font-size: 1.5rem;
      }
    }
  }

  &.project {
    .feed-content-main {
      display: block;
      display: -webkit-box;
      height: 5.3rem;
      margin-bottom: 1.2rem;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      @include bp-phone {
        max-height: 7.9rem;
        margin-bottom: 1.2rem;
        -webkit-line-clamp: 3;
      }
    }
  }
  &.article {
    .feed-content-main {
      display: block;
      display: -webkit-box;
      height: 6rem;
      margin-bottom: 1.2rem;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      p {
        margin-top: 1rem;
        margin-right: 7rem;
      }
      @include bp-phone {
        height: 8.7rem;
        margin-bottom: 1.2rem;
        -webkit-line-clamp: 3;
        p {
          margin-right: 0rem;
        }
      }
    }
  }

  .tool {
    border-bottom: 0px;
    padding:0;
    margin:0;
    .feed-content {
      @include bp-tablet {
        width: calc(100% - 17rem);
      }
      &-main {
        display: block;
        display: -webkit-box;
        height: 5.3rem;
        margin: 1.7rem 0 3rem 0;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        p {
          margin: 0px;
          img {
            display: none;
          }
        }
        p b, p strong, p span {
          font-weight: normal;
          margin: 0;
          font-size: 1.6rem;
        }
      }
    }
    h2, .rating {
      margin-bottom: 0;
      display: block;
      float: none;
      @include bp-tablet {
        display: inline;
        float: left;
      }
    }
    .rating {
      top: 0.5rem;
    }
    .feed-img {
      width: 9rem;
      height: 9rem;
      background-size: 100% 100% !important;
    }
  }
  h2 {
    margin: 0 1rem 0 0;
  }
  h3 {
    margin: .4rem 0 .6rem 0;
    a{
      margin-bottom: 1.6rem;
      color:#262626;
    }
  }
  .tags {
    line-height: 2.8rem;
    .btn-rounded {
      padding: .2rem 1rem;

    }
  }
  .status-btns {
    line-height: 2.8rem;
  }
  .func-btns, .status-btns {
    @include bp-phone {
      float: right;
      line-height: 1.6;
    }
  }
}

.section-home-feed {
  .feed.course {
    padding: 2.2rem 0;
    position:relative;
    border-bottom: 1px solid #d2d6d9;
    .heading {
      margin-bottom: 1rem;
      padding-top:.3rem;
      a {
        color: #262626;
        font-weight: 800;
      }
      @include bp-phone {
        padding-top: .5rem;
      }
    }
    .description {
      color: #262626;
    }
    @include bp-tablet{
      .heading {
        display: inline-block;
        vertical-align: top;
        top:0;
        margin: 0 1rem;
        margin-left: 0;
      }
    }
    @include bp-desktop {
      .heading {
        width: 75rem;
        .side-panel-open & {
          width: calc(100% - 23rem);
        }
      }
    }
    @include bp-largeScreen {
      .heading {
        .side-panel-open & {
          width: 75rem;
        }
      }
    }
  }
  .discussion {
    .discussion-cate a, .discussion-cate span {
      font-size:1.2rem;
      vertical-align: top;
    }
    .discussion-cate span {
      position: relative;
    }
    .status-btns {
      display: none;
      line-height: 1.2rem;
      vertical-align: top;
    }
    .func-btns {
      vertical-align: top;
      line-height: 1.2rem;
      height: 1.8rem;
      @include bp-phone {
        line-height: 3.2rem;
      }
    }
    @include bp-tablet{
      padding-left: 1rem;
      border-left: 2px transparent solid;
      .heading {
        padding-top: 0;
        width: calc(100% - 29rem);
      }
      .discussion-cate{
        display: inline-block;
        width: 20rem;
      }
    }
    @include bp-desktop{
      .heading {
        width: 43rem;
        margin: .5rem 0 0 0;
        .side-panel-open & {
          width: calc(100% - 29rem);
        }
      }
      .status-btns {
        display: inline-block;
        width: 13rem;
        float: none;
        margin-right: 2rem;
        .side-panel-open & {
          display: none;
        }
      }
    }
    @include bp-largeScreen {
      .heading {
        .side-panel-open & {
          width: 43rem;
        }
      }
      .status-btns {
        display: inline-block !important;

      }
    }
  }

  .tool {
    margin-bottom: 0;
    padding: 2rem 0;
    .feed-img {
      width: 5.3rem;
      height: 5.3rem;
      vertical-align: top;
      margin: 0 1.5rem 0 0;
      background-size: contain !important;
    }
    .feed-header {
      display: inline-block;
      vertical-align: bottom;
      margin: 0;
      width: calc(100% - 7rem);
      h2, .rating {
        margin-bottom: 0;
        float: none;
        display: block;
        line-height: 2.6rem;
        span{
          font-size: 1.6rem;
        }
        .icon-star-rated:before{
          font-size: 1.5rem;
        }
      }
      h2 {
        font-size: 2.6rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      @include bp-mobile {
        width: 95%;
      }
    }
    .func-btns {
      line-height: 1.5rem;
    }
    .feed-content {
      width: 100%;
      &-main {
        display: block;
        display: -webkit-box;
        height: 5.3rem;
        margin-top: .3rem;
        margin-bottom: 1rem;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        @include bp-phone {
          height: 7rem;
          margin-bottom: 1.5rem;
          -webkit-line-clamp: 3;
        }
        p {
          margin: 0px;
          img {
            display: none;
          }
        }
        p b, p strong, p span {
          font-weight: normal;
          margin: 0;
          font-size: 1.6rem;
        }
      }
    }
    @include bp-tablet {
      padding: 1.5rem;
      height: 190px;
    }
  }

  .tool-category {
    margin-bottom: 1rem;
    float: left;
    width:100%;
    .cat-header {
      display: inline-block;
      vertical-align: bottom;
      h2 {
        font-size: 2rem;
        margin: 0;
      }
    }
    .cat-content {
      width: 100%;
      &-main {
        display: -webkit-box;
        height: 5rem;
        margin-top: .3rem;
        margin-bottom: 2.5rem;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        @include bp-phone {
          height: auto;
          margin-bottom: 2.5rem;
          -webkit-line-clamp: 3;
        }
        @include bp-tablet {
          height: 5rem;
        }
        p {
          margin: 0px;
          font-size: 1.5rem;
        }
      }
    }
    @include bp-tablet {
      padding: 1.2rem;
      height: 100px;
      border: 1px #dddddd solid;
      display: inline-block;
      width: calc(50% - .4rem);
      margin: .2rem;
    }
  }
  .article {
    margin-bottom: 0;
  }

  .article-two_column {
    .feed-header {
      margin-bottom: 0rem;
      .feed-item-info {
        display: inline-block;
        &:hover, &:focus {
          text-decoration: none;
          .feed-header-info-name {
            text-decoration: underline;
          }
        }
        .feed-header-info-name {
          color: black;
          font-weight: 600;
        }
      }
    }
    .feed-content {
      width: 100%;
      a {
        font-size: 1.7rem;
        margin-top: .5rem;
        margin-bottom: 1.2rem;
        line-height: 1.3;
      }
      &-heading {
        margin-top: 0rem;
      }
      &-footer {
        padding-top: .7rem;
      }
    }
    .feed-content-heading {
      height: 4.6rem;
      overflow: hidden;
      margin-bottom: 0rem;
      padding-top: 5px;
      a {
        color: $gray-base;
        font-size: 1.7rem;
        margin-top: .5rem;
        margin-bottom: 1.2rem;
        line-height: 1.3;
        font-weight: 800;
        &:hover {
          text-decoration: underline;
        }
      }
    }
    .timestamp {
      display: block;
      color: #757575;
      font-size: 1.3rem;
      line-height: 2.2;
      padding: 0.2rem 0 0;
    }

    @include bp-tablet {
      padding: 1.5rem;
      height: 14rem;
    }
    @include bp-phone {
      .feed-header-info-name, .feed-header-info-icon {
        display: block;
      }
    }
  }
}
tool-feed-item.section-home-feed{
  width: 100%;
  @include bp-tablet{
    width: calc(50% + 1px);
    display: inline-block;
    margin: 0 -1px -1px 0;
    border: 1px #dddddd solid;
    border-bottom: none;
  }
  &:nth-child(odd) {
    float: left;
  }
  &:nth-child(even) {
    float: right;
  }
}

ut-article-feed-item.section-home-feed{
  @include bp-tablet{
    width: calc(48%);
    display: inline-block;
    margin-bottom: 4rem;
    border: 1px #dddddd solid;
    float: left;
  }
  &:nth-child(odd) {
    float: left;
  }
  &:nth-child(even) {
    float: right;
  }
}

.unauthenticated-project-bg {
  background-repeat: no-repeat;
  background-size: cover;
  background-position-y: bottom;
}

.unauthenticated-project-bg-redesign {
  background-color: var(--color-brand-cerulean-200);
  background: linear-gradient(180deg, #f2fbfc 0, #d9f2f7 100%);
}

.unauth-hero-img {
  max-width: 30%;
  max-height: 300px;
  z-index: 1;
  position: absolute;
  bottom: 0;
}

.unauth-hero-img-left {
  @extend .unauth-hero-img;
  max-width: 35%;
  max-height: 350px;
  left: 0;
}

.unauth-hero-img-left-full {
  @extend .unauth-hero-img;
  max-width: 100%;
  max-height: 100%;
  height: 100%;
  left: 0;
}

.unauth-hero-img-right-overlay {
  @extend .unauth-hero-img;
  right: 30px;
  bottom: -36px;
}

.unauth-hero-img-right {
  @extend .unauth-hero-img;
  right: 30px;
}

.unauth-hero-img-bottom {
  width: 100%;
  height: auto;
}

.btn-unauth {
  background-color: #E6E6E6;
  border-radius: 4px;
  border-color: #E6E6E6;
  color: black;
  max-width: 150px;
  &:active, &:focus {
    background: #6B4F00;
    background-color: #6B4F00;
    box-shadow: 0 0 3px 2px lighten(#fdb900, 25%);
    text-decoration: none;
    color: #FFFFFF;
  }
  &:hover {
    background: #6B4F00;
    background-color: #6B4F00;
    color: #FFFFFF;
  }
}

.unauth-round-div {
  background: #00A8CC;
  color: #FFFFFF;
  border-radius: 50%;
  width: 33px;
  height: 33px;
  text-align: center;
  vertical-align: middle;
  display: table-cell;
}

.unauth-small-cell-header {
  font-weight: bolder;
  font-size: 16px;
  padding-top: 5px;
}

.float-right {
  float: right;
}

.float-left {
  float: left;
}

.unauth-section-margin {
  margin-top: 70px;
}

.about-us-main-panel {
  text-align: center;
  padding: 0;
}

.smoke-panel {
  background-color: whitesmoke;
  margin-top: -60px;
}

.overflow-y-visible {
  overflow-y: visible;
}

.bold {
  font-weight: bold;
}

.section-home-content {
  min-height: 262px;
  background-color: var(--color-brand-cerulean-200);
  background: linear-gradient(180deg, #d9f2f7 0, #fff 11.5%);
}

.section-home-content-container {
  margin: 0 auto;
  background-color: white;
  padding: 1.5rem;
  border-radius: 8px;
}