.nav-sidebar {
  background: $black;
  width: 250px;
  height: 100%;
  position: absolute;
  color: $gray-100;
  font-size: 16px;

  div#skiptocontent {
    a {
      font-size: 16px;
      width: auto;
      display: block;
      position: absolute;
      left: -999px;
      top: -999px;
    }

    a:focus {
      left: 0;
      top: 0;
      padding: 3px;
      background: $blue;
      border:1px solid $dark-blue;
      color: white;
    }
  }

  .nav-sidebar-scrollable {
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100% - 69px);
  }

  .nav-sidebar-logo-container {
    border-bottom: solid 1px #404041;
  }

  .nav-sidebar-logo-image {
    margin: 15px auto;
    padding-top: 5px;
    height: 39px;
    width: 80px;

    a {
      display: block;

      &:focus {
        outline: 1px dashed #fff !important;
      }
    }
  }

  .nav-sidebar-user-card {
    display: flex;
    padding: 15px;
  }

  .nav-sidebar-user-card__image {
    height: 32px;
    width: 32px;
    border-radius: 50%;
  }

  .nav-sidebar-user-card-info {
    padding: 0 10px;
    width: 122px;
  }

  .nav-sidebar-user-card-info__name {
    font-size: 14.4px;
    overflow-wrap: break-word;
  }

  .nav-sidebar-user-card-info__details {
    font-size: 12.8px;
    color: $gray-200;
  }

  .nav-sidebar-user-card-rating {
    background-color: white;
    background: linear-gradient(270deg, #FFFFFF 0%);
    color: $black;
    border-radius: 20px;
    margin-top: 5px;
    display: inline-block;
  }

  .nav-sidebar-user-card-rating--unrated {
    background-color: white;
  }

  .nav-sidebar-user-card-rating--rated {
    background-color: #D2D3D4;
  }

  .nav-sidebar-user-card-rating--proven {
    background-color: #A29A8E;
  }

  .nav-sidebar-user-card-rating--bronze {
    background: linear-gradient(270deg, #A95911 0%, #DE9715 60%);
  }

  .nav-sidebar-user-card-rating--silver {
    background: linear-gradient(90deg, #FFFFFF 0%, #B2B9C2 60%);
  }

  .nav-sidebar-user-card-rating--gold {
    background: linear-gradient(270deg, #E4880B 0%, #FCBA00 60%);
  }

  .nav-sidebar-user-card-rating--lite {
    background-color: #FFF6D9;
  }

  .nav-sidebar-user-card-rating__text {
    text-align: center;
    text-transform: uppercase;
    color: #282828;
    padding: 0 10px;
    font-size: 12.8px;
    font-weight: bold;
  }

  .nav-sidebar-user-card-rating__text--unrated {
    color: #282828;
  }

  .nav-sidebar-user-card-rating__text--rated {
    color: #404041;
  }

  .nav-sidebar-user-card-rating__text--proven {
    color: #FFFFFF;
  }

  .nav-sidebar-user-card-rating__text--bronze {
    color: #FFFFFF;
  }

  .nav-sidebar-user-card-rating__text--silver {
    color: #282828;
  }

  .nav-sidebar-user-card-rating__text--gold {
    color: #282828;
  }

  .nav-sidebar-user-card-dropdown__arrow {
    color: $gray-200;
    position: relative;
    width: 50px;
    cursor: pointer;
    text-align: center;
    margin-top: 2px;

    button {
      color: $gray-200;
      background: none;
      border: none;

      &:focus {
        outline: 1px dashed #fff !important;
      }
    }
    i {
      font-size: 19.2px;
    }
  }

  .nav-sidebar-user-card-dropdown-child {
    font-size: 14px;
    padding: 4px 0 4px 28px;
  }

  .nav-sidebar-item {
    display: flex;
    height: 42px;
  }

  .nav-sidebar-item--selected {
    border-left: solid 3px #027CC2;
    background: #404041;
  }

  .nav-sidebar-item__link {
    display: flex;
    color: $gray-200;

    &:focus {
      outline: 1px dashed #fff !important;
    }
  }

  .nav-sidebar-item__link--selected {
    color: $white;
  }

  .nav-sidebar-item__text {
    font-size: 16px;
    font-weight: 500;
    padding: 10px 0;
  }

  .nav-sidebar-item__icon--selected, .nav-sidebar-item__text--selected {
    font-weight: bold;
    color: $white;
  }

  .nav-sidebar-item__icon {
    margin: 12px 20px 0 22px;
    i {
      font-size: 19.2px;
    }
  }

  .nav-sidebar-dropdown {
    display: flex;
    color: $gray-200;
  }

  .nav-sidebar-dropdown__arrow {
    width: 50px;
    cursor: pointer;
    text-align: center;
    position: relative;
    margin-top: 14px;
    margin-left: 51px;

    button {
      color: $gray-200;
      background: none;
      border: none;

      &:focus {
        outline: 1px dashed #fff !important;
      }
    }

    i {
      font-size: 19.2px;
    }
  }

  .nav-sidebar-dropdown--expanded {
    border-bottom: solid 1px #404041;
  }

  .nav-sidebar-dropdown__link--selected {
    color: #fff;
  }

  .nav-sidebar-dropdown-child {
    font-size: 14px;
    padding: 4px 0 4px 61px;
  }

  .nav-sidebar-dropdown-child--selected {
    background-color: #404041;
  }

  .nav-sidebar-dropdown-child__link {
    padding: 2px 0;
    color: #9FA1A3;
  }

  .nav-sidebar-dropdown-child__link--selected {
    color: #fff;
    font-weight: bold;
  }

  .nav-sidebar-footer {
    position: fixed;
    width: 250px;
    bottom: 0;
    padding: 24px;
    border-top: solid 1px #404041;
    font-weight: 500;
    font-size: 14.4px;
  }

  .nav-sidebar-footer__link {
    padding: 2px 0;
    color: $gray-200;
    display: block;

    &:hover, &:active, &:focus {
      text-decoration: underline;
    }
  }

  .nav-sidebar-footer__button--onetrust {
    padding: 2px 0 !important;
    color: $gray-200 !important;
    border: none !important;
    display: block;
    font-size: 14.4px !important;
    line-height: inherit !important;
    background-color: transparent !important;

    &:hover, &:active, &:focus {
      text-decoration: underline !important;
    }
  }

  .nav-sidebar-footer__link--inline {
    display: inline;
  }

  .nav-sidebar-footer__copyright {
    margin-top: 30px;
  }

  .nav-sidebar-toggle {
    position: absolute;
    background: transparent;
    border: none;
    margin: 20px 8px;

    i {
      vertical-align: middle;
      color: #FFFFFF;
    }
    &:focus {
      i {
        outline: 1px dashed #fff;
      }
    }
  }

  @media (min-width: 769px) {
    .nav-sidebar-toggle {
      display: none;
    }
  }


  @media (max-height: 889px) {
    .nav-sidebar {
      min-height: 0;
    }
    .nav-sidebar-footer {
      position: relative;
    }
  }
  .nav-sidebar__hide {
    display: none;
  }
}
