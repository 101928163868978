.loading-dots {
  text-align: center;
  width: 100%;
  height: 16px;
  line-height: 16px;
}

.loading-dots > div {
  display: inline-block;
  margin: 0 4px;
  background: #86878A;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  animation: loading-dots 0.6s alternate infinite;
}

.loading-dots > div:nth-of-type(2) {
  animation-delay: 0.2s;
}
.loading-dots > div:nth-of-type(3) {
  animation-delay: 0.4s;
}

@keyframes loading-dots {
  0% {
    -webkit-transform: scale(0,0);
  }
  100% {
    -webkit-transform: scale(1,1);
  }
}
