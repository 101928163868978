/*!
 * Copyright (C) 2023 to Present Applause App Quality, Inc. All rights reserved.
 */

@use '@angular/material' as mat;
@use 'ng-falcon';

$ngf-primary-base-palette: (
        50: ng-falcon.$color_applause-blue-5,
        100: ng-falcon.$color_applause-blue-10,
        200: ng-falcon.$color_applause-blue-20,
        300: ng-falcon.$color_applause-blue-30,
        400: ng-falcon.$color_applause-blue-40,
        500: ng-falcon.$color_applause-blue-50,
        600: ng-falcon.$color_applause-blue-60,
        700: ng-falcon.$color_applause-blue-60, // we don't have more darker colors
        800: ng-falcon.$color_applause-blue-60, // we don't have more darker colors
        900: ng-falcon.$color_applause-blue-60, // we don't have more darker colors
        contrast: (
                50: ng-falcon.$color_black,
                100: ng-falcon.$color_black,
                200: ng-falcon.$color_black,
                300: ng-falcon.$color_black,
                400: ng-falcon.$color_black,
                500: ng-falcon.$color_white,
                600: ng-falcon.$color_white,
                700: ng-falcon.$color_white,
                800: ng-falcon.$color_white,
                900: ng-falcon.$color_white,
        )
);

$ngf-warn-base-palette: (
        50: ng-falcon.$color_applause-red-20,
        100: ng-falcon.$color_applause-red-20,
        200: ng-falcon.$color_applause-red-20,
        300: ng-falcon.$color_applause-red-20,
        400: ng-falcon.$color_applause-red-50,
        500: ng-falcon.$color_applause-red-50,
        600: ng-falcon.$color_applause-red-50,
        700: ng-falcon.$color_applause-red-50,
        800: ng-falcon.$color_applause-red-50,
        900: ng-falcon.$color_applause-red-50,
        contrast: (
                50: ng-falcon.$color_black,
                100: ng-falcon.$color_black,
                200: ng-falcon.$color_black,
                300: ng-falcon.$color_black,
                400: ng-falcon.$color_white,
                500: ng-falcon.$color_white,
                600: ng-falcon.$color_white,
                700: ng-falcon.$color_white,
                800: ng-falcon.$color_white,
                900: ng-falcon.$color_white,
        )
);

$ngf-primary-palette: mat.define-palette($ngf-primary-base-palette, 600, 100, 600);
$ngf-warn-palette: mat.define-palette($ngf-warn-base-palette, 600, 100, 600);

$ngf-theme: mat.define-light-theme((
        color: (
                primary: $ngf-primary-palette,
                accent: $ngf-primary-palette, // same as primary bc, we are not using accent color
                warn: $ngf-warn-palette,
        )
));
