.gamification {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 2rem 1rem 2rem 0;
  width: 100%;
  &:first-child {
    border-top: 1px #ccc solid;
    margin-top: 2rem;
  }
  &-content{
    min-width: 17rem;
    flex: 7;
    h5 {
      font-size: 1.4rem;
      font-weight: 600;
    }
    p {
      font-size: 1.4rem;
      margin-bottom: 0;
      line-height: 1.4;
      padding-right: 3rem;
    }
    @include bp-tablet {
      padding-right: 10rem;
    }
  }
  &-icon {
    padding-right: 1.5rem;
    padding-top: 0.3rem;
    flex-grow: 1;
    @include bp-phone {
      width: 100%;
    }
    .profile-thumb {
      width: 6.5rem;
      height: 6.5rem;
    }
    @include bp-tablet {
      flex-grow: 0;
    }
  }
  &-points, &-type {
    max-width: 5rem;
    font-size: 1.2rem;
    font-weight: 600;
    color: #3c4042;
    @include bp-tablet {
      min-width: 8rem;
    }
  }
  &-points {
    text-align: right;
    flex-grow: 1;
    @include bp-tablet {
      flex-grow: 0;
    }
  }
  &-type {
    flex-grow: 1;
    @include bp-tablet {
      flex-grow: 0;
    }
  }
  @include bp-thin {
    display:block;
    text-align: center;
    padding-right: 0;
    p {
      padding-right: 0;
      max-width: 80%;
      margin:0 auto;
    }
    &-icon {
      padding-right: 0;
    }
    &-points {
      max-width: none;
      text-align: center;
      margin-top: 1rem;
    }
  }
}
