/*!
 * Copyright (C) 2020 to Present Applause App Quality, Inc. All rights reserved.
 */

.dashboard {
  padding-top: 20px;
  margin: 0px;

  .dashboard-card {
    box-sizing: content-box;
    background-color: #FFFFFF;
    border-radius: 5px;
    margin-bottom: 30px;
    box-shadow: 0 5px 5px rgba(64,64,65,0.03);
    padding: 25px;
    font-size: 14px;

    a {
      color: $blue;

      &:hover, &:active, &:focus {
        text-decoration: underline;
      }
    }

    .dashboard-card-tabset {
      margin-bottom: 5px;

      .dashboard-card-tabset-tab {
        padding: 0px;

        &.dashboard-card-tabset-tab--all {
          width: 15%;
          min-width: 17px;
        }

        &.dashboard-card-tabset-tab--projects {
          width: 20%;
          min-width: 75px;
        }

        &.dashboard-card-tabset-tab--invitations {
          width: 20%;
          min-width: 86px;
        }

        &.dashboard-card-tabset-tab--community {
          width: 20%;
          min-width: 79px;
        }

        & > a {
          color: #262626;
          text-align: center;
          text-transform: none;
          font-size: 13px;
          font-weight: normal;

          &:hover, &:active, &:focus {
            color: #262626;
            text-decoration: none;
          }
        }

        &.active > a {
          color: $blue;
          font-weight: bold;
          border-bottom: 4px $blue solid;
        }
      }

      .dashboard-card-tab-indicator {
        display: inline-block;
        background: $gray-lighter;
        border: 1px solid $gray;
        border-radius: 50%;
        width: 1.7em;
        line-height: 1.7em;
        box-sizing: content-box;
        font-size: 75%;
        font-weight: normal;
        color: #262626;
      }
    }

    .dashboard-activity-project-list-footer {
      display: flex;

      &:hover {
        background-color: #EEEEEE;
      }
    }

    .dashboard-activity-project-list-footer-more  {
      font-weight: 600;

      .material-icons {
        color: #888;
        width: 60px;
        font-size: 50px;
        display: inline-flex;
      }
    }

    .dashboard-activity-project-list-footer-more__stat {
      height: 50px;
      line-height: 50px;
      vertical-align: middle;
      display: inline-flex;
      color: #59595C;
    }

    .dashboard-activity-project-list-footer-view-all {
      position: absolute;
      left: 40%;
      display: inline-flex;
      height: 50px;
      line-height: 50px;
      text-transform: capitalize;
    }

    .dashboard-card-list--my-devices {
      padding: 15px 0px;
      max-height: 160px;
      overflow-y: auto;
    }

    .dashboard-card-list-item--activity {
      padding-top: 16px;
      padding-bottom: 14px;
      border-bottom: 1px solid #F3F4F5;
      line-height: 11px;
      color: #59595C;
      display: flex;
      flex-direction: row;
    }

    .dashboard-card-list-item--my-devices {
      margin-bottom: 5px;
      display: inline-flex;
      width: 100%;
    }

    .dashboard-card-list-item--unread {
      color: #404041;
    }

    .dashboard-card-list-item__time {
      display: inline-block;
      float: right;
      font-size: 11px;
    }

    .dashboard-card-list-item-description {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .dashboard-card-list-item-description--unread {
      font-weight: bold;
    }

    .dashboard-card-list-item-description--activity {
      display: inline-block;
      height: 11px;
      width: 80%;
    }

    .dashboard-card-list-item-description--primary {
      color: #404041;
      font-weight: bold;
    }

    .dashboard-card-list-item-description--secondary {
      color: #59595C;
    }

    .dashboard-card-footer {
      display: block;
      padding: 14px 0px;
      text-align: center;
    }

    .dashboard-activity-card__description {
      padding-top: 16px;
    }

    .dashboard-card-profile-completion-graph {
      position: relative;
      margin: auto;
      margin-top: 40px;
      margin-bottom: 40px;
      width: 120px;
      height: 120px;
    }

    .dashboard-card-profile-completion-graph__progress {
      display: block;
      width: 100%;
      height: 100%;
    }

    .dashboard-card-profile-completion-graph__progress-meter,
    .dashboard-card-profile-completion-graph__progress-value {
      fill: none;
    }

    .dashboard-card-profile-completion-graph__progress-meter {
      stroke: #E6E7E8;
    }

    .dashboard-card-profile-completion-graph__user_avatar {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      margin: auto;
      width: 84px;
      height: 84px;
      border-radius: 50%;
      background-size: cover;
      background-position-x: 50%;
      background-position-y: 50%;
      background-repeat: no-repeat;
    }

    .dashboard-card-profile-completion-graph__percentage-badge {
      position: absolute;
      bottom: 0;
      right: 0;
      border-radius: 50%;
      width: 50px;
      height: 50px;
      background-color: #FFFFFF;
      line-height: 50px;
      text-align: center;
      font-weight: bold;
      box-shadow: -1px 2px 4px rgba(0, 0, 0, .2);
    }

    .dashboard-card-profile-completion-button {
      display: flex;
    }

    .dashboard-card-description {
      color: #59595C;
      text-align: center;
    }

    .dashboard-card-list-item-icon {
      margin-right: 5px;
      background-color: #EEE;
      border-radius: 50%;
      width: 38px;
      height: 38px;
      padding: 7px;
      color: #b0b1b4;
      display: flex;
      align-items: center;
    }

    .dashboard-card-list-item-icon-image {
      margin: auto;
    }

    .dashboard-activity-no-list-items {
      text-align: center;
      padding: 50px 0px;
    }

    .dashboard-activity-no-list-items__primary {
    	font-size: 24px;
    	font-weight: 600;
    }

    .dashboard-activity-no-list-items__secondary {
      padding-top: 10px;
      padding-bottom: 20px;
    }

    .dashboard-link-button {
      @extend %ntux-button;

      &:hover, &:active, &:focus {
        text-decoration: none;
      }
    }

    .dashboard-card-footer--profile-completion {
      margin-top: 10px;
      color: $blue;
      margin-left: auto;
      margin-right: auto;
      border: 2px solid $blue;
      border-radius: 25px;
      text-align: center;
      font-weight: bold;
      padding: 6px;
      transition:0.2s linear all;

      &:hover
      {
        color: #FFFFFF;
        background-color: $blue;
      }
    }

    .dashboard-card-list-fade {
      content:'';
      height: 15px;
      position: relative;
      pointer-events: none;
    }

    .dashboard-card-list-fade--bottom {
      margin-top: -15px;
      background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
    }

    .dashboard-card-list-fade--top {
      margin-bottom: -15px;
      background: linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
    }

    .dashboard-activity-project-timing {
      text-align: right;
      width: 40%;
      min-width: 155px;
    }

    .dashboard-activity-project-timing__dates {
      margin-bottom: 5px;
    }

    .dashboard-activity-project-timing__date {
      padding-bottom: 5px;
    }

    .dashboard-activity-project-timing__countdown {
      display: inline-flex;
      font-weight: 600;
      flex-direction: row;
    }

    .dashboard-activity-project-status {
      text-align: center;
      padding: 0 4px;
      margin-right: 5%;
      width: 15%;
    }

    .dashboard-activity-project-status__icon {
      border-radius: 50%;
      background-color: #eee;
      height: 36px;
      width: 36px;
      padding: 4px;
      margin: 0 auto 7px;
      color: #888;
    }

    .dashboard-activity-project-status__text {
      line-height: 17px;
    }

    .dashboard-activity-project-status__icon--active {
      border: 2px solid #7ECC82;
    }

    .dashboard-activity-project-status__icon--pending {
      border: 2px solid #FCBA00;
    }

    .dashboard-activity-project-status__icon--invitation {
      border: 2px solid #eee;
    }

    .dashboard-activity-project-emphasized-text {
      font-weight: bold;
      font-size: 14px;
    }

    .dashboard-activity-project-link {
      color: $blue;
    }

    .dashboard-activity-project-icon {
      font-size: 15px;
      color: #888;
      margin-top: -2px;
      margin-right: 5px;
    }

    .dashboard-activity-project-details {
      width: 65%;
      display: inline-flex;
      flex-direction: column;
    }

    .dashboard-card-activity-my-projects-recruited {
      display: inline-flex;
      margin-bottom: 5px;
      font-size: 12px;
      font-weight: 600;
    }

    .dashboard-card-activity-my-projects-title-block {
      margin-bottom: 5px;
      line-height: 14px;
      min-height: 14px;
      max-height: 28px;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    .dashboard-card-activity-my-projects-title {
      font-size: 14px;
      font-weight: 600;
    }

    .dashboard-card-activity-my-projects-stats {
      flex-direction: row;
      align-items: center;
    }

    .dashboard-card-activity-my-projects-stat {
      display: inline-flex;
      margin-right: 10px;
      font-weight: 600;
    }
  }

  .dashboard-card__heading {
    color: #404041;
    font-size: 14px;
    letter-spacing: 1px;
    text-transform: uppercase;
    margin-bottom: 20px;
    font-weight: inherit;
    line-height: inherit;
    margin-top: inherit;
  }

  .dashboard-card-requested-devices__items {
    margin: 0;
    padding: 15px 0px;
    max-height: 160px;
    overflow-y: auto;
    list-style: none;
  }

  .dashboard-card-requested-devices__item {
    margin-bottom: 5px;
    display: flex;
    align-items: center;
  }

  .dashboard-card-requested-devices__feedback {
    text-align: center;
    margin-top: 10px;
  }

  .dashboard-card-requested-devices__feedback-button {
    vertical-align: text-bottom;
    margin-left: 10px;
    background: none;
    border: none;
  }

  .dashboard-new-joiner-card {
    border: 2px solid $blue;
    padding: 0px !important;
  }

  .dashboard-new-joiner-checklist__header {
    background-color: $blue;
    padding: 25px;
    font-size: 14px;
    color: #fff;
  }

  .dashboard-new-joiner-checklist__heading {
    display: flex;
    align-items: center;
  }

  .dashboard-new-joiner-checklist__heading-icon {
    margin-right: 10px;
  }

  .dashboard-new-joiner-checklist__heading-text {
    text-transform: uppercase;
    padding-bottom: 6px;
    letter-spacing: 1px;
    color: inherit;
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
    margin-top: inherit;
    margin-bottom: inherit;
  }

  .dashboard-new-joiner-checklist__subheading {
    text-transform: none;
    margin-top: 20px;
    line-height: normal;
  }

  .dashboard-new-joiner-checklist {
    padding: 25px;
  }

  .dashboard-new-joiner-checklist__item {
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
  }

  .dashboard-new-joiner-checklist__item--completed {
    color: $blue;
    font-weight: bold;
  }

  .dashboard-new-joiner-checklist__text {
    padding-bottom: 6px;
  }

  .dashboard-new-joiner-checklist__icon {
    margin-right: 10px;
  }

  .dashboard-new-joiner-checklist__icon--unchecked {
    color: $gray-500;
  }

  .dashboard-new-joiner-checklist__link {
    font-weight: bold;
    text-decoration: underline;
    color: $blue;

    &:focus {
      outline: 1px dashed currentColor !important;
    }
  }

  .dashboard-new-joiner-checklist__link--completed {
    text-decoration: none;
  }

  .dashboard-new-joiner-checklist__description {
    margin-left: 10px;
    display: inline;
  }
}

.mobile-device:before {
  content: '\e325';
}

.computer:before {
  content: '\e30b';
}

.entertainment:before {
  content: '\e63a';
}

.smartwatch:before {
  content: '\e334';
}

.set-top-box-and-streaming-device:before {
  content: '\e8c7';
}

.smart-tv:before {
  content: '\e639';
}

.smartwatch:before {
  content: '\e334';
}

.wearable:before {
  display: block;
  content: '\e0d9';
  transform: rotate(180deg);
}
