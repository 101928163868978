.contest-cover {
  padding: 0 0 4rem 0;
  margin-bottom: 2rem;
  .grid {
    padding: 0;
  }
  h1 {
    margin: 1.5rem 0;
  }
  &-band {
    width: 100%;
    background: rgba(255,255,255, .8);
    padding: 2rem 1rem;
    border-bottom-left-radius: .5rem;
    border-bottom-right-radius: .5rem;
    margin-bottom: 6rem;
    @include bp-tablet {
      padding: 2rem 3rem;
    }
    @include bp-phone {
      text-align: center;
    }
    span {
      font-size: 1.2rem;
      padding-right: 1rem;
    }
    img {
      display: inline;
      vertical-align: middle;
    }
    #coverBtn {
      margin-top: .3rem;
      @include bp-phone {
        margin: 2rem 0 1rem 0;
      }
    }
  }
  &-img {
    max-width: 20rem;
    margin: 0 auto;
    @include bp-tablet {
      padding: 1rem 4rem 1rem 0;
    }
  }
  &-detail {
    padding: 2rem;
    @include bp-tablet {
      padding: 0;
    }
    @include bp-phone {
      text-align: center;
    }
  }
  h4, h4 * {
    color: #fff;
    font-size: 2rem;
    font-weight: 600;
    margin-top: 0;
    margin-bottom: -1rem;
  }
}
.contest-info {
  padding: 3rem 0;
  border-bottom: 1px #ccc solid;
  &-band {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    border-top: 1px #ccc solid;
    border-bottom: 1px #ccc solid;
    margin: 1.5rem 0;
    &-item {
      flex-grow: 1;
      padding: 1.5rem 0;
      min-width: 15rem;
      text-align: center;
      &-heading {
        font-size: 1.2rem;
        color: #7b8a97;
        line-height: 1.75rem;
      }
      &-date {
        font-size: 1.6rem;
      }
    }
  }
  &-detail {
    padding: 0 1rem;
    @include bp-tablet {
      padding: 0 4.5rem;
    }
    h2 {
      margin: 4rem 0 1rem 0;
    }
    #coverBtn {
      float: none;
    }
  }
}