.ntux-select {
  display: inline-block;
  height: 24px;
  cursor: pointer;
  outline: none;
  color: #262626;
  line-height: 24px;

  i {
    vertical-align: bottom;
  }
  .ntux-select-value {
    background: none;
    border: none;
    color: $black;
    padding: 0;
    &:focus {
      outline: 2px dashed #027CC2 !important;
      transition: none;
    }
  }
  .ntux-select-options {
    margin-top: -24px;
    position: absolute;
    display: none;
    z-index: 2;
    padding: 12px;
    border: 1px solid #C8C8C8;
    border-radius: 4px;
    background-color: #FFFFFF;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.25);
  }

  .ntux-select-option {
    line-height: 24px;
    padding-bottom: 6px;
    white-space: nowrap;
    text-align: left;
    list-style: none;

    &:hover, &:focus {
      color: #027CC2;
      font-weight: 600;
      outline: none;
    }

    &:last-of-type {
      padding-bottom: 0px;
    }
  }
}
