.community-follow-btn {
    .checkbox-toggle {
      @extend %ntux-button;
      cursor: pointer;
      padding-top: 9px;
      height: 40px;
      width: 157px;
      border: 2px solid $blue;
      border-radius: 20px;
      font-size: 16px;
      line-height: 19px;
      text-align: center;
      &:focus {
        outline: none;
      }
    }
    input[type='checkbox'] {
      display: none;
      &:checked + .checkbox-toggle:before {
        content: 'Following';
        color: $blue;
      }
      &:checked ~ .checkbox-toggle:hover:before{
        content: 'Unfollow';
      }
      &:checked + .checkbox-toggle {
        background-color: $white;
      }
      &:not(:checked) + .checkbox-toggle:before {
        content: 'Follow';
        color: $white;
      }
      &:not(:checked) + .checkbox-toggle {
        background-color: $blue;
      }
    }
  }