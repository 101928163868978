.leaderboard {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  .leader-info{
    flex-grow: 1;
    display: flex;
    align-items: center;
    width: 17rem;
    > a > .profile-thumb {
      margin-right: 1rem;
    }
    @include bp-tablet {
      order: 1;
    }
  }
  &-badge {
    flex: 1 100%;
    padding-top: 1.5rem;
    img {
      display: inline;
      width: 3.5rem;
      height: 3.5rem;
      margin: .3rem;
    }
    @include bp-tablet {
      order: 2;
      flex: 3 0;
      padding-top: 0rem ;
      img {
        width: 3rem;
        height: 3rem;
      }
    }
  }
  &-points {
    min-width: 6.4rem;
    font-weight: 600;
    color: #3c4042;
    text-align: right;
    @include bp-tablet {
      order: 3;
      width: 10rem;
    }
  }
  .cus-checkbox-follow {
    width: 8rem;
    margin-left: 4rem;
    @include bp-tablet {
      order: 4;
    }
  }
  @include bp-thin {
    display:block;
    padding-bottom: 7rem;
    position: relative;
    .leader-info {
      display:block;
      text-align: center;
      width:100%;
      .profile-thumb {
        margin-right: 0;
      }
    }
    .leaderboard-points {
      text-align: center;
    }
    .leaderboard-badge {
      text-align: center;
      padding-top: .5rem;
    }
    .cus-checkbox-follow {
      position: absolute;
      bottom:3rem;
      left:50%;
      margin-left: -4rem;
    }
  }
  .user-tooltip {
    .cus-checkbox-follow {
      margin-left: 1rem;
    }
  }
}
