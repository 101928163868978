.nav-tabs {
  margin: 1rem 0 0;
  overflow-x: auto;
  width:100%;
  overflow-y: hidden;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  &::-webkit-scrollbar {
      display: none;
  }
  li{
    display: inline-block;
    float: none;
    padding: 1rem 1rem 0;
    &:first-child {
      padding-left:0;
    }
    a {
      color: $gray-700;
      font-weight: 600;
      font-size: 1.2rem;
      letter-spacing: .05rem;
      text-transform: uppercase;
      padding: 0 0 .6rem;
      border: 0px;
      border-bottom: 4px transparent solid;
      border-radius:0;
      &:hover, &:focus {
        background:none;
        border-color: #8794A1;
        color: rgba(38,38,38,.7);
      }
    }
  }
  &>li.active>a, &>li.active>a:hover, &>li.active>a:focus{
    border:0px;
    border-bottom: 4px $blue solid;
  }
  .tool & {
    border-bottom: none;
  }

}
