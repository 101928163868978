.about-us-item {
  padding: 3rem 0 2rem;
  @include bp-tablet {
    min-height: 40rem;
    &:nth-child(2n) {
      padding-left: 2rem;
    }
    &:nth-child(2n + 1){
      padding-right: 2rem;
      clear: left;
    }
  }
  .feed-header-info {
    float: none;
    &-icon {
      margin-right: 2rem;
    }
    &-name {
      vertical-align: top;
      h4 {
        margin: 4rem  0 0;
      }
      .feed-type {
        font-size: 1.5rem;
        color: #262626;
        text-transform: none;
      }
    }
  }
}
