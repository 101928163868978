//new styling
.scrollable {
  height: 100%;
  @include bp-phone {
    height: auto;
  }
}

.scrollable-bar {
  cursor: pointer;
  @include bp-phone {
    display: none !important;
  }
}

.scrollable-slider {
  @include transition(height .2s ease-in-out !important);
  background-image: none !important;
  border: none !important;
  border-radius: 8px !important;
}

.scrollable-bar {
  @include transition(opacity .2s ease-in-out !important);
  border-radius: 0 !important;
}

.navMenuWrapper.scrollable {
  height: 100%;
  .scrollable-bar {
    background: #7b8a97 !important;
    border: 2 px solid #7b8a97 !important;
  }
  @include bp-mobile {
    overflow: scroll;
  }
}

.sidePanelWrapper.scrollable {
  @include bp-mobile {
    height: 100%;
    overflow: scroll;
  }
}
