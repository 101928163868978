.easy-write {
  width:100%;
  background: #f6f8f9;
  border: 1px #d2d6d9 solid;
  border-radius: 4px;
  padding: 2rem;
  margin: 6rem 0 3rem;
  .profile-thumb, .write-status, .write-post {
    display: inline-block;
    vertical-align: top;
  }
  .profile-thumb {
    display: none;
    margin: 0 1rem 0 0;
    @include bp-tablet {
      vertical-align: middle;
      width:4rem;
      height:4rem;
      display: inline-block;
    }
  }
  .write-status {
    width:100%;
    form {
      position: relative;
    }
    input {
      width: calc(100% - 6rem);
      height: 4.2rem;
      border-top-left-radius:4px;
      border-bottom-left-radius: 4px;
      border: 1px #d2d6d9 solid;
      outline:none;
      padding-left:1.5rem;
    }
    @include bp-tablet{
      width: calc(100% - 6rem - 21rem);
      margin-right: 1rem;
    }
  }
  .write-article {
    display: none;
    margin-left:0;
    .btn {
      background:#fdb900;
      border-color: #fdb900;
      color:#262626;
      margin-left: 1.5rem;
      padding-left: 4.2rem;
      padding-right: 2.1rem;
    }
    @include bp-tablet {
      display: inline-block;
    }
  }
}

.write-status-success {
  position: relative;
  .write-status {
    .btn.blue-tab::before {
      background: rgb(34, 192, 100);
    }
    .btn.blue-tab,
    input, input:focus {
      border-color: rgb(34, 192, 100);
    }
    .btn.blue-tab {
      .btn-text {
        margin-top: -1rem;
        opacity:0;
        @include transition(all 500ms);
      }
      &:hover::after {
        width:0;
        @include transition(width 500ms 300ms);
      }
    }
  }
  form {
    position: relative;
    &::after {
      content: '\e806';
      font-family: 'utestfont';
      color:white;
      position: absolute;
      right:0.2rem;
      bottom:0;
      z-index: 9;
      font-size: 1.6rem;
      width: 6rem;
      text-align: center;
      line-height: 4.2rem;
    }
  }
}

.write-status {
  .dropzone + p {
    text-align:center;
    font-weight:600;
    margin-bottom:2rem;
    font-size:1.3rem;
  }
  form {
    position: relative;
  }
  input[type="text"] {
    @include placeholder($gray-base);
  }
}

.write-status-fs-inner {
  height:calc(100% - 7.2rem);
  overflow:auto;
  padding-bottom:5rem;
  > .close {
    float:none;
    display: block;
    font-size: 4rem;
    line-height: 1em;
    padding-left:1rem;
  }
  .uedit {
    .CodeMirror {
      border:0;
    }
    .CodeMirror-placeholder {
      color:$gray-base;
    }
  }
}

.write-status-fs {
  .write-status-attach {
    top:1.5rem;
    left:1rem;
    right: auto;
    a {
      font-size:2rem;
    }
    .label {
      display:none;
    }
  }
}

.write-status-fs-attachments {
  padding:1rem;
  p {
    margin:0;
  }
}

.write-status-fs-footer {
  position: absolute;
  bottom:0;
  left:0;
  width:100%;
  border-top:1px solid $gray-light;
  background:$gray-lightest;
  padding:1rem;
}

.write-status-fs {
  padding:1rem;
  border: 1px solid $gray-light;
  background:#fff;
  border-radius:.3rem;
  text-align: left;
  margin-left:5rem;
  font-size: 1.5rem;
  display:block;
  width:auto;
  max-width: none;
  color:$gray-base;
  font-family: $font-family-sans-serif;
  .write-status_show_on_open {
    display:none;
  }
  &.open {
    margin-left:0;
    position: fixed;
    top:0;
    left:0;
    height:100%;
    width:100%;
    padding:0;
    border-radius:0;
    z-index:99;
    .write-status-fs-placeholder{
      display:none;
    }
    .write-status_show_on_open  {
      display:block;
    }
  }
}

.write-status-fs-placeholder {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.write-status-attach {
  position: absolute;
  top:0rem;
  right:7rem;
  font-size: 0;
  a {
    font-size: 1.4rem;
    display:inline-block;
    line-height:4.2rem;
    padding:0 .4rem;
    color:#bac1c6;
    &.active,
    &:hover {
      color:#929292;
    }
  }
  button {
    font-size: 1.4rem;
    display:inline-block;
    line-height:4.2rem;
    padding:0 .4rem;
    color:#525960;
    background: none;
    border: none;
    &.active, &:hover, &:focus {
      color: $blue;
    }
  }
  .label {
    font-size: 1.2rem;
    max-width: 16rem;
    display:inline-block;
    text-overflow:ellipsis;
    overflow:hidden;
    white-space: nowrap;
    top:-.4rem;
    margin-right: .6rem;
    vertical-align: middle;
    position: relative;
    padding:.3rem 2.5rem .3rem .3rem;
    background:none;
    border:1px solid #d2d6d9;
    color:$gray-base;
    font-weight: normal;
    opacity:0.8;
    position: relative;
    .icon-cancel {
      position: absolute;
      top:.3rem;
      right:.3rem;
      cursor: pointer;
    }
    &.active, &:hover, &:focus {
      color: $blue;
    }
  }
  .popover {
    top:4rem;
    position:absolute;
    transform: translateX(-50%);
    transition:left 300ms;
    &.top {
      top:auto;
      bottom:4.5rem;
    }
    input[type="text"]  {
      width:100%;
      border-radius:.3rem;
      margin-bottom:2rem;
    }
    .icon-cancel {
      color:#262626;
      margin:0 !important;
      float:right;
      padding:0;
      font-size:1.5rem;
      line-height:1.4;
      transition: none;
      &:hover, &:focus {
        background: $blue;
        color: white;
        max-height: 25px;
      }
    }
    @media (max-width: 830px) {
      transform: translateX(-80%);
      > .arrow {
        left:80%;
      }
      .write-status-fs & {
        transform: translateX(-8%);
        > .arrow {
          left:8%;
        }
      }
    }
  }
}

.write-status > input {
  padding-left:7rem;
}

[suggestion-typeahead] + .dropdown-menu {
  li {
    padding:0 1rem;
    cursor:pointer;
    .feed-item-info {
      padding-top:1rem;
      padding-bottom:1rem;
      min-height:5.8rem;
    }
    &.active {
      background:#edf0f2;
    }
  }
}
