/*!
 *  Copyright (C) 2021 to Present Applause App Quality, Inc. All rights reserved.
 */

div[ngfFormContainer] {
  display: flex;
  flex-direction: column;

  > ngf-form-field {
    margin-bottom: 24px;
  }

  > ngf-field {
    margin-bottom: 24px;
  }

  > * {
    margin-bottom: 24px;
  }
}

ngf-form-field[md] {
  width: 380px;

  ngf-input-container {
    width: 100%;
  }

  ngf-select {
    width: 100%;
  }

  textarea[ngfTextarea] {
    width: 100%;
    font-family: proxima-nova, sans-serif;
  }

  ngf-autocomplete {
    width: 100%;
  }
}

ngf-form-field[lg] {
  width: 480px;

  ngf-input-container {
    width: 100%;
  }

  ngf-select {
    width: 100%;
  }

  textarea[ngfTextarea] {
    width: 100%;
    font-family: proxima-nova, sans-serif;
  }

  ngf-autocomplete {
    width: 100%;
  }
}

ngf-form-field[nested] {
  margin-left: 48px
}

mat-divider[ngfFormDivider] {
  margin-bottom: 24px;
}
