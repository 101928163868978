/* You can add global styles to this file, and also import other style files */

@import 'animate.css';
@import '~simplemde/dist/simplemde.min.css';
@import '~odometer/themes/odometer-theme-minimal.css';
@import '~emojione/assets/css/emojione.css';
@import '~nprogress/nprogress.css';
@import '~dropzone/dist/dropzone.css';
@import '~ui-select/dist/select.css';
@import '~ladda/dist/ladda-themeless.min.css';
@import 'src/app/styles/utestv2.scss';
