.simple-registration {
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  overflow: auto;

  flex: none;
  order: 0;
  flex-grow: 0;

  a.privacy-policy-link:focus {
    border-radius: 5px;
  }

  .signup-consent {
    padding-left: 3rem !important;
    margin-top: 0;
  }

  .container-fluid{
    margin-right: auto;
    margin-left: auto;
    padding: 20px 80px;
    max-width: 840px;
  }

  .logo-container {
    float:left;
  }

  .simple-registration-page-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    padding: 20px 0 20px;
    gap: 4px;

    .sign-in {
      font-size: 16px;

      a:focus {
        border-radius: 5px;
      }

      span {
        color: $gray-700;
      }

      @include bp-phone{
        padding: 0;
      }
    }
  }

  .set-password-page-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 20px 0 20px;
    gap: 4px;
    margin-top: 5%;
    min-height: 70px;
  }

  @media only screen and (max-width: $screen-phone) {
    .simple-registration-page-header {
      .sign-in{
        font-size: 14px;
      }
    }

    .container-fluid{
      padding: 0 10px 10px;
    }

  }

  @media only screen and (max-width: $screen-tablet) {
    .container-fluid{
      padding: 0 10px 10px;
    }

    .simple-registration-page-header {
      padding: 20px 0 10px;
    }
  }
}

.set-password {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 4px;

  overflow-y: auto;
  overflow-x: hidden;

  flex: none;
  order: 0;
  left: 0;
  top: 0;

  .simple-registration-page-header {
    align-self: flex-start;
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: flex-start;
    padding: 20px 15px 20px;
    gap: 4px;
  }
}

.simple-registration-alert-banner {
  border-radius: 4px;
  border: 1px solid #D42D0E;
  background: #F8DBD6;
  min-height: 44px;
  max-width: 480px;
  padding: 12px 24px;
  margin-bottom: 20px;
  vertical-align: middle;

  .simple-registration-alert-text {
    color: #811B08;
    font-family: "proxima-nova", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;

  }
}

.same-as-above {
  width: 75%;
  padding-left: 3rem;
  margin-top: 2.5rem;
}

.utest-simple-registration-logo {
  width: 100px;
  max-height: 30px;
}

.utest-registration-finished-logo {
  width: 400px;
  height: 315px;

  @media only screen and (max-width: $screen-phone) {
    width: 350px;
    height: 315px;
  }

}

.simple-registration-image-container {
  display: flex;
  justify-content: center;
}

.form-header-text {
  font-family: "proxima-nova", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
}

.set-password-expired-header {
  margin-bottom: 20px;
}

.simple-registration-form-group-box{
  float: left;
  padding: 1rem 0rem;
  margin-bottom: 2rem;
}

.set-password-form-group-box {
  float: left;
  padding: 1rem 0rem;
  margin-bottom: 2rem;
  min-width: 400px;
  @media only screen and (max-width: $screen-phone) {
    min-width: 340px;
  }
}

.set-password-requirements-list {
  margin-bottom: 20px;

  .set-password-requirement-list-item {
    margin-left: 24px;
  }
}

.simple-registration-label {
  margin-bottom: 0;
}

.simple-registration-recaptcha {
  margin-top: 10px;
  margin-bottom: 10px;
}
.simple-registration-recaptcha-error {
  margin-bottom: 10px;
}

#com-reg-form-area {
  min-height: 100%;
  height: 100%;
}

.simple-registration-btn-container {
  padding-top: 20px;
  max-width: 160px;

  &:not(.set-password-container) {
    @media only screen and (max-width: $screen-phone) {
      padding-top: 0;
    }
  }
}

.simple-registration-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 24px;
  gap: 12px;

  width: 160px;
  height: 56px;

  background: $blue;
  border-radius: 36px;

  .simple-registration-btn-text {
    width: 57px;
    height: 20px;

    color: white;
    font-family: "proxima-nova", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
  }

  .set-password-btn-text {
    width: 109px;
    height: 20px;
    color: white;
    font-family: "proxima-nova", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
  }

  &:focus, &:active, &:hover {
    background: $dark-blue;
  }
}

.request-set-password-btn-text {
  width: 260px;
  height: 20px;
  color: white;
  font-family: "proxima-nova", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
}

.request-set-password-btn {
  width: 263px;
}

.request-set-password-btn[disabled] {
  pointer-events: none;
  cursor: not-allowed;
  background-color: $action-disabled-color;
  border-color:  $action-disabled-color;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.request-set-password-page {
  max-width: 500px;
}

.simple-registration, .set-password {
  .form-group {
    margin-bottom: 20px !important;
  }

  .sign-up-form-container {

    .user-form:not(.invalid) input {
      &.ng-dirty, &.ng-invalid {
        border-color: #ccc !important;
      }
    }

    input[type="text"], input[type="email"], input[type="password"] {
      background: unset;

      &[disabled] {
        background-color: $gray-300;
        color: $gray-700;
      }
    }

    .ui-select-toggle {
      height: 43px;
      background-color: $white;
      border-radius: .5rem;
      border-color: #ccc;
      color: #262626;
      box-shadow: none;
      width: -moz-available;
      width: -webkit-fill-available;
      &.ng-pristine {
        color: #75767A;
      }
      &:focus {
        outline: auto 2px;
      }
    }
  }
}
