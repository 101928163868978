
/*--------------*\
	utilities
\*--------------*/
@import "utility/mixin";
@import "base/variables";
@import "bootstrap-sass-3.3.6/assets/stylesheets/bootstrap";
@import "~material-design-icons-iconfont/dist/material-design-icons.css";

/*--------------*\
	base style
\*--------------*/
@import "base/base";
@import "base/margins";
@import "base/color";
@import "base/icon-font";
@import "base/grid";
@import "base/animate";
@import "base/form";
@import url('//fonts.googleapis.com/css?family=Open+Sans:400italic,700,600,800,400,300');
@import "base/ut";

/*--------------*\
	layout style
\*--------------*/
@import "layout/wrapper";
@import "layout/scroll";

/*--------------*\
	module style
\*--------------*/

@import "module/ntux-shared";
@import "module/global-feed";
@import "module/home";
@import "module/about-us-item";
@import "module/activity";
@import "module/activity-feed";
@import "module/alert";
@import "module/avatar-icon-thumb";
@import "module/btn";
@import "module/buttons";
@import "module/carousel";
@import "module/circular-num-label";
@import "module/community-registration";
@import "module/simple-registration";
@import "module/contest";
@import "module/date-picker";
@import "module/delete-btn";
@import "module/discussion-color-block";
@import "module/dropdown-filter";
@import "module/gamification";
@import "module/leaderboard";
@import "module/message";
@import "module/nav-header";
@import "module/nav-sidebar";
@import "module/nav-tab";
@import "module/community";
@import "module/community-content-control-icons";
@import "module/academy";
@import "module/community-nav";
@import "module/ntux-select";
@import "module/dashboard";
@import "module/overlay";
@import "module/profile";
@import "module/progress-bar";
@import "module/rating";
@import "module/referral-campaign";
@import "module/section-home";
@import "module/section-single";
@import "module/section-submit";
@import "module/search-result";
@import "module/sign-up-form";
@import "module/slide-up";
@import "module/status-input";
@import "module/to-do-list";
@import "module/social-sharing";
@import "module/dante-editor";
@import "module/upload-btn";
@import "module/fourOhFour";
@import "module/pagination";
@import "module/landing-pages";
@import "module/tooltips";
@import "module/u-editor";
@import "module/modals";
@import "module/tag";
@import "module/pdf-viewer";
@import "module/loading-dots";
@import "module/follow-btn";
@import "module/ntux-common";
@import "module/unauthenticated";
@import "module/history-timeline";
@import "module/url-shortener";
@import "module/web_apps";
@import "module/project";
