@font-face {
  font-family: 'utestfont';
  src: url('../../../static/assets/fonts/utestfont_13.eot?5737580');
  src: url('../../../static/assets/fonts/utestfont_13.eot?5737580#iefix') format('embedded-opentype'),
       url('../../../static/assets/fonts/utestfont_13.woff2?5737580') format('woff2'),
       url('../../../static/assets/fonts/utestfont_13.woff?5737580') format('woff'),
       url('../../../static/assets/fonts/utestfont_13.ttf?5737580') format('truetype'),
       url('../../../static/assets/fonts/utestfont_13.svg?5737580#utestfont_13') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'utestfont_13';
    src: url('../../../src/static/assets/fonts/utestfont_13.svg?10640412#utestfont_13') format('svg');
  }
}
*/

 [class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "utestfont";
  font-style: normal;
  font-weight: normal;
  speak: none;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-material-adjust {
  @extend .material-icons;
  &:hover, &.active {
    color: #ef5454;
  }
  font-size: 18px;
  color: #262626;
  vertical-align: top;
  margin-left: 0;
  margin-right: 0;
}
.icon-status:before { content: '\e800'; } /* '' */
.icon-notifications:before { content: '\e801'; } /* '' */
.icon-courses:before { content: '\e802'; } /* '' */
.icon-tools:before { content: '\e803'; } /* '' */
.icon-search:before { content: '\e804'; } /* '' */
.icon-star:before, .icon-star-rated:before { content: '\e805'; } /* '' */
.icon-check:before { content: '\e806'; } /* '' */
.icon-heart:before { content: '\e807'; } /* '' */
.icon-avatar:before { content: '\e808'; } /* '' */
.icon-follow:before { content: '\e809'; } /* '' */
.icon-link:before { content: '\e80a'; } /* '' */
.icon-chat:before { content: '\e80b'; } /* '' */
.icon-arrowDown:before { content: '\e80c'; } /* '' */
.icon-home:before { content: '\e80d'; } /* '' */
.icon-articles:before { content: '\e80e'; } /* '' */
.icon-write:before { content: '\e80f'; } /* '' */
.icon-inbox:before { content: '\e810'; } /* '' */
.icon-events:before { content: '\e811'; } /* '' */
.icon-share:before { content: '\e812'; } /* '' */
.icon-discussions:before { content: '\e813'; } /* '' */
.icon-location:before { content: '\e815'; } /* '' */
.icon-trash:before { content: '\e816'; } /* '' */
.icon-linkedin:before { content: '\e817'; } /* '' */
.icon-linkedin-material {
  &:before {
    @extend .icon-material-adjust;
    content: '\e817';
  }
  &:hover:before {
    color: $linkedin-main;
  }
} /* '' */
.icon-flag:before { content: '\e818'; } /* '' */
.icon-twitter:before { content: '\e819'; } /* '' */
.icon-twitter-material {
  &:before {
    @extend .icon-material-adjust;
    content: '\e819';
  }
  &:hover:before {
    color: $twitter-main;
  }
  /* '' */
}
.icon-cancel:before, .glyphicon-remove:before { content: '\e81a'; } /* '' */
.icon-facebook:before { content: '\e81b'; } /* '' */
.icon-views:before { content: '\e81c'; } /* '' */
.icon-paper-plane:before { content: '\e81d'; } /* '' */
.icon-projects:before { content: '\e81e'; } /* '' */
.icon-right-open-big:before, .glyphicon-chevron-right:before { content: '\e81f'; } /* '' */
.icon-left-open-big:before, .glyphicon-chevron-left:before { content: '\e820'; } /* '' */
.icon-arrowUp:before { content: '\e821'; } /* '' */
.icon-arrowRight:before { content: '\e822'; } /* '' */
.icon-pencil:before { content: '\e823'; } /* '' */
.icon-uPoints:before { content: '\e825'; } /* '' */
.icon-leaderboard:before { content: '\e826'; } /* '' */
.icon-quote-left:before { content: '\e827'; } /* '' */
.icon-youtube-play:before { content: '\e828'; } /* '' */
.icon-export:before { content: '\e829'; } /* '' */
.icon-picture:before { content: '\e82a'; } /* '' */
.icon-reply:before { content: '\e82b'; } /* '' */
.icon-bell:before { content: '\e82c'; } /* '' */
.icon-eye-off:before { content: '\e82d'; } /* '' */
.icon-circle:before { content: '\e82e'; } /* '' */
.icon-arrows-ccw:before { content: '\e82f'; } /* '' */
.icon-pin:before { content: '\e830'; } /* '' */
.icon-clipboard:before { content: '\e831'; } /* '' */
.icon-archive:before { content: '\e832'; } /* '' */
.icon-docs:before { content: '\f0c5'; } /* '' */
.icon-lock:before { content: '\f512'; } /* '' */

.glyphicon {
  position: relative;
  top: 1px;
  display: inline-block;
  font-family: 'utestfont';
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-pin:before {
  transform:rotate(45deg);
  top:-0.1em;
  position: relative;
}
