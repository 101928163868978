html, body, main {
  height:100%;
  position: relative;
}

html {
  overflow-y: hidden;
}

.block {
  padding:1.5rem;
}

.position-relative {
  position: relative;
}

body.loading-indicator-enabled {
  border-top: 3px solid $brand-primary;
}

.wrapper-all {
  .navbar {
    position: absolute;
  }
  box-shadow:0 0 .7rem rgba(0,0,0,.4);
  height:100%;
  background:#fff;
  position: relative;
  z-index:1;
  margin-left:auto;
  width:100%;
  transition: all 300ms 300ms ease-in-out;
  .container {
    transition:transform 300ms;
  }
  @media (min-width: 1245px) {
    .nav-menu-open & {
      transition: all 300ms;
      width: calc(100% - 30rem);
    }
  }

  @media (min-width: 1600px) {
    .nav-menu-open & {
      width:100%;
    }
  }

  @media (max-width: 1244px) {
    .nav-menu-open & {
      transform: translate(30rem, 0);
      transition: all 300ms;
    }
  }
}

.ntux-wrapper-all {
  .navbar {
    position: absolute;
  }
  .container-fluid {
    max-width: 1440px;
    padding-left: 50px;
    padding-right: 50px;
  }

  box-shadow: 0 0 .7rem rgba(0,0,0,.4);
  height: 100%;
  background: #fff;
  position: relative;
  z-index: 1;
  margin-left: auto;
  width: 100%;
  transition: all 300ms ease-in-out;
  .container {
    transition: transform 300ms;
  }

  @media (min-width: 769px) {
    .nav-menu-open & {
      transition: all 300ms;
      width: calc(100% - 250px);
    }
  }

  @media (max-width: 768px) {
    .nav-menu-open & {
      transform: translate(250px, 0);
      transition: all 300ms;
    }
  }

  @media (min-width: 768px) {
    .container {
      width: 520px;
    }
  }

  @media (min-width: 992px) {
    .container {
      width: 720px;
    }
  }

  @media (min-width: 1245px) {
    .container {
      width: 930px;
    }
  }

  .dashboard, .community, .academy, .academy-home, .academy-course {
    @media (min-width: 1440px) {
      .container {
        width: 1140px;
      }
    }

    @media (min-width: 1600px) {
      .container {
        width: 1280px;
      }
    }

    @media (min-width: 1720px) {
      .container {
        width: 1440px;
      }
    }
  }
}

@media (min-width: 1600px) {
  .ut-aside {
    z-index:4;
    transition:all 400ms;
    transform: translateX(-100%);
  }
  .nav-menu-open .ut-aside {
    transform: translateX(0);
  }
}

.main-ui-view {
  padding-top:7rem;
  height:100%;
  max-height:100%;
  background: #ffffff;
  z-index:1;
  -webkit-overflow-scrolling: touch;
  transition:all .3s ease-in-out;
  @include transition(all .3s ease-in-out);
  &.topOfWindow {
    margin-top: 0;
    height: 100%;
  }
  body.menu-open & {
    @include transform(translate(30rem,0) !important);
    position:fixed;
    @include bp-tablet{
      width: 100%;
      @include transform(translate(30rem,0) !important);
    }
  }
  @include bp-desktop {
    body.side-panel-open & {
      width: calc(100% - 30rem);
    }
  }
  @include bp-phone {
    body.side-panel-open & {
      width: 100%;
    }
  }

  [ui-view] {
    max-height:100%;
    min-height:100%;
    height:100%;
    overflow-y: auto;
  }
  &.unauthenticated-view {
    [ui-view] {
      overflow-y: hidden;
    }
  }
}

slide-up .slide-up {
  transition:all .3s ease-in-out;
  @include transition(all .3s ease-in-out);
  position: fixed;
  bottom: 0;
  body.menu-open & {
  @include transform(translate(30rem,0) !important);
    //overflow-y: hidden;
    position:fixed;
    height: 100%;
    @include bp-tablet{
      @include transform(translate(30rem,0) !important);
    }
  }
  @include bp-desktop {
    body.side-panel-open & {
      width: calc(100% - 30rem);
    }
  }
}

.cover {
  width: 100%;
  padding: 13rem 0 5rem 0;
  h1 {
    color: #fff;
    font-size: 3rem;
  }
  p {
    color: #fff;
  }
}

//functional btns group
.func-btns, .status-btns{
  float: right;
  text-align: right;
  line-height: 3rem;
  @include bp-phone {
    float: none;
    .article & {
      float: right;
    }
  }
}
.func-btns {
  min-width:5rem;
  [class^='icon'] {
    color: #7b8a97;
    font-size: 1.4rem;
    margin-left:.4rem;
    vertical-align: middle;
    @include bp-phone {
      font-size: 1.5rem;
    }
  }
  .icon-quote-left {
    &:hover, &.active {
      color:$brand-primary;
    }
  }
  .icon-share {
    padding: 0;
    transition: none;
    width: 35px;
    background: none;
    border: none;
    &:hover, &.active, &:focus {
      color: #014065;
    }
    &:focus {
      outline: 1px dashed #014065 !important;
    }
  }
}
.status-btns {
  min-width:4rem;
}
//tag btns group
.tags, .func-btns, .status-btns, .event-btns{
  display:inline-block;
  line-height: 3.2rem;
}

.feed-item-header {
  .func-btns {
    line-height: 2.6rem;
  }
}
.feed-content-footer {
  text-align: left;
  .tags, .status-btns > span {
    float: left;
    color: #7b8a97;
  }
}

.section-home-cover-random.event, .feed.event {
  .feed-content-footer {
    text-align: right;
    @include bp-phone {
      text-align: left;
      margin-top: 2.3rem;
    }
  }
}

.section-single-cover .feed.tool {
  .feed-content-footer {
    margin-bottom: -2rem;
  }
}

.section-single-cover.submit {
  margin: 0 0 30px 0;
}

//google map container
.angular-google-map-container {
  height: 25rem;
  margin: 1.5rem 0 3rem 0;
  .gmnoprint img {
    max-width: none;
  }
}

.register-wrapper {
  display: inline-block;
  vertical-align: top;
  .lower-white {
    color: #fff;
    width: 100%;
    text-align: center;
    margin-top: .4rem;
  }
  .lower-black {
    color: #262626;
    width: 100%;
    text-align: center;
  }
}

//load more btn container
.load-more-btn {
  text-align: center;
  .btn {
    margin-top: 3rem;
  }
}

.icon-heart {
  &:hover, &.active {
    color:#fe1186;
  }
}
.icon-heart:not(.clickable):hover {
  color:#7b8a97;
}
.icon-facebook:hover {
  color:$facebook-main;
}
.icon-twitter:hover {
  color:$twitter-main;
}
.icon-linkedin:hover {
  color:$linkedin-main;
}
.icon-flag {
  &:hover, &.active {
    color:#ef5454;
  }
}
.icon-eye-off {
  &:hover, &.active {
    color:$brand-danger;
  }
}
.icon-check.completed {
  color: #fff;
  background-color: #22c064;
  border-radius: .3rem;
}
.icon-lock,
.subscribe-btn {
  &.active, &:hover {
    color: $brand-danger;
  }
}

.icon-archive {
  &:hover, &.active {
    color: $orange;
  }
}

.registration{
  height: 100%;
  overflow: auto;
  #regs_container{
    padding: 0px;
  }
  .container-fluid{
    margin-right: auto;
    margin-left: auto;
    padding: 20px 80px;
    max-width: 1200px;
  }
  .col-xs-12{
    padding: 0 15px;
  }
  @media only screen and (min-width: 992px) {
    #side_banner{
      height: 100%;
      min-height: 100%;
      padding: 30px 40px;
      position: relative;
      display: table;
      .brand{
        position: absolute;
        left: 60px;
        top: 30px;
        float: left;
        img{
          max-height: 30px;
          width: 88px;
        }
      }
      .left-content{
        color: #fff;
        display: table-cell;
        vertical-align: middle;
        .h1{
          color: #fff;
          padding-bottom: 2rem;
          font-size: 3.5rem;
          font-weight: 700;
        }
        .h4{
          color: #fff;
          font-weight: 600;
          font-size: 1.8rem;
        }
        hr{
          border: 2px solid #1BAACB;
          width: 15%;
          float: left;
        }
      }
    }
  }
  @media only screen and (max-width: 1024px) {
    #uTest_icon{
      background: url('/assets/images/utestLogoBlue.svg') no-repeat;
      height: 28px;
    }
    .container-fluid{
      padding: 20px 40px;
    }
  }
  @media only screen and (max-width: 767px) {
    .container-fluid{
      padding: 20px;
    }
  }
  .grey-text{
    color: $gray-700;
  }
  .container-fluid{
    .sign-in-box{
      margin-top: 28px;
      margin-bottom: 75px;
      .sign-in{
        span{
          color: $gray-700;
        }
        a{
          color: #000;
          text-decoration: underline;
          &:focus-visible {
            outline: auto 2px;
            outline-offset: 2px;
            transition: none;
          }
        }
        @include bp-phone{
          padding: 0;
        }
      }
    }
  }
}
