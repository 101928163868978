// Styles common across various NTUX pages - Community
.ntux-wrapper-all, .article-two_column, .feed-item {
  .status-btns, .section-single-toolbar__cell_left, .actions, .func-btns {
    .community-feed-item-like {
      display: inline-block;
      vertical-align: super;
      color: $gray-700;
    }
  }

  .func-btns {
    .material-icons {
      font-size: 18px;
    }
  }

  .community-feed-item__like-button {
    border: none;
    &:hover {
      color: $icon-color-red;
    }
    &:focus {
      color: $icon-color-red;
        i {
        outline: black;
        outline-width: 1px;
        outline-style: dashed;
      }
    }
  }
  .community-feed-item__button {
    background-color: inherit;
    display: inline-flex;
    color: $gray-700;
    position: relative;
    border: none;
    padding-left: 0;
    &:focus {
      i {
        outline: black;
        outline-width: 1px;
        outline-style: dashed;
      }
    }
    &:hover {
      a {
        text-decoration: none;
      }
    }
    a:focus {
      text-decoration: none;
      i {
        outline: black;
        outline-width: 1px;
        outline-style: dashed;
      }
    }
    a:hover {
      text-decoration: none;
    }
  }
  .community-feed-item__button--liked {
    color: $icon-color-red;
    &:hover, &:focus {
      color: $icon-color-red;
        i {
        outline: black;
        outline-width: 1px;
        outline-style: dashed;
      }
    }
  }
  .community-feed-item-footer__text {
    text-align: left;
    position: absolute;
    padding-left: 5px;
    padding-top: 3px;
  }

  .community-feed-item__add-comment-button {
    a {
      color: $black;
      cursor: pointer;
    }
    i {
      &.active, &:hover {
        color:#BD8A00;
      }
      display: inline-block;
    }
    .comment-count {
      display: inline-block;
      vertical-align: super;
      padding-left: 5px;
    }
  }
}
