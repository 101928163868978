//global to-do style
.to-do-group {
  margin-top: 3rem;
  padding-bottom: .5rem;
  border-bottom: 1px #ccc solid;
  &-icon {
    width: 2.5rem;
    height: 2.5rem;
    font-size: 1.4rem;
    padding: 0 .2rem;
    margin-right: .5rem;
  }
  &-heading {
    display: inline-block;
    font-size: 1.8rem;
    font-weight: 800;
    text-transform: capitalize;
    vertical-align: super;
    padding-top: -1rem;
    position: relative;
    top: .3rem;
  }
}
.to-do-item {
  padding: 1.5rem 0 1rem;
  border-bottom: 1px #ccc solid;
  &-date {
    color: #1599e7;
    font-weight: 600;
    font-size: 1.2rem;
  }
  &-title-ori {
    width: 85%;
    color: #3c4042;
    font-weight: 600;
    &:hover { color: #7b8a97; }
  }
  &-title-short, &-sub-title-short {
    display: none;
  }
  &-func {
    vertical-align: top;
    float: right;
    position: relative;
    z-index: 10;
    .icon-check {
      color: #ef5454;
      font-size: 1.6rem;
    }
    .icon-cancel {
      color: #bac1c6;
      font-size: 1.6rem;
    }
  }
  .main-btns {
    text-align: left;
  }
  .progress-info {
    .bar-container{
      width: 25%;
    }
    .btn {
      color: #00a8cc;
      border-color: #00a8cc;
      margin-top: 1rem;
      span {font-size: 1.2rem;}
    }
    .collapse-content ol {
      margin-top: 2rem;
      padding-left: 2rem;
      li {
        min-height: 2.8rem;
        a:not(.icon-check, .icon-cancel)  {
          line-height: 2;
          display: inline-block;
          width: 80%;
          vertical-align: top;
        }
        .cus-checkbox {
          position:relative;
          top: .1rem;
        }
      }
    }
  }
}
//to-do full page modification
#toDoList {
  margin-bottom: 7rem;
  .to-do-group {
    margin: 1rem 0;
    &-icon {
      display:none;
    }
    &-heading {
      font-weight: 700;
      font-size: 1.2rem;
      color: #262626;
      text-transform: uppercase;
    }
  }
  .to-do-item {
    width:100%;
    display: inline-block;
    margin: 0rem;
    border-bottom: 0;
    padding:1rem 0 0 0;
    &-title-ori {
      display: none;
    }
    &-title-short, &-sub-title-short {
      display: block;
      position: relative;
      width:100%;
      line-height: 1.7rem;
      font-size: 1.4rem;
      color: #3c4042;
      font-weight: 400;
      z-index: 9;
      padding-right: 50px;
      &:hover {
        color: #7b8a97;
      }
      .btn {
        position: absolute;
        top: 0;
        right: 0;
        margin-right: 0;
      }
    }
    &-sub-title-short {
      display: inline-block;
      width:78%;
    }
    &.to-do-group-courses > .to-do-item-func {
      display: none;
    }
    .bar-container {
      width: 50%;
    }
  }
}
//course-track page modification
.sub-course {
  ol {
    padding-left: 1.5rem;
    margin-left: 3.5rem;
    a {
      &:hover {
        color: #262626;
      }
    }
    li {
      position: relative;
      padding-right: 2.8rem;
      padding-bottom: 1.3rem;
      line-height: 1.6rem;
    }
  }
  .sub-course-loading {
    padding:1.5rem;
  }
  .sub-course-description {
    font-size: 1.2rem;
    color:#7b8a97;
    margin-left: 3.5rem;
    margin-top: 10px;
  }
}

.to-do-group {
  margin-top: 2rem;
  &-heading {
    font-size: 1.2rem;
    text-transform: uppercase;
  }
}

.to-do-item {
  border:0;
  &-title-ori {
    font-size: 1.4rem;
    font-weight: 300;
  }
  &-date {
    color:$brand-primary;
    text-transform: uppercase;
    font-weight: 800;
    font-size: 1rem;
  }
}

.to-do-see-all {
  padding:2rem 0;
  border-bottom: 1px solid $gray-dark;
  .btn {
    color: $brand-primary;
    font-weight:600;
    background:#313131;
    text-transform: none;
    letter-spacing: 0;
    border-color:$gray-dark;
    padding:1rem;
    &:hover {
      border-color: $brand-primary;
    }
  }
}

.ut-aside {
  .to-do-filter {
    margin:2rem 0 0 0;
  }
  .to-do-group {
    border-bottom:1px solid $gray-dark;
    &-heading {
      color:#fff;
    }
  }
  .to-do-item {
    &-title-ori {
      color: #fff;
    }
    .cus-checkbox .checkbox-toggle:before {
      background:none;
    }
  }
}
