/*!
 * Copyright (C) 2020 to Present Applause App Quality, Inc. All rights reserved.
 */

@import "colors";
@import "styles/forms";

//Font Weights
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-bold: 600;

//Font Sizes
$font-size-xxxl: 56px; // TODO remove
$font-size-xxl: 34px; // TODO remove
$font-size-xl: 24px;
$font-size-lg: 20px;
$font-size-md: 18px;
$font-size-base: 16px;
$font-size-sm: 14px;
$font-size-s: 13px;
$font-size-xs: 12px;
$font-size-xxs: 9px;
$font-size-title: 22px;

@mixin font-xxxl() {
  font-size: $font-size-xxxl;
  line-height: 64px;
  font-weight: $font-weight-light;
}

@mixin font-xxl() {
  font-size: $font-size-xxl;
  line-height: 42px;
  font-weight: $font-weight-light;
}

@mixin font-xl() {
  font-size: $font-size-xl;
  line-height: 34px;
  font-weight: $font-weight-medium;
}

@mixin font-large() {
  font-size: $font-size-lg;
  line-height: 30px;
  font-weight: $font-weight-medium;
}

@mixin font-large-light() {
  font-size: $font-size-lg;
  line-height: 30px;
  font-weight: $font-weight-light;
}

@mixin font-medium() {
  font-size: $font-size-md;
  line-height: 30px;
  font-weight: $font-weight-regular;
}

@mixin font-medium-bold() {
  font-size: $font-size-md;
  line-height: 30px;
  font-weight: $font-weight-bold;
}

// TODO check usages
@mixin font-base() {
  font-size: $font-size-base;
  line-height: 26px;
  font-weight: $font-weight-regular;
}

@mixin font-base-500() {
  font-size: $font-size-base;
  line-height: 26px;
  font-weight: $font-weight-medium;
}

@mixin font-small() {
  font-size: $font-size-sm;
  line-height: 24px;
  font-weight: $font-weight-regular;
}

@mixin font-small-500() {
  font-size: $font-size-sm;
  line-height: 24px;
  font-weight: $font-weight-medium;
}

@mixin font-xs() {
  font-size: $font-size-xs;
  line-height: 24px;
  font-weight: $font-weight-regular;
}

@mixin font-xs-500() {
  font-size: $font-size-xs;
  line-height: 24px;
  font-weight: $font-weight-medium;
}

@mixin font-xxs() {
  font-size: $font-size-xxs;
  line-height: 24px;
  font-weight: $font-weight-regular;
}

@mixin font-xxs-500() {
  font-size: $font-size-xxs;
  line-height: 24px;
  font-weight: $font-weight-medium;
}

@mixin ngf-field-value() {
  color: $ngf-field-value-color;
  font-size: $font-size-base;
  font-weight: $font-weight-regular;
  line-height: 24px;
}

@mixin ngf-form-label() {
  color: $ngf-label-color;
  font-size: $font-size-sm;
  line-height: 24px;
  font-weight: $font-weight-bold;
  padding: 0;
}

@mixin ngf-form-label-hint() {
  color: $ngf-hint-color;
  font-size: $font-size-xs;
  line-height: 16px;
  padding: 4px 0 0 0;
}

@mixin ngf-form-label-error() {
  color: $ngf-error-color;
  font-size: $font-size-xs;
  line-height: 16px;
  padding: 4px 0 0 0;
}

@mixin ngf-field-action() {
  font-size: $font-size-sm;
  line-height: 20px;
  color: $ngf-blue;
  cursor: pointer;
  text-decoration: underline;
  padding: 4px 0 0 0;
}

@mixin ngf-page-title() {
  font-size: $font-size-title;
  line-height: 28px;
  font-weight: $font-weight-regular;
  color: $ngf-grey-900
}

@mixin ngf-tagline() {
  font-size: $font-size-sm;
  line-height: 16px;
  font-weight: $font-weight-bold;
  color: $ngf-grey-900
}

@mixin ngf-tagline-id() {
  font-size: $font-size-xs;
  line-height: 16px;
  font-weight: $font-weight-regular;
  color: $ngf-grey-500
}
