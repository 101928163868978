.home-cover {
  width: 100%;
  text-align: center;
  position: relative;
  h4 {
    font-size: 2rem;
    color: #262626;
    font-weight: 700;
    margin: 1.5rem 0;
    @include bp-phone {
      font-size: 1.5rem;
    }
  }
  h1 {
    color: #fff;
    font-size: 5rem;
    line-height: 1.4;
    margin-top: 2rem;
    margin-bottom: 1.5rem;
    @include bp-phone {
      font-size: 3.7rem;
      line-height: 1.2;
    }
    a {
      color: #2d3234;
      text-transform: uppercase;
      position: relative;
      display: inline-block;
      &:before {
        position: absolute;
        top: 0;
        left: 0;
        overflow: hidden;
        max-width: 0;
        border-bottom: 6px solid #2d3234;
        color: #2d3234;
        content: attr(data-hover);
        -webkit-transition: max-width 0.3s ease-in-out;
        -moz-transition: max-width 0.3s ease-in-out;
        transition: max-width 0.3s ease-in-out;
      }
      &:hover:before {
        max-width: 100%;
      }
    }
  }
  .home-cover-bg {
    position: absolute;
    width:100%;
    height:100%;
    z-index:0;
    background-color:$brand-primary;
    overflow:hidden;
    div {
      position: absolute;
      width:100%;
      height:100%;
    }
    + div {
      position: relative;
      z-index: 9;
    }
  }
  .btn.btn-yellow {
    background: #fdb900;
    border-color: #fdb900;
    color: #262626;
    font-size: 1.2rem;
    @include bp-phone {
      margin: 1.25rem 0 0.25rem 0;
      width: 100%;
    }
    &:hover {
      background: #FFCD00;
    }
  }
  .register-wrapper {
    display: inline-block;
    vertical-align: top;
    @include bp-phone {
      width: 100%;
    }
    .lower-white {
      color: #fff;
      width: 100%;
      text-align: center;
    }
  }
  .btn.btn-black {
    background: #262626;
    border-color: #262626;
    color: #fff;
    font-size: 1.2rem;
    display: inline-block;
    vertical-align: top;
  }
}
.register-wrapper {
  .btn {
    margin: 1.25rem;
    margin-bottom: 0;
    @include bp-phone {
      margin: 1.25rem 0 0.25rem 0;
      width: 100%;
    }
  }
}
.home-welcome {
  h2 {margin-bottom: .5rem;}
  p {margin-top: .5rem;}
}
.easy-write .write-article .btn {
  margin-left: 0.0rem !important;
}

.home-guest-hero {
  background: transparent url('/assets/images/home/uTest_Hero2b.png') no-repeat center center;
  background-size:cover;
  padding: 250px 0 150px;
  text-align:center;
}

.home-guest-hero__title {
  font-size:5rem;
  font-weight: 700;
  line-height:1.1em;
  color:#fff;
  max-width:600px;
  margin: 0 auto;
  hyphens: initial;
}

.home-guest-hero__actions {
  .button {
    margin: 0 10px 20px;
  }
}

.home-guest-hero__p {
  color:#fff;
  font-weight: 600;
  max-width:550px;
  margin: 1em auto 1.5em;
  font-size:1.7rem;
  hyphens: initial;
}

.home-guest-hero__small {
  color:#fff;
  font-weight: 500;
  max-width:550px;
  margin: 0em auto;
  font-size:1.4rem;
  hyphens: initial;
}
