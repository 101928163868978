.discussion-cate {
  font-weight: 600;
  margin: 0;
  .overlay-content & {
    font-weight: 800;
  }
  span {
    text-transform:uppercase;
    &:before {
      content:'';
      width: 1rem;
      height: 1rem;
      margin-bottom: .1rem;
      margin-right: .8rem;
      display: inline-block;
      vertical-align: middle;
      background: #dddddd
    }

    &.welcome:before{
      background: #00a8cc;
    }
    &.earn:before{
      background: #18a78b;
    }
    &.learn:before{
      background: #fdb900;
    }
    &.social:before{
      background: #ff017e;
    }
  }
}
.category-nav:before {
  content:'';
  width: 1rem;
  height: 1rem;
  margin-right: .6rem;
  display: inline-block;
}

.discussion-category-page-cover {
  &-search {
    float:left;
    width:70%;
  }
  &-action {
    float:left;
    width:30%;
    padding:.9rem 0;
  }
  p {
    padding-top: 5rem;
  }
  .select2-theme-form .btn.btn-default {
    border-color: transparent;
  }
}

[discussion-category-cover = 'welcome'], [discussion-category = 'welcome']:before {
  background: #00a8cc !important;
}
[discussion-category-cover = 'earn'], [discussion-category = 'earn']:before {
  background: #18a78b !important;
}
[discussion-category-cover = 'learn'], [discussion-category = 'learn']:before {
  background: #fdb900 !important;
}
[discussion-category-cover = 'social'], [discussion-category = 'social']:before {
  background: #ff017e !important;
}