//styling for carousels
.tools {
  .carousel {
    .carousel-control {
      opacity: 0;
      @include transition(all .3s ease);
      cursor: pointer;
      &:hover{
      	opacity: 1;
      }
      &.left {
      	background-image: -webkit-linear-gradient(left, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
    	background-image: -webkit-gradient(linear, left top, right top, from(rgba(255, 255, 255, 1)), to(rgba(255, 255, 255, 0)));
    	background-image: linear-gradient(to right, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
    	background-repeat: repeat-x;
    	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#80000000', endColorstr='#00000000', GradientType=1);
      }
      &.right {
      	background-image: -webkit-linear-gradient(right, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
    	background-image: -webkit-gradient(linear, right top, left top, from(rgba(255, 255, 255, 1)), to(rgba(255, 255, 255, 0)));
    	background-image: linear-gradient(to left, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
    	background-repeat: repeat-x;
    	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#80000000', GradientType=1);
      }
    }
    .carousel-indicators li {
    	color: #00a8cc;
    	width: 14px;
    	height: 14px;
    	border: 1px solid #00a8cc;
    	margin: 0 .4rem;
    	@include transition(all .3s ease);
    	&.active {
    		background: #00a8cc;
    	}
    }
    .carousel-caption {
    	display: none;
    }
  }
}

.carousel-simple {
  .carousel {
    padding-bottom: 2rem;
  }
  .carousel-control {
    display: none;
  }
  .carousel-indicators {
    bottom: 0;
    margin-bottom: 0;
    li {
      border-color: #3c4042;
      &.active {
        border-color: transparent;
        background: #3c4042;
      }
    }
  }
}