.section-single-cover {
  .section-single-cover-filter {
    float:right;
  }
  .section-desc {
    color: $black;
    font-size: 1.6rem;
    line-height: 1.6;
  }
  .tags {
    padding:2rem 0 3rem 0;
  }
  @include bp-phone {
    text-align: center;
    .section-single-cover-filter {
      float:none;
      text-align:right;
      margin:-2rem 0 2rem 0;
    }
  }
  .feed-header-info {
    float: none;
  }
  .topic-feed-header-info {
    text-align: left;
    .feed-header-info-name {
      a {
        font-weight: 700;
        display: block;
      }
    }
  }
  h5, .time-stamp, h1{
    color: #222;
    a {
      color: #222;
    }
  }
  h5 {
    line-height: 1.4rem;
  }
  h1 {
    font-weight: 500;
    font-size: 4rem;
    max-width: fit-content;
    white-space: normal;
    display: inline-block;
  }
  .func-btns, .status-btns{
    a {
      vertical-align: top;
      &.func-link {
        vertical-align: bottom;
      }
    }
    .icon-heart.active {
      color: #fe1186;
    }
  }
  @include bp-phone{
  	.feed-header-info-icon {
  		display: block;
      margin: 0;
  	}
  }
  &.course {
    padding: 3.3rem 0;
    h1 {
    margin: 0.67em 0 2.4rem;
    }
    .tags {
      line-height: 1.2rem;
      span {
        color: #ffffff;
        text-transform: uppercase;
        font-size: 1.2rem;
        vertical-align: middle;
        display: inline-block;
        a {
          margin: 0 .4rem .2rem .4rem;
        }
      }
      a {
        vertical-align: middle;
        display: inline-block;
      }
    }
  }
  &.article {
    padding-top :10rem;
    .edit-article {
      float: right;
    }
    .feed-header-info {
      display: inline-flex;
      .feed-header-info-name {
        font-weight: 600;
        margin: 0;
        color: $gray-base;
        display: grid;
        .time-stamp {
          position: relative;
          bottom: 5px;
          font-weight: 400;
        }
      }
      &:hover, &:focus {
        text-decoration: none;
        .feed-header-info-name__text{
          text-decoration: underline;
        }
      }
    }
  }
  &.course {
    padding-top :10rem;
    .edit-course {
      float: right;
    }
  }
  .tool {
    border-bottom: none;
    padding: 3rem 0 0 0;
    p {
      margin-bottom:.5rem;
    }
    .web-link {
      float: right;
      @include bp-phone {
        position: relative;
        width: 100%;
        float: left;
        right: 0;
        margin: 0 0 .75rem 0;
      }
    }
    .feed-img {
      height: 10rem;
      width: 10rem;
      background-size: 100% 100% !important;
      float: left;
      @include bp-phone {
        float: none;
      }
    }
    .rating {
      margin: 0;
      float: left;
      top: -0.4rem;
      @include bp-phone {
        float: none;
      }
    }
    .feed-content-footer {
      margin-top: 1.9rem;
    }
    .tags {
      @include bp-phone {
        width: 100%;
        text-align: center;
        margin: .3rem 0 0 0;
      }
    }
    .func-btns {
      @include bp-phone {
        width: 100%;
        text-align: center;
        margin: 2rem 0 0 0;
      }
    }
    .feed-content-heading {
      h2 {
        margin: 0.1rem 0 0 0;
        clear: both;
        @include bp-phone {
          width: 100%;
          text-align: center;
        }
      }
      .category{
        float: left;
        clear: both;
        h4{
          font-size: 1.4rem;
          margin: .5rem 0 0 .2rem;
          font-weight: 600;
        }
      }
    }
  }
  &.project {
    padding-top: 1rem;
    margin-bottom: -3rem;
    h2 {
      @include bp-phone {
        margin-bottom: 3.5rem;
        width: 100%;
      }
    }
    h4 {display:inline}
    .feed-date {
      @include bp-phone {
        margin: 0 0 2rem 0;
        float: right;
        line-height: 2.2rem;
        text-align: right;
        span {
          float: right;
          margin: 0 0 0 1rem;
        }
      }
    }
    .details {
      margin: 0 0 0.2rem 0;
      @include bp-phone {
        float: left;
        width: 100%;
      }
    }
    .func-btns {
      line-height: 2.75rem;
    }
    .status {
      @include bp-phone {
        float: left;
      }
    }
    span.btn {
      font-size: 1rem;
      letter-spacing: 0rem;
      padding: .2rem .5rem;
      width:auto;
      height: auto;
      min-width: 5rem;
      background: #7b8a97;
      border:none;
      display: inline-block;
      text-transform: capitalize;
      margin: -1rem .5rem 0 .5rem;
      @include bp-phone {
        margin: -.4rem .5rem 1rem .5rem;
      }
      &.open {
        background: #7b8a97;
        color: #ffffff;
        margin: -0.9rem .5rem 0 .5rem;
        @include bp-phone {
          margin: 0.2rem .5rem 1rem 0;
        }
      }
    }
    .feed {
      padding: 2.5rem 0 0;
      border-bottom: 0px;
      @include bp-phone {
        padding: 0;
      }
    }
  }
  &.discussions {
    padding: 3rem 0 0 0;
    .discussion-cate {
      margin-top: 3rem;
    }
    h1 {
    font-weight: 800;
    font-size: 3rem;
    color: #262626;
    margin-top: 0.5rem;
    margin-bottom: 0;
    }
    .time-stamp {
      color: #7b8a97;
    }
    @include bp-phone {
      h1 {
        padding: 0.2rem 0 3rem 0;
      }
    }
  }
}

.section-single-body {
  &.article {
    .alignleft, img.alignleft {
      float: left;
      margin: 5px 15px 15px 0;
    }
    .alignright, img.alignright {
      float: right;
      margin: 5px 0 15px 15px;
    }
    .aligncenter {
      text-align: center;
      margin-bottom: 15px;
    }
    img.aligncenter {
      margin-left: auto;
      margin-right: auto;
      display: block;
    }
  }
  &.tool {
    padding-top: 0;
    margin-top: 0;
    .section-single-body-content {
      padding: 0 0 7rem;
      margin-top: 0;
      .carousel {
        margin: 4rem 0 0 0;
        height: 45rem;
        overflow: hidden;
        .carousel-inner {
          height: 100%;
          position: relative;
          .item {
            height: 100%;
            img {
              line-height: 1;
              height: auto;
              width: 100%;
            }
          }
        }
        @media not all  and (transform-3d), (-webkit-transform-3d) {
          .carousel-inner > .item {
            perspective: inherit !important;
          }
        }
      }
    }
  }
  &.course {
    .content-table {
      width: 100%;
      background: #edf0f2;
      border-radius: .4rem;
      padding: 2rem;
      margin-bottom:4rem;
      h3 {
        margin:0 0 1rem;;
      }
      ul {
        margin-left: 2rem;
        li a {
            color:#262626;
            line-height: 2;
        }
        li.selected{
          font-weight: 700;
        }
      }
    }
    h2 {
      margin-top: 5rem;
    }
    .back-to-top {
      margin: 0;
    }
    .course-section-pagination{
      .btn{
        i{
          float: left;
          font-size: 20px;
        }
        .next{
          float: left;
          padding: 1px 2px 0 5px;
        }
        .prev{
          float: left;
          padding: 1px 5px 0 2px;
        }
      }
    }
  }
  &.article {
    .section-single-body-content {
      p, p strong, p span, b, p em, a, li, li em, i, u {
        font-size:1.8rem;
        line-height: 1.6;
      }
    }
    h2:not(.graf), h3:not(.graf), h4:not(.graf) {
      color: #262626;
    }
    p, ul, ol {
      padding: 1.3rem 0rem;
      margin-bottom: 0;
      margin-left:0;
      @include bp-phone{
        padding: 1.3rem 2rem;
      }
    }
    ul, ol {
      margin-left: 3.5rem;
    }
    blockquote {
      margin: 3rem 0 5rem 0;
      font-size: 1.6rem;
      line-height: 1.8;
      padding: 0 0 0 1rem;
    }
  }
  &.tool {
    p {
      margin-bottom: 2rem;
    }
  }
  &.discussion {
    .grid {
      padding-top: 0;
    }
    ul.pagination {
      margin-left: 0;
    }
    .feed {
      .profile-thumb {
        display: inline-block;
      }
    }
  }
  &-filter {
    text-align: right;
    padding: .5rem 0 0;
    .filter-options {
      margin-left:0;
      text-align: left;
      li {
        margin-bottom: 0;
      }
    }
    .filter-search {
      float:left;
      position: relative;
      width:100%;
      max-width:26rem;
      input {
        padding:.6rem .5rem .6rem 2.3rem;
        font-size: 1.2rem;
        border-color:#bac1c6;
      }
      i {
        position: absolute;
        top:50%;
        margin-top: -1.1rem;
        left:.4rem;
        color:#bac1c6;

      }
      input:focus + i + i,
      input:focus + i {
        color: #7b8a97;
      }
      @include bp-phone {
        max-width:100%;
        margin-bottom:1.5rem;
      }
    }
  }
  &-content {
    h3:not(.graf) {
      font-size: 2.4rem;
      font-weight: 600;
      margin-bottom: 2rem;
    }
    h4:not(.graf) {
      font-weight: 600;
      margin-bottom: 1.5rem;
    }
    p {
      font-size: 1.4rem;
    }
    ul {
      margin-left: 4rem;
      @include bp-phone {
        margin-left: 0.5rem;
      }
      li {
        margin-bottom: .7rem;
      }
    }
    .source {
      margin: 11rem 0 1rem;
      span {
        font-weight: 600;
      }
    }
    .course &, .tool & {
      padding-bottom:0;
    }
    .project & {
      margin-top:0;
      border-bottom:0px #ccc solid;
      padding: 0;
      p{
        font-size: 1.6rem;
      }
      .btn-apply {
        margin: 6rem auto 0;
        display: block;
      }
      .project-description {
        margin: 4rem 0;
      }
    }
    .discussion & {
      margin-top: 0;
      padding: 0;
    }
  }
}
.section-single-comment {
  width:100%;
  margin-top:3rem;
  &.feed {
    border-bottom: 1px #d2d6d9 solid;
  }
  &.topic {
    border-bottom: 1px #d2d6d9 solid;
    .feed-header-info {
      text-decoration: none;
      .feed-header-info-name {
        font-size: 1.5rem;
        display: inline-grid;
        margin: 0;
        line-height: 1.4rem;
        color: $gray-base;
        .feed-header-info-name__text {
          margin: 0;
          line-height: 1.4rem;
          font-weight: 600;
          color: #262626;
        }
      }
      &:hover, &:focus {
        .feed-header-info-name__text {
          text-decoration: underline;
        }
      }
    }
  }
  &-heading {
    font-size: 2rem;
    font-weight: 700;
    position: relative;
    padding-bottom: 2rem;
    border-bottom: 1px #ccc solid;
    &:before {
      right: 60%;
    }
    &:after {
      left: 60%;
    }
  }
  .feed-content {
    width: 100% !important;
    article {
      width: 100%;
      margin: 0 0 1rem;
    }
    .rating {
      margin-bottom: .7rem;
    }
  }
  .comment-btn {
    width: 100%;
    display: block;
    text-align: center;
    .icon-chat {
      float: right;
    }
    .reply-btn {
      cursor: pointer;
      float: left;
    }
    .btn {
      text-align: center;
    }
  }
  .comment-child {
    margin: 3rem 0 0 0 ;
    padding: 3rem 0 0 4.5rem;
    display: block;
    border-top: 1px #d2d6d9 solid;
    .comment-btn {
      .reply-btn {
        margin-left: 4.5rem;
        display: none;
      }
    }
  }
  &.topic {
    margin-top: 0rem;
  }
  $max-count-indentation: 3;
  $indentation-width: 2rem;
  .indent {
    padding-left: $max-count-indentation * $indentation-width;
    @for $i from 0 through $max-count-indentation {
      &.indent-#{$i} {
        padding-left: $i * $indentation-width;
      }
    }
  }
  .feed {
    float:none;
  }
  .func-btns {
    .clickable {
      img {
        height:1rem;
        margin:0 .5rem 0 0;
        display:inline;
      }
    }
    .delete-button, .edit-button {
      @extend %ntux-button;
      @extend %ntux-hollow-button-on-white;
      min-width: 150px;
      padding: 0;
      font-size: 12px;

      @include bp-xs {
        min-width: 100px;
      }
    }
  }
}

.section-single-comment-dark {
  .embedly-wrapper {
    text-align: left;
    iframe {
      max-width:32rem;
      height:auto !important;
      margin-right:auto;
    }
  }
  .section-single-comment-heading {
    border:0;
    background:#ffffff;
    @media (max-width:$screen-xs) {
      text-align:center;
      width:100%;
    }
  }
  .section-single-comment-content {
    padding:2rem 2rem 0 2rem;
    background:#f6f8f9;
    border:0;
    .feed-header {
      margin-bottom: 1rem;
      .feed-header-info {
        display: inline-block;
        &:hover, &:focus {
          text-decoration: none;
          .feed-header-info-name__text {
            text-decoration: underline;
          }
        }
        .feed-header-info-name {
          color: $gray-base;
          display: inline-grid;
        }
      }
    }
    .feed-content {
      border-bottom:1px #d2d6d9 solid;
      padding-bottom: 2rem;
      p {
        font-size: 1.4rem;
      }
      .feed-content__reply-link {
        color: $dark-blue;
      }
    }
    &:last-of-type {
      .feed-content {
        border-bottom:0;
      }
    }
    .time-stamp {
      color: $gray-dark;
    }
  }
  .profile-thumb {
    width:2.6rem;
    height:2.6rem;
  }
  .feed-header-info-name {
    line-height: 1.3rem;
    .time-stamp,
    h5,
    h5 a {
      font-size: 1.2rem;
      line-height:inherit;
    }
  }
  .feed-content article {
    white-space: normal;
  }
  .comment-btn {
    max-width:89rem;
    border-top: 1px solid #d2d6d9;
    margin:0 auto;
    padding-top: 6rem;
  }
}


.section-single-cover,
.section-single-body:not(.discussion) {
  .func-btns:not(.small) {
    font-size: 1.8rem;
    * {
      font-size: inherit;
      vertical-align:top;
    }
  }
}

.section-single-cover.discussions {
  .func-btns.small {
    button {
      @extend %ntux-button;
      @extend %ntux-hollow-button-on-white;
      min-width: 150px;
      padding: 0;
      font-size: 12px;
    }
    .delete-confirmation {
      button {
        margin-top: .3rem;
      }
      button:first-child {
        margin-left:1.5rem;
      }
    }
  }
}

.navigate-prev-next {
  text-align: left;
  span {
    display:inline-block;
    vertical-align: middle;
  }
  ul {
    list-style:none;
    display:inline-block;
    vertical-align: middle;
    li {
      float:left;
      padding-right: .8rem;
    }
    a {
      float:left;
      text-align: center;
      font-size: 1.8rem;
      position: relative;
      color: $blue;

      &:hover {
        opacity: .7;
      }
      &.inactive {
        color: $gray;
        cursor:default;
      }
    }
  }
  @include bp-phone {
    margin-bottom: 2rem;
  }
}

.slide-up .dropdown-menu {
  top:auto !important;
  bottom:100% !important;
  max-height:20rem;
  overflow:auto;
  margin-left:0;
}

.embedly-wrapper {
  text-align: center;
  iframe {
    max-width:100%;
  }
}

.section-single-toolbar {
  font-size: 1.6rem;
  padding-top:1.5rem;
  &, a, .feed-like:after, .feed-like {
    color: $gray;
  }
  &__cell_left {
    float:left;
  }
  &__cell_right {
    float:right;
  }
  hr {
    margin:1.5rem 0;
    border-top: 1px solid $gray-light;
  }
  .icon-heart {
    margin:0 1rem 0 0;
  }
  @media (max-width: $screen-xs) {
    font-size: 1.4rem;
    .icon-heart {
      margin:0 .5rem 0 0;
    }
  }
}

.projects-action-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 20px;
  margin-top: 30px;
  a {
    font-size: 24px;
  }
  i {
    color: #0372B4;
    background-color: #EBF4F8;
    border-radius: 88px;
    font-size: 110px;
    padding: 33px;
    margin: 20px 0;
  }
  p {
    font-size: 16px;
    text-align: justify;
  }
}

.section-title {
  text-align: center;
  font-size: 2.5rem;
  line-height: 1.1em;
  padding:3rem 0;
}

.section-maintenance-title {
  color: #fff;
  text-align: center;
  font-size: 3.2rem;
  line-height: 1.1em;
  padding-top: 3rem;
}

.section-maintenance-desc {
  color: #fff;
  text-align: center;
  font-size: 2.5rem;
  line-height: 1.1em;
}

.recommendations-wrapper {
  margin-top: 4rem;
  border-top:1px solid $gray-light;
}

.recommendation-item {
  padding:2.5rem 2.5rem 4rem;
  height:23rem;
  border: 1px solid rgb(210, 214, 217);
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.06);
  border-radius:.4rem;
  position: relative;
  .feed-item-info {
    display: block;
    text-decoration: none;
    .feed-item-info_title {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: black;
      font-weight: 600;
      &:hover, &:focus {
          text-decoration: underline;
      }
    }
    small {
      color: $gray-dark;
    }
  }
  @media(max-width: $screen-xs-max) {
    margin-bottom: 3rem;
  }
  p.user-slug, p.tooltip-user-name {
    padding: 0;
  }
  .user-slug {
    a {
      color: $light-blue;
      &:focus {
        text-decoration: underline;
      }
    }
  }
}

$rit-font-size: 2.2rem;
$rit-line-height: 1.136;
$rit-lines-to-show: 3;

.recommendation-item-title {
  display: block; /* Fallback for non-webkit */
  display: -webkit-box;
  max-width: 400px;
  height: $rit-font-size*$rit-line-height*$rit-lines-to-show; /* Fallback for non-webkit */
  //margin: 0 auto;
  font-size: $rit-font-size;
  line-height: $rit-line-height;
  -webkit-line-clamp: $rit-lines-to-show;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 20px;
  margin-bottom: 10px;
  a {
    color: $gray-base;
    font-weight: 800;
    font-size: 2.2rem;
    line-height: 1.2;
  }
}

.recommendation-item-footer {
  position: absolute;
  font-size: 1.4rem;
  bottom: 2.5rem;
  left: 2.5rem;
}

.section-single-cover.discussions {
  .main-btns {
    button {
      @extend %ntux-button;
      @extend %ntux-hollow-button-on-white;
      min-width: 150px;
      &.delete {
        padding: 0;
      }
    }
  }
  @media(max-width: $screen-sm) {
    .main-btns {
      margin-top:1.5rem;
      text-align:center;
    }
    .topic-feed-header-info {
      text-align:center;
    }
    .discussion-cate {
      margin-top: 0;
    }
  }
  .topic-feed-header-info {
    text-align: left;
    display: inline-block;
    .feed-header-info-name {
      display: inline-grid;
      .feed-header-info-name__text {
        font-size: 1.5rem;
        font-weight: 600;
        margin: 0;
        color: $gray-base
      }
    }
    &:hover, &:focus {
      text-decoration: none;
        .feed-header-info-name__text {
          text-decoration: underline;
      }
    }
  }
}
