.upload-btn-container {
  margin: 2rem;
  .upload-btn {
    font-size: 1.2rem;
    min-width: 15rem;
    padding: 1.3rem 2rem;
    &.uploaded {
      background: #262626;
    }
  }
  .filename {
    display: block;
    font-size: 1.2rem;
    margin: 1rem;
  }
  input {
    display: none;
  }
}