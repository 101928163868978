html {
  font-size: 62.5%;
  //font-size: 1.4rem;
  background:#333;
  -webkit-font-smoothing: antialiased;
}

body {
  -webkit-overflow-scrolling: touch;
}

h1, h2, h3, h4, .h1, .h2, .h3, .h4{
  color:#262626;
  font-weight:800;
  line-height:1.2;
  &.sub-header {
    font-weight: 300;
    font-size: 2rem;
    margin-top: 0;
  }
  a {
    color: $blue;
    font-size: inherit;
  }
  &.underline {
    padding-bottom: 2rem;
    margin-bottom: 0;
    border-bottom: 1px solid #d2d6d9;
  }
}
h1, .h1{
  font-size: 3.3rem;
  &.large {
    font-size: 4rem !important;
  }
  &.xlarge {
    font-size: 5rem !important;
    .upper {
      text-transform: uppercase;
      color: #00a8cc;
      font-size: inherit;
    }
  }
}
h2, .h2{
  font-size: 2.6rem;
}
h3, .h3{
  font-size: 2.2rem;
}
h4, .h4{
  font-size: 2rem;
  @include bp-phone{
    font-size: 1.5rem;
  }
}
h5, .h5{
  font-size: 1.5rem;
  font-weight:700;
  margin:0;
}
h6, .h6{
  font-size: 1.2rem;
  font-weight:700;
  text-transform:uppercase;
  margin:0;
}
p, .p {
  color:#262626;
  font-size:1.6rem;
  -webkit-font-smoothing: antialiased;
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: normal;
  word-break: break-word;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
  a {
    color: $blue;
    font-size: inherit;
    &:hover {
      color: $light-blue;
    }
  }
}
blockquote {
  border-left: 4px #00a8cc solid;
  margin:2rem 0 2rem 0;
  line-height:1.5;
  font-size: 1.6rem;
  padding: 0 0 0 1rem;
}
a, button {
  text-decoration: none;
  color: $blue;
}
a:hover, a:focus, a:active {
  text-decoration: underline;
  color:#005EB8;
}
ul {
  padding:0;
  margin:0;
}
iframe {
  border:0;
}
input[type="text"], input[type="email"], input[type="password"], input[type="url"], textarea{
  width: 100%;
  border-radius: 3px;
  border: 1px solid $gray-light;
  padding: 1rem;
  &:focus {
    background: $gray-lighter;
    outline: auto 2px;
    border-radius: 0.5rem;
    z-index: 3,
  }
}

@include bp-phone {
  .mobile-hide {
    display: none !important;
  }
}

[ui-sref] {
    cursor: pointer
}

.clickable {
  cursor:pointer;
}

.hidden-menu {

  position: relative;

  .hidden-menu-trigger {
    display:inline-block;
    background: none;
    border: none;
    [class^='icon'] {
      margin:0;
    }
    .material-icons {
      &:not(:hover) {
        color: $gray-base;
      }
    }
    &:hover {
      [class^='icon'] {
        color:$brand-primary;
      }
    }
  }

  img {
    margin:0;
    height:1.8rem;
    display:inline-block;
    vertical-align: middle;
  }

  ul {
    margin-top:1.5rem;
    position: absolute;
    top:100%;
    right:0;
    display:inline-block;
    list-style: none;
    max-height:0;
    padding:0;
    z-index:9;
    overflow:hidden;
    transition: none;
    background:white;
    box-shadow: 0rem 0rem .2rem $gray;
    border-radius: .5rem;
    li {
      opacity:0;
      transition: opacity 200ms 50ms;
      a {
        padding:.4rem 0rem;
        margin:0 !important;
        display:block;
        text-align: right;
        color:#7b8a97;
        font-size: 1.3rem !important;
        line-height: 1.2em;
        font-weight:400;
        &:hover {
          color:$blue;
        }
      }
    }
    &.active {
      max-height: 150px;
    }
  }

  .hidden-menu-trigger:focus {
    & + ul.active {
      display: block;
      padding:1.5rem 2rem;
      max-height:40rem;
      transition: none;
      overflow:visible;
      li {
        opacity:1;
      }
      &:before {
        content: '';
        width: 0;
        height: 0;
        border-left: .7rem solid transparent;
        border-right: .7rem solid transparent;
        border-bottom: .7rem solid $gray-lighter;
        position: absolute;
        top:-.7rem;
        right:0.8rem;
      }
      &:after {
        content: '';
        width: 0;
        height: 0;
        border-left: .5rem solid transparent;
        border-right: .5rem solid transparent;
        border-bottom: .5rem solid white;
        position: absolute;
        top:-.5rem;
        right:1rem;
      }
    }
  }

}

.func-link, .func-link:focus {
  color:#7b8a97;
  text-decoration: underline;
  &:hover {
    color:#262626;
    text-decoration: underline;
  }
}

.popover {
  &.bottom {
    margin-top: .5rem;
  }
  .popover-title {
    padding:1.5rem 2rem;
    background:#fff;
    font-size: 1.6rem;
    font-weight: 700;
  }
  .popover-content {
    padding:2rem;
  }
}

code.clickable {
  &:hover {
    font-weight: 800;
    box-shadow: 1px 1px 0px $gray;
  }
}

.label-outline {
  border:1px solid $gray-light;
  background:none;
  color:$gray-dark;
  font-weight: normal;
}

.label-block {
  display:block;
  text-align: left;
  overflow:hidden;
  text-overflow: ellipsis;
}

.label-m {
  font-size: 1.5rem;
  line-height: 1.8em;
  .close {
    font-size: 200%;
    line-height: inherit;
  }
}

.hidden-mobile {
  @media(max-width: 480px) {
    display: none !important;
  }
}
.visible-mobile {
  @media(min-width: 481px) {
    display: none !important;
  }
}

.tab-content {
  position: relative;
}

.overflow-hidden {
  overflow-y: hidden;
}

.overflow-scroll {
  overflow-y: scroll;
}
