//colors
$brand-error: #D42D0E;
$brand-error-on-dark-bg: #EFABA0;

.dark {
  color:rgba(38,38,38, 1) !important;
  border-color: rgba(38,38,38, 1) !important;
}
.green {
  color:rgba(34,192,100, 1) !important;
  border-color: rgba(34,192,100, 1) !important;
}
.blue {
  color: rgba(21, 153,231,1) !important;
}
.lightBlue {
  color: rgba(0, 168,204, 1) !important;
}
.yellow{
  color: rgba(253,185,0,1) !important;
}

$darkred: rgb(139,0,0);
$orangered: rgb(255,69,0);
$orange: rgb(255,165,0);
$yellowgreen: rgb(154,205,50);
$darkyellowgreen: rgb(15,137,25);
$green: rgba(34,192,100, 1);
$darkgreen: rgba(23, 135, 70, 1);
$lightblue: rgba(0, 168, 204, 1);

$gray-50: #f3f4f5;
$gray-100: #e6e7e8;
$gray-200: #d2d3d4;
$gray-300: #F0F0F0;
$gray-500: #86878A;
$gray-700: #59595C;
$black: #282828;
$white: #fff;

// UT-2690 + UT-2700, increase
// contrast between floating label text and background
// when input is focused / active
$label-text-on-dark-bg: #CCCCCC;
