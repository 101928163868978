/*!
 * Copyright (C) 2023 to Present Applause App Quality, Inc. All rights reserved.
 */
@use "@angular/material" as mat;
@use "theme/calendar-theme";
@use "styles/ng-falcon-fixes";

@use "src/lib/dialog/dialog";
@use "src/lib/ngf-button";
@use "src/lib/text-editor/text-editor";
@use "src/lib/badge/badge.directive";

// importing Proxima Nova font
@import url("https://use.typekit.net/emc4mjh.css");

// importing Source Code Pro font (font-family: 'Ubuntu Mono')
@import url("https://fonts.googleapis.com/css2?family=Ubuntu+Mono:ital,wght@0,400;0,700;1,400;1,700&display=swap");

$font-family: proxima-nova, "Open Sans", "Gill Sans MT", "Gill Sans", Corbel, Arial, sans-serif;

// TODO: in the future we should enable this by default
@mixin useProximaNovaGlobally() {
  $custom-typography: mat.define-typography-config($font-family);
  @include mat.core($custom-typography);

  html, body {
    font-family: $font-family;
  }
}
