.fourHundred {
  width: 90%;
  max-width: 420px;
  margin: 0 auto;
  padding-top: 120px;

  h1 {
    font-weight: 600;
    font-size: 34px;
  }

  h2 {
    font-size: 18px;
    font-weight: 400;
  }

  .btn {
    margin-top: 12px;
  }

  img {
    width: 34px;
    vertical-align: top;
  }
}

.fourHundredNotAuthorized {
  @extend .fourHundred;
  text-align: center;
  font-family: "proxima-nova", "Helvetica Neue", sans-serif;
  font-size: 16px;
  margin-bottom: 50px;

  h1 {
    font-size: 20px;
    font-weight: bold;
  }

  .btn-unauth {
    background-color: #E6E6E6;
    border-radius: 4px;
    border-color: #E6E6E6;
    color: black;
  }
}
