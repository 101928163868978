@mixin auto-detected(){
  .auto-detected{
    span.ui-select-toggle, ngf-input-container{
      border: 1px solid $blue;
    }
    ~span.check-mark {
      font-size: 12px;
      color: $blue;
      float: right;
      i {
        font-size: 12px;
        vertical-align: middle;
      }
    }
  }
  input.auto-detected{
    border: 1px solid $blue;
  }

  select.signup-form-input-select--native {
    padding: 10px;

    &.auto-detected {
      border: 1px solid $blue;
    }
  }

}

.sign-up-form-container {
  .ui-select-bootstrap > .ui-select-choices {
    max-height: 250px;
  }
  .ui-select-bootstrap .ui-select-toggle > a.btn{
    width: auto;
  }
  label{
    font-weight: 600;
    letter-spacing: 0.01em;
    color: $gray-base;
  }
  .loading-overlay {
    display:none;
    position: absolute;
    top:0;
    right:0;
    width:100%;
    height:100%;
    .loader {
      width:100%;
      position: absolute;
      top:50%;
      transform: translateY(-50%);
    }
  }
  &.loading .loading-overlay {
    display:block;
    z-index:4;
  }

  .sign-up-form-inner {
    transition: filter 400ms, opacity 400ms;
  }

  &.loading .sign-up-form-inner {
    filter: blur(1px);
    opacity:0.5;
  }
  .step-header{
    .step-title{
      font-size: 1.6rem;
      font-weight: normal;

      span {
        display: block;

      }
      .sub-title{
        font-size: 3rem;
      }
      @include bp-phone{
        margin: 0 0 1rem 0;
        line-height: 1;
        font-size: 1.8rem;
      }
    }
  }
  .header{
    margin-top: 3rem;
  }
  .progress_bar{
    float: right;
    width: auto;
    .circle{
      display: inline-block;
      float: left;
      width: 20px;
      height: 20px;
      border-radius: 15px;
      vertical-align:top;
      background: #fff;
      .label {
        display: block;
        width: 20px;
        height: 20px;
        line-height: 17px;
        border-radius: 15px;
        color: #595959;
        font-size: 1.2rem;
        border: 2px solid #595959;
        padding: 0;
      }
      &.done, &.current{
        .label {
          color: #FFF;
          background: #595959;
          border-color: #595959;
        }
      }
      &.current{
        .label {
          background: $blue;
          border-color: $blue;
        }
      }
    }
    .bar {
      display: inline-block;
      float: left;
      width: 15px;
      height: 2px;
      border-left: none;
      border-right: none;
      border-radius: 0;
      margin-top: 9px;
      vertical-align: top;
      background: #D8D9DA;
    }
    @include bp-desktop{
      padding: 0;
    }
  }
  .user-form{
    .error-msg{
      position: absolute;
      color: $brand-error;
      font-size: 1.2rem;
      padding: .2rem .5rem;
      display: none;
    }

    .gender-container{
      .error-msg{
        position: static;
      }
    }

    .location {
      .address {
        padding-left: 0;
        .ui-select{
          margin-left: 0;
        }
      }
    }

    .form-group{
      margin-bottom: 25px;
      margin-right: 0;
      date-control{
        width: 100%;
        display: inline-block;
      }
      .validation-icon{
        display: none;
        bottom: 14px;
        position: absolute;
        right: 3rem;
        font-size: 15px;
      }
      input.ng-dirty.ng-invalid:not(.ng-pending){
        border-color: $brand-error;
      }
      input.ng-dirty.ng-valid {
        border-color: #d2d6d9;
      }
      input.ng-dirty ~ .validation-icon {
        color: $green-base;
        display:block;
      }
      input.ng-dirty.ng-invalid ~ .validation-icon {
        display: block;
        color: $brand-error;
      }
      input[type="text"], input[type="email"], input[type="password"], .select2-theme-form .btn.btn-default{
        border-radius: .5rem;
        border-color: #ccc;
        &::placeholder {
          color: $gray-600;
        }
        /* For Chrome, Edge, and Safari */
        &::-webkit-input-placeholder {
          color: $gray-600;
        }
        /* For Firefox */
        &::-moz-placeholder {
          color: $gray-600;
        }
        /* For IE 10+ */
        &:-ms-input-placeholder {
          color: $gray-600;
        }
        /* For Opera */
        &::-o-input-placeholder {
          color: $gray-600;
        }
        &:focus {
          outline: auto 2px;
          border-radius: .5rem;
          background-color: $white;
        }
      }
      .ui-select-box{
        margin: 0 -15px;
      }
      .ui-select-multiple.ui-select-bootstrap{
        padding: .7rem;
        border-radius: .5rem;
        box-shadow: none;
      }
      .radio-btn{
        span{
          padding: .5rem;
          vertical-align: text-bottom;
        }
      }
      input.ng-dirty.ng-invalid ~ .validation-icon {
        display: block;
        color: $brand-error;
      }
      input.ng-dirty.ng-invalid ~ .error-msg{
        @extend .error-msg;
        display: block;
      }
      input.ng-dirty.ng-valid ~ .error-msg{
        display: none;
      }
      .suggested-email, .strength-meter{
        & ~ .validation-icon{
          bottom: 35px;
        }
        a{
          cursor: pointer;
        }
      }
      @include auto-detected();
    }
    &.invalid{
      input.ng-invalid:not(.ng-pending){
        border-color: $brand-error;
      }
      select.ng-invalid:not(.ng-pending){
        border-color: $brand-error;
      }
      input.ng-invalid ~ .validation-icon {
        display: block;
        color: $brand-error;
      }
      input.ng-invalid ~ .error-msg, .ui-select-container .error-msg, .select2-theme-form .error-msg{
        @extend .error-msg;
        display: block;
      }
      .checkmark.error{
        border: 1px solid $brand-error;
      }
    }
    select {
      height: 43px;
      background-color: $white;
      border-radius: .5rem;
      border-color: #ccc;
      color: #262626;
      box-shadow: none;
      width: -moz-available;
      width: -webkit-fill-available;
      &.ng-pristine {
        color: #75767A;
      }
      &:focus {
        outline: auto 2px;
      }
    }
  }
  .form-group-box{
    float: left;
    padding: 1rem 0rem;
    border-bottom: 1px solid $gray-lighter;
    margin-bottom: 2rem;
    .devices-box{
      display: flex;
      flex-direction: column;
      .first{
        order: 1;
      }
      .second{
        order: 2;
        margin-top: 4rem;
      }
      .ui-select-box{
        @include auto-detected();
      }
    }
    .step-intro{
      color: $gray-base;
      font-size: 16px;
      padding-right: 15px;
      @include bp-mobile{
        padding: 0;
      }
    }
  }
  .next-step{
    margin-bottom: 1rem;
    width: auto;
    @include bp-xs{
      width: 100%;
    }
  }
  .btn-inline-error{
    display: inline-block;
    vertical-align: middle;
    padding: 1rem 2rem;
    text-align: center;
    color: $brand-error;
    @include bp-xs{
      width: 100%;
    }
  }
  .strength-meter {
    position: relative;
    height: 6px;
    background: #DDD;
    margin: 10px auto 10px;
    border-radius: 3px;
    &:before, &:after{
      content: '';
      height: inherit;
      background: transparent;
      display: block;
      border-color: #FFF;
      border-style: solid;
      border-width: 0 5px 0 5px;
      position: absolute;
      width: 80px;
      z-index: 10;
    }
    &:before{
      left: 70px;
    }
    &:after{
      right: 70px;
    }
    .strength-meter-fill {
      background: transparent;
      height: inherit;
      position: absolute;
      width: 0;
      border-radius: inherit;
      transition: width 0.5s ease-in-out, background 0.25s;

      &[data-strength='0'] {
        background: $darkred;
        width: 20%;
      }
      &[data-strength='1'] {
        background: $orangered;
        width: 40%;
      }
      &[data-strength='2'] {
        background: $orange;
        width: 60%;
      }
      &[data-strength='3'] {
        background: $yellowgreen;
        width: 80%;
      }
      &[data-strength='4'] {
        background: $green;
        width: 100%;
      }
    }
  }
  .pass-instruction{
    padding: 0rem 2rem;
    h4{
      text-align: center;
      margin: 0 0 1rem 0;
      color: $darkred;
      &[data-strength='1']{
        color: $orangered;
      }
      &[data-strength='2']{
        color: $orange;
      }
      &[data-strength='3']{
        color: $darkyellowgreen;
      }
      &[data-strength='4']{
        color: $darkgreen;
      }
    }
  }
  ::placeholder {
    color: $gray-light;
    opacity: 1;
  }

  :-ms-input-placeholder {
    color: $gray-light;
  }

  ::-ms-input-placeholder {
    color: $gray-light;
  }
  a.btn-green, button.btn-grey, button.btn-green{
    text-transform: none;
    min-width: 10rem;
    font-weight: bold;
    padding: 8px 10px 8px 15px;
    i{
      font-size: 20px;
      vertical-align: middle;
    }
  }
  a.btn-green:hover,a.btn-green:focus, button.btn-green:hover, button.btn-green[disabled]{
    background: #1ec698;
    border-color: #1ec698;
    opacity: 1;
  }

  button.btn-grey{
    background: #F3F4F5;
    border-color: #F3F4F5;
    color: #58595C;
    padding: 8px 20px 8px 15px;
    width: auto;
    @include bp-xs{
      width: 100%;
    }
  }
  button.btn-grey:focus-visible{
    background: #eaeced;
    border-color: #eaeced;
    outline: auto 2px;
    outline-offset: 2px;
    transition: none;
  }
  .registration-intro{
    margin-bottom: 2rem;
    article, p, p strong, p span, b, p em, a, li, li em, i, u {
      font-size: 16px;
      color: #A1A2A4;
    }
    h1, h2, h3, h4, h5{
      color: #A1A2A4;
    }
    p,ul,ol{
      padding: 1.3rem 0rem;
      margin-bottom: 0;
      margin-left: 0;
    }
    ul,ol{
      margin-left: 3.5rem;
    }
  }
  .ladda-progress{
    background: #1CB78D;
    border-color: #1CB78D;
    border-radius: 30px 0 0 30px;
  }
  ::-ms-clear {
    display: none;
  }
  .utest-captcha {
    text-align: center;
    min-width: 250px;
  }

  .signup-consent {
    width: 75%;
    padding-left: 4rem;
  }

  .signup-consent--highlight {
    background: #F4FBFC;
    padding: 2rem 2.8rem 2rem 4rem;
  }

  .signup-consent__checkbox {
    margin-left: 1rem;
  }

  .signup-consent__checkbox--highlight {
    margin-top: 2rem;
  }

  input:checked ~ .signup-consent__checkbox--highlight {
    background-color: $blue;
  }

  .signup-consent__text--highlight {
    color: $dark-blue;
  }

  .ui-select-match.btn-default-focus {
    outline: auto 2px !important;
    border-radius: 0.5rem;
  }

  .ui-select-container:focus-within, .ui-select-match.btn-default-focus:focus-within, .ui-select-match:focus-within {
    outline: auto 2px;
    border-radius: 0.5rem;
    &:not(.ui-select-multiple) {
      .ui-select-match > span{
        outline: auto 2px !important;
        border-radius: 0.5rem;
      }
    }
  }

  .ui-select-match {
    button.clear-btn {
      background: none;
      border: none;
      width: 2rem;
      height: auto;
      padding: 0;
      i {
        font-size: 1.4rem;
      }
      &:focus {
        outline: 2px auto;
        color: inherit;
      }
    }
  }

  .signup-map {
    width:100%;
    height:36rem;
    &:focus {
        box-shadow: 0 0 3px 2px lighten($blue, 25%);
    }
    a {
      text-decoration: underline!important;
    }
  }
  .google-maps-instructions {
    display: inline-block;
    vertical-align: middle;
    padding-top: 1rem;
    text-align: center;
  }

  ngf-autocomplete#city {
    width: 100%;

  ngf-input-container:focus-within {
    outline: auto 2px;
  }
    .suffix .toggle-btn {
      display: none;
    }
  }

}

.sign-up-form {
  width: 100%;
  display: inline;
  display: flex;
  flex-flow: row wrap;
  text-align: center;
  justify-content: center;
  margin-top: 3rem;
  @include transition(0.3s all ease-in-out);
  &-input {
    position: relative;
    flex: 1 100%;
    max-width: 100%;
    padding: 1rem;
    height: 9rem;
    &.long {
      width: 33rem;
      max-width: 33rem;
      margin: 0 auto 4rem;
      label {
        font-size: 1.3rem;
        @include bp-phone {
          width: 70%;
          top: 2.7rem;
          left: 15%;
        }
      }
      @include bp-phone {
        width: 100%;
      }
    }
    @include bp-tablet {
      flex: 1 50%;
      max-width: 50%;
    }
    input:not(.ui-select-search), .ui-select-container {
      height: 2rem;
      background: #262626;
      border: 1px #262626 solid;
      padding: 0 2rem;
      color: #fff;
      font-size: 1.6rem;
      font-weight: 700;
      transition: all .2s ease-in-out;
      text-align: left;
      &:focus, &.input-filled, &.ng-invalid.ng-touched, &.ui-select-container.open, &.ui-select-container.ng-dirty{
        background: #2d3234;
        border-color:  #7b8a97;
        height: 5rem;
        @include transform (translateY(2rem));
        &~.sign-up-form-input-label {
          @include transform (translate(-2rem, -2.8rem));
          color: $label-text-on-dark-bg;
          font-size: 1.2rem;
          width: 100%;
          text-align: left;
        }
      }
      &:focus {
        border-color: $green-base;
      }
      &.ng-invalid.ng-touched {
        border-color: $brand-error;
      }
    }
    &-label {
      font-size: 1.6rem;
      font-weight: 700;
      color: #fff;
      position: absolute;
      z-index:1;
      top: 3.4rem;
      left: 3.4rem;
      transition: all .2s ease-in;
    }
    .ui-select-container {
      border-radius: 0.5rem;
      padding:0;
      border:0;
      position: relative;
      z-index:2;
      & + .sign-up-form-input-label {
        z-index:3;
      }
      .btn {
        background: none;
        border:none;
        color:#fff;
        font-size: 1.6rem;
        font-weight: 700;
        padding:0;
        height:7rem;
        * {
          font-size: inherit;
        }
      }
      .ui-select-toggle > .caret {
        right:0;
      }
      .ui-select-match {
        padding:0 2rem;
        border-radius:.3rem;
        &.btn-default-focus {
          background:none;
          border:.1rem solid $green-base;
        }
        .ui-select-container.open & {
          border:.1rem solid $green-base;
        }
      }
      .ui-select-search {
        background:none;
        color:#fff;
        padding:0 2rem;
        line-height: 5rem;
        height:5rem;
        border-color: $green-base;
        &:focus {
          box-shadow:none;
        }
      }
      .ui-select-choices {
        background:#3c4042;
        border:0;
        margin-top: .1rem;
        .ui-select-choices-row a {
          color:#d2d6d9;
          transition:color 100ms;
          div {
            font-size: 1.4rem;
            font-weight: 700;
          }
        }
        .ui-select-choices-row {
          &.active, &.active:hover, &:hover {
            a {
              background:#fff;
              div {
                color:#262626;
              }
            }
          }
        }
      }
      &.ng-dirty {
        .btn {
          height:4.8rem;
          line-height:4.8rem;
          transition:all 0s;
        }
        .ui-select-search, .ui-select-match {
          border:1px solid #7b8a97;
        }
      }
      &.open {
        border:1px solid $green-base !important;
        .ui-select-search {
          border:none;
        }
      }
      .ui-select-match.btn-default-focus {
        border:1px solid $green-base;
      }
    }
  }
  .sign-up-form-input.error .ui-select-container {
    background: #2d3234;
    height: 5rem;
    border:1px solid #ef5454;
    @include transform (translateY(2rem));
    &~.sign-up-form-input-label {
      @include transform (translate(-2rem, -2.8rem));
      color: $label-text-on-dark-bg;
      font-size: 1.2rem;
      width: 100%;
      text-align: left;
    }
    .btn {
      height:4.8rem;
      line-height:4.8rem;
      transition:all 0s;
    }
    &.ng-dirty .ui-select-match {
      border:none;
    }
  }
  .checkbox-container {
    flex: 1 100%;
    padding-top: 1rem;
    text-align: center;
    margin: 1rem auto 4rem;
    max-width: 100%;
    .cus-checkbox {
      margin-bottom: 0;
      position: relative;
      width: 36rem;
      max-width: 100%;
    }
    .checkbox-toggle {
      padding-left: 2.5rem;
      color: #fff;
      text-transform: uppercase;
      font-size: 1.2rem;
    }
  }
  &.ng-submitted {
    .sign-up-form-input input.ng-invalid {
      background: #2d3234;
      border-color: $brand-error;
      height: 5rem;
      @include transform (translateY(2rem));
      &~.sign-up-form-input-label {
        @include transform (translate(-2rem, -2.8rem));
        color: $label-text-on-dark-bg;
        font-size: 1.2rem;
      }
    }
  }
}

.sign-up {
  background:transparent url('/assets/images/sign-up-bg.png') center center no-repeat;
  background-size: cover;
  text-align:left;
  padding:30px;
  margin-bottom:-100px;
}

@media (max-width: 320px) {
  .sign-up-form-container {
    .user-form {
      .form-group {
        .months {
          padding-right: 0px;
        }
        .days{
          padding: 0 5px 0 5px;
        }
        .years{
          padding-left: 0px;
        }

        .ui-select-bootstrap .ui-select-choices-row>span{
          padding: 3px 10px;
        }
      }
    }
  }
}

@media (min-width: 1024px) and (max-width: 1280px) {
  .sign-up-form-container {
    .user-form {
      .form-group {
        .months {
          padding-right: 10px;
        }
        .days{
          padding: 0 10px;
        }
        .years{
          padding-left: 10px;
        }

        .ui-select-bootstrap .ui-select-choices-row>span{
          padding: 3px 10px;
        }
      }
    }
  }
}

.profile-overlay {
  .sign-up-form-input-error {
    display: none;
  }

  .ng-invalid.ng-touched ~ .sign-up-form-input-error {
    display: block;
    color: $brand-error-on-dark-bg;
    font-size: 1.3rem;
    font-weight: 700;
    margin-top: 25px;
  }
}

.signup-form-input-select {
  width: 100% !important;
}

