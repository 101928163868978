// social sharing
.popover {
	&.social-sharing {
    color: #3c4042;
    background-color: white;
		.popover-inner {
      color: #3c4042;
      background-color: white;
			padding: 0 8px;
		}
		.arrow {
			border-bottom-color: white;
			&:after {
				border-bottom-color: white;
			}
		}
		.social-sharing-pop i {
			cursor:pointer;
		}
    .popover-content {
      padding: 0;
    }
	}
	i {
		line-height: 1;
		display: block;
		border-radius: 5px;
		padding: 6px;
		margin: 10px auto !important;
		font-size: 125%;
		&[class^='icon-'] {
			color: #ffffff;
			&:hover {
				color: #ffffff;
			}
		}
	}
	i.icon-twitter, i.icon-twitter-material {
		background-color: #42c6fc;
		border: 3px #ffffff00 solid;
		&:hover {
			background-color: #00abf1;
		}
		&:focus, &:active {
			outline: none;
			border: 3px $dark-blue solid;
		}
	}
	i.icon-linkedin, i.icon-linkedin-material {
		background-color: #32a5e2;
		border: 3px #ffffff00 solid;
		&:hover {
			background-color: #127cb4;
		}
		&:focus, &:active {
			outline: none;
			border: 3px $dark-blue solid;
		}		
	}
}
