@use "ng-falcon/setup";
@use '@angular/cdk' as cdk;

.cdk-overlay-container {
  z-index: cdk.$overlay-container-z-index;
}
.cdk-overlay-pane {
  position: absolute;
  z-index: 1;
}
