.comment-form {
  .CodeMirror, .CodeMirror-scroll {
    min-height:20rem !important;
  }
  .CodeMirror {
    height: 20rem;
  }
}

.form-group{
  .uedit{
    .CodeMirror-scroll {
      max-height: 300px;
    }
  }
}
.uedit {
  position: relative;
  .CodeMirror {
    border-color: #d2d6d9;
    font-family: $font-family-sans-serif;
    font-size: 1.6rem;
  }
  .CodeMirror-placeholder {
    color: #7b8a97;
  }
  .editor-toolbar {
    z-index:9;
    opacity:1;
    border-color: #d2d6d9;
    a {
      position: relative;
      color: #7b8a97 !important;
      @media(max-width: $screen-xs-max) {
        position: initial;
      }
    }
  }
}

.uedit.dropzone-active .dropzone {
  display:block;
}
.uedit.embedzone-active .embedzone {
  display:block;
}

.uedit-pick-emoji {
  margin:.1rem;
  padding:.2rem;
  &.active {
    border-radius:.2rem;
    border:.1rem solid #999;
  }
}

.codeMirror-toolbar-dropdown {
  font-family: $font-family-sans-serif;
  background:#edf0f2;
  box-shadow: .1rem .1rem .3rem #bac1c6;
  position: absolute;
  z-index:9;
  width:35rem;
  height:20rem;
  top:120%;
  display:none;
  .arrow {
    width: 0;
    height: 0;
    border-left: 0.8rem solid transparent;
    border-right: 0.8rem solid transparent;
    border-bottom: 0.8rem solid #edf0f2;
    position: absolute;
    bottom:100%;
    left:.5rem;
  }
  .close {
    padding: 0.3rem;
    opacity: 1;
    &:focus, &:hover, &:active {
      i {
        border: 1px dashed $black;
      }
    }
  }
  .editor-toolbar & {
    display:block;
  }
  @media(max-width: $screen-xs-max) {
    width:90%;
    left:5%;
    top:110%;
    height:18rem;
    .arrow {
      display:none;
    }
  }
}

.codeMirror-suggestion-box {
  box-shadow: .1rem .1rem .3rem #bac1c6;
  width:17rem;
  margin-left:-8.9rem;
  position:absolute;
  z-index: 99999;
  display:none;
  background:#edf0f2;
  ul {
    padding:.5rem;
    max-height:20rem;
    overflow:auto;
    &, li {
      margin:0;
    }
  }
  li {
    padding: .5rem;
    cursor:pointer;
    font-size: 1rem;
    white-space: nowrap;
    overflow:hidden;
    text-overflow:ellipsis;
    &:hover, &.active {
      background:#d2d6d9;
    }
    .profile-thumb {
      width:2rem;
      height:2rem;
      background-size: cover;
      vertical-align:middle;
      margin-right:.2rem;
    }
  }
  .arrow {
    width: 0;
    height: 0;
    border-left: 0.8rem solid transparent;
    border-right: 0.8rem solid transparent;
    border-bottom: 0.8rem solid #edf0f2;
    position: absolute;
    bottom:100%;
    left:50%;
    margin-left:-0.4rem;
  }
}

.dropzone {
  border:none;
  background:none;
  width:100%;
  height:100%;
  display: flex;
  justify-content: center;
}
.activation-btn {
  @extend %ntux-button;
}
.embed-toolbar {
  height:auto;
}

.embedzone {
  padding:2.7rem;
  label {
    float: left;
    color: $gray-dark;
  }
  input {
    display:block;
    font-size: 1.4rem;
    height:4.5rem;
    background:#fff;
    padding:1.5rem;
    position: relative;
    margin-bottom:1.5rem;
    -webkit-text-fill-color: $gray-dark;
  }
  button {
    padding:1rem;
    &.embed-button {
      @extend %ntux-button;
    }
  }
  @media (max-width: $screen-xs-max) {
    padding:1.5rem;
  }
}

/* Fullscreen fixes */
.CodeMirror-fullscreen, .editor-preview-side.editor-preview-active-side {
  z-index: 99997;
}
.form-group {
  .uedit {
    .CodeMirror-fullscreen {
      .CodeMirror-scroll {
        max-height: 100%;
        width: 88%;
        float: right;
      }
    }
  }
}
.editor-toolbar.fullscreen {
  z-index: 99998;
  text-align: center;
}
.editor-toolbar.fullscreen .codeMirror-toolbar-dropdown {
  position: fixed;
  top:4.2rem !important;
  z-index: 99999;
}
.editor-toolbar.disabled-for-preview a:not(.no-disable) {
  background:none;
  opacity:.3;
}

.uedit-markdown-guide {
  h4 {
    font-weight: 600;
    font-size: 1.5rem;
    color:$gray-light;
  }
  pre {
    background:$gray-base;
    color:$gray-lighter;
    border:0;
  }
  p {
    color:$gray-light;
    line-height: 1.25em;
    font-size: 1.4rem;
  }
  code {
    padding:.1rem .2rem;
    color:$gray-dark;
    line-height: 1.25em;
    font-size: 1.4rem;
  }
  .emojione-link {
    color: #35B4FD;
  }
}

@media(max-width: $screen-xs) {
  .editor-toolbar {
    text-align: center;
    i.separator {
      display:none;
    }
  }
}

.CodeMirror .cm-spell-error:not(.cm-url):not(.cm-comment):not(.cm-tag):not(.cm-word) {
  background:transparent;
  border-bottom:2px dotted $brand-danger;
}

.markdown-guide-container .modal-header button {
  &:focus, &:active {
    outline: 1px dashed #fff !important;
  }
}
