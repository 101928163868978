/*!
 * Copyright (C) 2023 to Present Applause App Quality, Inc. All rights reserved.
 */

@mixin invalid {
  @at-root .ngf-control-invalid & {
    @supports selector(:has(*)) {
      &:has(.ng-invalid) {
        @content
      }
    }

    @supports not selector(:has(*)) {
      // firefox still not supports ":has" selector
      // although it can be enabled by "layout.css.has-selector.enabled" flag
      // so in firefox we will fallback to marking all controls as invalid when at least one error is present
      // https://caniuse.com/?search=%3Ahas
      @content
    }
  }

  @at-root .ngf-control-invalid .ng-invalid & {
    @content
  }

  @at-root .ngf-control-group-invalid & {
    @content
  }
}
