.ntux-delete-btn {
  margin-left: .5rem;
  margin-right: .5rem;
  position: relative;

  @include bp-phone {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }

  .ntux-delete-btn__main, .ntux-delete-btn-confirmation__yes, .ntux-delete-btn-confirmation__no {
    min-width: 10rem;
    height: 3.9rem;
    padding: 1rem 2rem;
    text-transform: uppercase;
    letter-spacing: .1rem;
    vertical-align: middle;
    touch-action: manipulation;
    cursor: pointer;
    font-size: 1.2rem;
    font-weight: 600;
    display: inline-block;
    text-align: center;
    white-space: nowrap;
  }

  .ntux-delete-btn-confirmation__yes, .ntux-delete-btn-confirmation__no {
    margin-left: .5rem;
  }

  .ntux-delete-btn__main {
    background: #cf2626;
    border-radius: 4px;
    border: 1px solid #cf2626;
    color: #fff;
    display: block;

    transform: rotateX(0deg);
    transform-origin: left center;
    transition: transform .3s ease;

    &.ntux-delete-btn--activated {
      transform: translateY(-2.4rem) rotateX(90deg);
    }
  }

  .ntux-delete-btn-confirmation {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: absolute;
    top: 0;
    right: 0;
    text-align: right;
    transform: translateY(2.8rem) rotateX(-90deg);
    transform-origin: left center;
    transition: transform .3s ease;
    min-width: 400px;

    @media (max-width: $screen-xs) {
      min-width: auto;
    }

    &.ntux-delete-btn--activated {
      transform: rotateX(0deg);
    }
  }

  .ntux-delete-btn-confirmation__no {
    background: #ffffff;
    border-radius: 4px;
    border: 1px solid #000000;
    color: #000000;
  }

  .ntux-delete-btn-confirmation__yes {
    background: #cf2626;
    border-radius: 4px;
    border: 1px solid #cf2626;
    color: #fff;
  }

  .ntux-delete-btn-confirmation__label {
    margin: 0 .5rem;
  }
}