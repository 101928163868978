%campaign-card-shared {
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.25);
  margin-bottom: 12px;
  padding-left: 36px;
  padding-right: 36px;
  padding-top: 30px;
  padding-bottom: 24px;
}

%campaign-card-header {
  font-size: 16px;
  text-transform: uppercase;
}

.campaign {
  small {
    color: $gray-700;
    font-size: 14px;
    line-height: 20px;
  }

  .campaign-header {
    margin-top: 20px;
    font-size: 24px;
    font-weight: 600;
    padding-bottom: 24px;
  }

  .campaign-feed {
    padding-bottom: 16px;
  }

  .campaign-feed-item {
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.25);
    margin-bottom: 12px;
  }

  .campaign-feed-item-main {
    padding-left: 36px;
    padding-right: 36px;
    padding-top: 26px;
    padding-bottom: 18px;
  }

  .campaign-feed-item-header {
    padding-bottom: 15px;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .campaign-feed-item-header-info {
    width: 100%;
  }

  .campaign-feed-item-header__avatar {
    display: block;
    float: left;
    height: 36px;
    width: 36px;
    border-radius: 50%;
    margin-right: 16px;
    flex-shrink: 0;
  }

  .campaign-feed-item-header__avatar--glyph {
    color: $icon-color;
    background-color: $gray-lighter;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .campaign-feed-item-header-author {
    display: inline-block;
  }

  .campaign-feed-item-header__author {
    color: $black;
    font-size: 16px;
    line-height: 19px;
  }

  .campaign-feed-item-body {
    padding-bottom: 12px;
    margin-bottom: 12px;
  }

  .campaign-feed-item-body__title{
    font-size: 21px;
    font-weight: 600;
    line-height: 25px;
    margin-bottom: 15px;
    color: $black;
    padding-bottom: 12px;
  }

  .campaign-feed-item-body__content{
    font-size: 15px;
    color: $black;
    line-height: 22px;
    max-height: 44px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .campaign-feed-item-body__content--only{
    font-size: 21px;
    line-height: 25px;
    max-height: 50px;
  }

  .campaign-feed-item-footer {
    font-size: 16px;
    line-height: 18px;
    color: $gray-700;
    display: flex;
    align-items: stretch;
    a {
      color: inherit;
    }
    .campaign-feed-item-footer__text {
      position: relative;
    }
  }

  .campaign-feed-item__button {
    background-color: inherit;
    display: inline-flex;
    color: inherit;
    position: relative;
    border: none;
    padding-left: 0;
  }

  .campaign-feed-item-footer__text {
    text-align: left;
    position: absolute;
    padding-left: 5px;
    padding-top: 3px;
  }

  .campaign-detail {
    padding-top: 50px;
  }

  .campaign-detail-body__title {
    font-size: 24px;
    font-weight: 600;
    padding-bottom: 24px;
  }

  .campaign-detail-body__content {
    padding-bottom: 24px;
  }

  .campaign-detail-referral {

  }

  .campaign-detail-referral-cta {
    padding-bottom: 24px;
  }

  .campaign-detail-referral-widget {
    border: 1px $gray-light solid;
    background: $gray-shade-1;
    max-width: 500px;
    padding: 16px;
  }

  .campaign-detail-referral-widget__header {
    text-transform: uppercase;
  }

  .campaign-detail-referral-widget-link {
    display: flex;
    align-content: space-between;
  }

  .campaign-detail-referral-widget-link__url {
    max-width: 300px;
    margin-right: 10px;
    padding: 10px;
    border: 1px $gray-light solid;
    border-radius: 4px;
    background: $white;
    overflow-x: scroll;
    white-space: nowrap;
  }

  .campaign-detail-referral-widget-link__button {
    @extend %ntux-button;
    min-width: 150px;
    margin-top: 0;
  }

  @media screen and (max-width: 1244px) {
    .icon-toggle {
      display: none;
    }
  }
}
