.pdf-container{
  position: relative;
  margin: 0 auto;
  width: 100%;
  height: 600px;
  margin-bottom: 7rem;
  @include bp-xs{
    height: 350px;
  }
}
.pdf-viewer-container {
  text-align: center;
  background: #D3D3D3;
  overflow: auto;
  z-index: 1;
  .pdf-viewer-canvas-box{
    height: 600px;
    top: 32px;
    position: relative;
    .pdf-viewer-canvas {
      overflow: scroll;
      margin: 7px auto 7px auto;
      background-clip: content-box;
      background-color: white;
      box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.75);
      -webkit-box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.75);
      -moz-box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.75);
      -ms-box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.75);
      -o-box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.75);
    }
    @include bp-xs{
      height: 350px;
    }
  }
  .pdf-loading-overlay {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: 2;
    background: #000;
    opacity: .5;
    i {
      position: absolute;
      top: 40%;
      font-size: 40px;
      color: #fff;
    }
    span{
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;
      font-size: 18px;
      font-weight: 700;
      color: #ef5454;
    }
  }
  .bottom-bar {
    margin-top: 18px;
  }
  .title-bar {
    width: 100%;
    position: absolute;
    overflow: hidden;
    z-index: 2;
    background: #454545;
    -webkit-box-shadow: 0px 1px 3px rgba(50, 50, 50, 0.75);
    -moz-box-shadow: 0px 1px 3px rgba(50, 50, 50, 0.75);
    box-shadow: 0px 1px 3px rgba(50, 50, 50, 0.75);
    button{
      height: 100%;
      vertical-align: middle;
      padding-top: 5px;
      color: #fff;
    }
    .pdf-title{
      height: 40px;
      vertical-align: middle;
      padding-top: 10px;
      color: #fff;
      display: block;
    }
    .toolbarLabel {
      min-width: 16px;
      padding: 3px 6px 3px 2px;
      margin: 4px 2px 4px 0;
      border: 1px solid transparent;
      border-radius: 2px;
      color: hsl(0,0%,85%);
      font-size: 12px;
      line-height: 14px;
      text-align: left;
      -webkit-user-select: none;
      -moz-user-select: none;
      cursor: default;
    }
    .toolbarField {
      padding: 3px 6px;
      margin: 4px 0 4px 0;
      border-radius: 2px;
      background-color: hsla(0,0%,100%,.09);
      background-image: -moz-linear-gradient(hsla(0,0%,100%,.05), hsla(0,0%,100%,0));
      background-clip: padding-box;
      border: 1px solid hsla(0,0%,0%,.35);
      box-shadow: 0 1px 0 hsla(0,0%,0%,.05) inset, 0 1px 0 hsla(0,0%,100%,.05);
      color: hsl(0,0%,95%);
      font-size: 12px;
      line-height: 14px;
      outline-style: none;
      -moz-transition-property: background-color, border-color, box-shadow;
      -moz-transition-duration: 150ms;
      -moz-transition-timing-function: ease;
      &.pageNumber {
        min-width: 16px;
        text-align: right;
        width: 40px;
      }
      &:focus {
        background-color: hsla(0,0%,100%,.15);
        border-color: hsla(204,100%,65%,.8) hsla(204,100%,65%,.85) hsla(204,100%,65%,.9);
      }
    }
    .middle-tool-bar{
      position: absolute;
      left: 50%;
    }
    .page-data{
      @include bp-xs{
        display: none;
      }
    }
  }
  .toolbarButton {
    border: 0 none;
    background-color: rgba(0, 0, 0, 0);
    min-width: 32px;
    height: 25px;
    border-radius: 2px;
    background-image: none;
  }
  .toolbarButton, .dropdownToolbarButton {
    min-width: 16px;
    padding: 2px 6px 2px;
    border: 1px solid transparent;
    border-radius: 2px;
    color: hsl(0, 0%, 95%);
    font-size: 12px;
    line-height: 14px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    cursor: default;
    -webkit-transition-property: background-color, border-color, box-shadow;
    -webkit-transition-duration: 150ms;
    -webkit-transition-timing-function: ease;
    -moz-transition-property: background-color, border-color, box-shadow;
    -moz-transition-duration: 150ms;
    -moz-transition-timing-function: ease;
    -ms-transition-property: background-color, border-color, box-shadow;
    -ms-transition-duration: 150ms;
    -ms-transition-timing-function: ease;
    -o-transition-property: background-color, border-color, box-shadow;
    -o-transition-duration: 150ms;
    -o-transition-timing-function: ease;
    transition-property: background-color, border-color, box-shadow;
    transition-duration: 150ms;
    transition-timing-function: ease;
    &:hover{
      background-color: hsla(0,0%,0%,.12);
      background-image: linear-gradient(hsla(0,0%,100%,.05), hsla(0,0%,100%,0));
      background-image: -webkit-linear-gradient(hsla(0,0%,100%,.05), hsla(0,0%,100%,0));
      background-image: -moz-linear-gradient(hsla(0,0%,100%,.05), hsla(0,0%,100%,0));
      background-image: -ms-linear-gradient(hsla(0,0%,100%,.05), hsla(0,0%,100%,0));
      background-image: -o-linear-gradient(hsla(0,0%,100%,.05), hsla(0,0%,100%,0));
      background-clip: padding-box;
      border: 1px solid hsla(0,0%,0%,.35);
      border-color: hsla(0,0%,0%,.32) hsla(0,0%,0%,.38) hsla(0,0%,0%,.42);
      box-shadow: 0 1px 0 hsla(0,0%,100%,.05) inset, 0 0 1px hsla(0,0%,100%,.15) inset, 0 1px 0 hsla(0,0%,100%,.05);
    }
  }
  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
