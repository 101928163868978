.unauthenticated-container {
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  justify-content: space-between;

  .legal-container {
    background: #f3fafc;
    padding-top: 40px;
    padding-bottom: 40px;

    .container {
      background: white;
      padding: 10px 40px;
      border-radius: 5px;
    }

    ul {
      margin-left: 40px;
      margin-bottom: 10px;
    }
  }
}

.unauthenticated-nav-bar {
  padding: 0 75px;
  background-color: $white;

  @media(max-width: 991px) {
    // allow scrolling the header / nav dropdown menu
    position: relative;
    padding: 0 33px;
  }

  &__dropdown-toggle {
    float: right;
    margin-top: 18px;

    button {
      background: none;
      border: none;
      color: $gray-500;
    }

    i {
      vertical-align: middle;
      font-size: 32px;
    }
  }

  &__dropdown-menu {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    margin-top: 70px;
    text-align: center;
    background-color: $white;
    width: 100%;
    max-width: 462px;
    padding: 40px 18px;
    border-radius: 0 0 4px 4px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
    z-index: 100;

    list-style: none;

    &-link {
      color: $gray-base !important;
      margin-bottom: 32px;
      display: block;
    }

    &-sign-up {
      font-weight: 600;
      background-color: $applause-yellow;
      text-align: center;
      width: 75%;
      height: 34px;
      padding: 8px 24px !important;
      border-radius: 4px;
      color: $gray-base !important;

      &:hover {
        background-color: $applause-yellow !important;
        box-shadow: $blue 0px 0px 0 3px;
        text-decoration: none;
      }
    }

    &-divider {
      display: inline-block;
      width: 75%;
      background-color: $gray-light;
      height: 1px;
      margin-bottom: 24px;
    }
  }

  &__dropdown-menu--hide {
    display: none;
  }

  &__logo {
    width: 80px;
    padding: 21px 0;
  }

  &__logo-small {
    padding: 15px 0;
    width: 42px;
  }

  &__divider {
    display: inline-block;
    width: 1px;
    background-color: $gray-light;
    height: 40px;
    margin: 15px 0;
  }


  &__links {
    ul:first-child {
      li:nth-child(3) {
        a {
          padding-right: 24px;
        }
      }
    }
  }

  &__link {
    color: $gray-base !important;
    padding-right: 12px;
    padding-left: 12px;
    &:hover, &:focus {
      text-decoration: underline !important;
      outline-offset: -2px;
    }
  }

  &__link-text--selected {
    padding-bottom: 4px;
    border-bottom: solid 3px $brand-primary;
  }

  &__routed-link {
    display: block;
    padding-top: 25px;
    padding-bottom: 25px;
    &:hover, &:focus {
      text-decoration: underline !important;
    }
  }

  &__sign-up {
    font-weight: 600;
    background-color: $applause-yellow;
    text-align: center;
    width: 120px;
    height: 34px;
    margin: 17px 5px;
    padding: 8px 24px !important;
    border-radius: 4px;
    color: $gray-base !important;
    &:hover, &:focus {
      background-color: $applause-yellow !important;
      outline: none;
      box-shadow: $blue 0px 0px 0 3px;
      @include transition(all .3s ease);
    }
  }
}

.unauthenticated-nav-bar-new {
  padding: 40px 100px 16px 100px;
  font-size: 14px;
  font-family: "proxima-nova", sans-serif;
  background-color: var(--color-brand-cerulean-100);
  border-color: var(--color-brand-cerulean-100);

  @media (max-width: 585px) {
    &--hidden-mobile {
      display: none !important;
    }
    padding: 40px 20px 16px 20px;
  }
  @media (min-width: 586px) and (max-width: 900px) {
    &--hidden-tablet {
      display: none !important;
    }
    padding: 40px 50px 16px 50px;
  }
  @media (min-width: 901px) and (max-width: 1165px) {
    &--hidden-desktop-sm {
      display: none !important;
    }
    padding: 40px 50px 16px 50px;

  }
  @media (min-width: 1166px) {
    &--hidden-desktop-md {
      display: none !important;
    }
  }

  &__container {
    padding: 18px var(--spacing-spacing-06) 18px var(--spacing-spacing-06);
    background-color: $white;
    max-width: 1240px;
    height: 68px;
    align-self: stretch;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: var(--corner-radius-radius-round);
    box-shadow: var(--drop-shadow-extra-small);
    border: 1px solid;
    border-color: var(--color-brand-cerulean-200);
    margin: 0 auto;

    @media (max-width: 585px) {
      padding: 18px var(--spacing-spacing-05) 18px var(--spacing-spacing-05);
    }
  }

  &__dropdown-toggle {
    float: right;

    button {
      background-color: $white;
      border: none;
      color: $gray-500;
      width: 30px;
      height: 30px;
    }

    i {
      vertical-align: middle;
      font-size: 32px;
    }

    &--open {
      background-image: url("https://s3.us-east-1.amazonaws.com/utest.com/assets/img/open.svg");
      border: #8f6e00;
    }

    &--close {
      background-image: url("https://s3.us-east-1.amazonaws.com/utest.com/assets/img/close.svg");
    }
  }

  &__dropdown-container {
    position: absolute;
    z-index: 1001;
    width: 100%;
    margin-top: -6px;
    font-family: 'proxima-nova', sans-serif;

    @media (max-width: 585px) {
      padding: 0px 20px 0 20px;
    }
    @media (min-width: 586px) and (max-width: 900px) {
      padding: 0 50px 0 50px;
    }
  }

  &__dropdown-menu {
    text-align: left;
    border-radius: var(--corner-radius-radius-big);
    border: 1px solid;
    border-color: var(--color-brand-cerulean-200);
    padding: 18px var(--spacing-spacing-05) 18px var(--spacing-spacing-05);
    align-self: stretch;
    width: 100%;
    background-color: var(--color-gray-white);
    box-shadow: var(--drop-shadow-extra-small);

    list-style: none;

    &-link {
      color: var(--color-gray-black);
      height: 50px;
      display: flex;
      align-items: center;
      font-size: var(--button-medium-font-size);
      justify-content: space-between;
      gap: 8px;

      &:hover, &:focus {
        text-decoration: none !important;
        color: var(--color-gray-black);
      }

      &:hover {
        border-bottom: 3px solid #00a8cc;
      }
    }

    &-log-in {
      display: flex;
      background-color: var(--color-gray-200);
      align-items: center;
      justify-content: center;
      height: 31px;
      padding: 7px var(--spacing-spacing-04) 7px var(--spacing-spacing-04) !important;
      border-radius: var(--corner-radius-radius-round);
      color: $gray-base !important;
      margin-top: 10px;
      margin-bottom: 5px;
      font-size: 12.5px;

      &:hover, &:focus {
        text-decoration: none;
      }

      &:hover {
        background-color: var(--color-gray-200) !important;
        box-shadow: 0 2px 0 0 #949494, 0 -2px 0 0 #949494 inset;
      }
    }

    &-sign-up {
      display: flex;
      background-color: var(--color-status-yellow-500);
      align-items: center;
      justify-content: center;
      height: 31px;
      padding: 7px var(--spacing-spacing-04) 7px var(--spacing-spacing-04) !important;
      border-radius: var(--corner-radius-radius-round);
      color: $gray-base !important;
      margin-top: 10px;
      font-size: 12.5px;

      &:hover, &:focus {
        text-decoration: none;
      }

      &:hover {
        box-shadow: 0 2px 0 0 #8f6e00, 0 -2px 0 0 #8f6e00 inset;
      }
    }

    &-divider {
      display: inline-block;
      width: 75%;
      background-color: $gray-light;
      height: 1px;
      margin-bottom: 24px;
    }
  }

  &__dropdown-menu--hide {
    display: none;
  }

  &__logo {
    padding: 28px 0 21px 0;
    height: 90px;
  }

  &__logo-small {
    padding: 15px 0;
    width: 42px;
  }

  &__divider {
    display: inline-block;
    width: 1px;
    background-color: $gray-light;
    height: 40px;
    margin: 15px 0;
  }

  &__link {
    color: $gray-base !important;
    padding-right: 12px;
    padding-left: 12px;
    &:hover, &:focus {
      text-decoration: none;
    }
  }

  &__link-text--selected {
    padding-bottom: 4px;
    border-bottom: solid 3px $brand-primary;
  }

  &__link-text {
    &:hover, &:focus {
      padding-bottom: 4px;
      border-bottom: 3px solid #00a8cc;
    }
  }

  &__routed-link {
    display: block;
    padding-top: 25px;
    padding-bottom: 25px;
  }

  &__right-group {
    display: inline-flex;
    align-items: center;
    gap: var(--spacing-spacing-03);
    position: relative;
    font-size: 12.5px;
  }

  &__authentication {
    display: flex;
    position: relative;
    font-size: 12.5px;
  }

  &__log-in {
    color: $gray-base !important;
    background-color: var(--color-gray-200);
    width: 84px;
    align-items: center;
    text-align: center;
    gap: 10px;
    border-radius: var(--corner-radius-radius-round);
    margin-right: 8px;
    display: flex;
    justify-content: center;
    line-height: 1;

    &:hover {
      border-radius: 9999px;
      background-color: var(--color-gray-200) !important;
      box-shadow: 0 2px 0 0 #949494, 0 -2px 0 0 #949494 inset;
    }
  }

  &__sign-up {
    background-color: var(--color-status-yellow-500);
    width: 84px;
    align-items: center;
    text-align: center;
    gap: 10px;
    border-radius: var(--corner-radius-radius-round);
    color: $gray-base !important;
    display: flex;
    line-height: 1;

    &:hover {
      border-radius: 9999px;
      background: var(--color-status-yellow-500) !important;
      box-shadow: 0 2px 0 0 #8f6e00, 0 -2px 0 0 #8f6e00 inset;
    }

    &:focus {
      border-radius: 9999px;
      background: var(--color-status-yellow-500) !important;
    }
  }
}

.unauthenticated-footer {
  @media(max-width: 991px) {
    text-align: center;
  }
  background-color: $gray-lightest;

  &__logo {
    width: 48px;
    padding: 0;
    margin: 0 auto 16px auto;
  }

  &__ctas {
    padding: 48px 74px;
  }

  &__cta-heading {
    font-size: 20px;
    font-weight: bold;
    line-height: 23px;
    @media(max-width: 991px) {
      margin-bottom: 8px;
    }
  }

  &__cta-subheading {
    font-size: 14px;
    color: #657481;
    hyphens: none;
  }

  &__cta-link {
    color: #0277BB;
    &:hover, &:focus {
      color: #0277BB;
    }
  }

  &__nav {
    background-color: $gray-dark;
    color: $gray-light;
    padding: 24px 74px;
    font-size: 12px;
  }

  &__nav-items {
    list-style: none;
  }

  &__nav-item {
    display: inline-block;
    margin-right: 16px;
    a {
      color: $gray-light;
    }
  }

  &__nav-item--onetrust {
    display: inline-block;
    margin-right: 16px;
    button {
      color: $gray-light !important;
      border: none !important;
      font-size: 12px !important;
      background-color: transparent !important;
      padding: inherit !important;

      &:hover, &:active, &:focus {
        text-decoration: underline !important;
      }
    }
  }

  &__apps {
    float: right;

    @media(max-width: 991px) {
      float: none;
    }
  }

  &__apps-text {
    font-size: 14px;
    font-weight: bold;
    line-height: 16px;
    letter-spacing: 0.2px;
    @media(max-width: 991px) {
      margin-bottom: 16px;
    }
  }

  &__app-icons {
    display: inline;
    text-align: right;
    padding-right: 8px;
    a {
      &:hover, &:focus {
        text-decoration: none;
        outline: none;
        img {
          outline: 2px auto;
        }
      }
    }
    @media(max-width: 991px) {
      text-align: center;
    }
      @media(max-width: 437px) {
      display: block;
      padding-right: 15px;

      img {
        margin-bottom: 8px;
      }
    }
    img {
      image-rendering: optimizequality;
      image-rendering: -webkit-optimize-contrast;
      height: 32px;
    }
  }

  &__social {
    text-align: right;
    margin-bottom: 48px;
    a {
      padding: 0 8px;
      &:hover, &:focus {
        text-decoration: none;
        outline: none;
        img {
          box-shadow: #009ABD 0px 0px 0 3px;
          border-radius: 50%;
        }
      }
    }

    @media(max-width: 991px) {
      text-align: center;
      margin: 36px 0;
    }
  }

  &__copyright {
    text-align: right;

    @media(max-width: 991px) {
      text-align: center;
    }
  }
}

.unauthenticated-footer-new {
  background-color: var(--color-gray-black);

  &__container {
    max-width: 1440px;
    width: 100%;
    margin: 0 auto;
    padding: 80px 100px 80px 100px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    flex-direction: column;
    gap: var(--spacing-spacing-06);

    @media (max-width: 585px) {
      padding: 40px 20px 40px 20px;
    }
    @media (min-width: 586px) and (max-width: 900px) {
      padding: 56px 32px 56px 32px;
    }
    @media (min-width: 901px) and (max-width: 1165px) {
      padding: 72px 50px 72px 50px;
    }
  }

  &__top {
    padding-top: 3px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    margin-bottom: 4px;

    @media (max-width: 585px) {
      margin-bottom: 0px;
      flex-direction: column;
      align-items: flex-start;
    }

    @media (min-width: 586px) and (max-width: 900px) {
      flex-direction: column;
      align-items: flex-start;
      gap: var(--spacing-spacing-06);
      margin-bottom: 0px;
    }
  }

  &__logo {
    width: 157px;
    height: 56px;

    @media (max-width: 585px) {
      margin-bottom: 32px;
    }
  }

  &__nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;


  }

  &__nav-items {
    list-style: none;
    display: inline-flex;
    align-items: center;
    justify-content: flex-end;
    gap: var(--spacing-spacing-04);
    position: relative;
    align-self: stretch;

    @media (max-width: 585px) {
      align-items: flex-start;
      justify-content: flex-start;
      flex-wrap: wrap;
      width: fit-content;
    }

    @media (min-width: 586px) and (max-width: 900px) {
      align-items: flex-start;
      justify-content: flex-start;
      flex-wrap: wrap;
      width: fit-content;
    }
  }

  &__nav-item {
    display: inline-block;
    font-family: var(--body-extra-small-font-family);
    font-weight: var(--body-extra-small-font-weight);
    color: var(--color-gray-white);
    font-size: var(--body-extra-small-font-size);
    letter-spacing: var(--body-extra-small-letter-spacing);
    line-height: var(--body-extra-small-line-height);
    font-style: var(--body-extra-small-font-style);
    position: relative;
    a {
      text-decoration: underline;
      white-space: nowrap;
      color: var(--color-gray-white);
    }
  }

  &__nav-item--onetrust {
    display: inline-block;
    button {
      color: var(--color-gray-white) !important;
      border: none !important;
      font-size: 12px !important;
      background-color: transparent !important;
      padding: inherit !important;
      font-family: 'proxima-nova', sans-serif;
      text-decoration: underline;

      &:hover, &:active, &:focus {
        text-decoration: underline !important;
      }
    }
  }

  &__social {
    display: inline-flex;
    justify-content: flex-end;
    gap: var(--spacing-spacing-08);
    padding: 12px var(--spacing-spacing-06) 12px var(--spacing-spacing-06);
    background-color: var(--color-gray-800);
    align-items: center;
    border-radius: var(--corner-radius-radius-round);

    @media (max-width: 585px) {
      padding: 12px var(--spacing-spacing-05) 12px var(--spacing-spacing-05);
    }

    &-cta {
      position: relative;
      width: fit-content;
      font-family: var(--button-medium-font-family);
      font-weight: var(--button-medium-font-weight);
      color: var(--color-gray-white);
      font-size: var(--button-medium-font-size);
      letter-spacing: var(--button-medium-letter-spacing);
      line-height: var(--button-medium-line-height);
      white-space: nowrap;
      font-style: var(--button-medium-font-style);
    }

    &-links {
      gap: var(--spacing-spacing-03);
      display: inline-flex;
    }

    &-link {
      display: inline-flex;
      padding: 8px;
      justify-content: center;
      align-items: center;
      border-radius: 8px;
      background: #3d3d3d;
      width: 32px;
      height: 32px;

      &:hover, &:focus {
        background: #6b6b6b;
      }
    }
  }

  &__copyright {
    width: fit-content;
    font-family: var(--body-extra-small-font-family);
    font-weight: var(--body-extra-small-font-weight);
    color: var(--color-gray-white);
    font-size: var(--body-extra-small-font-size);
    letter-spacing: var(--body-extra-small-letter-spacing);
    line-height: var(--body-extra-small-line-height);
    white-space: nowrap;
    font-style: var(--body-extra-small-font-style);
    position: relative;
  }
}

// Wistia embeded videos need css here instead of in a component's css (doesn't work)

.wistia_embed {
  .wistia_click_to_play {
    margin: auto;
  }
  @media(max-width: 991px) {
    height: 300px;
    width: 519px;
  }
  @media(max-width: 769px) {
    height: 159px;
    width: 287px;
  }
  @media(min-width: 992px) {
    height: 330px;
    width: 577px;
  }
}

.how-utest-works-video {
  margin-top: 10px;
  .wistia_embed {
    @media(max-width: 991px) {
      height: 320px;
      width: 577px;
    }
    @media(max-width: 768px) {
      height: 200px;
      width: 360px;
    }
    @media(min-width: 992px) {
      height: 400px;
      width: 711px;
    }
  }
}

.wistia_placebo_close_button {
  img {
    max-width: initial !important;
  }
}

:root {
  --button-medium-font-family: "proxima-nova", Helvetica;
  --button-medium-font-weight: 600;
  --button-medium-font-size: 16px;
  --button-medium-letter-spacing: 0.16px;
  --button-medium-line-height: 150%;
  --button-medium-font-style: normal;
  --body-extra-small-font-family: "proxima-nova", Helvetica;
  --body-extra-small-font-weight: 400;
  --body-extra-small-font-size: 12px;
  --body-extra-small-letter-spacing: 0.24px;
  --body-extra-small-line-height: 154.99999523162842%;
  --body-extra-small-font-style: normal;
  --drop-shadow-extra-small: 0px 2px 4px 0px rgba(0, 0, 0, 0.08);
  --color-gray-800: rgba(27, 27, 27, 1);
  --color-gray-black: rgba(0, 0, 0, 1);
  --color-gray-white: rgba(255, 255, 255, 1);
  --color-brand-cerulean-200: rgba(217, 242, 247, 1);
  --color-brand-cerulean-300: rgba(153, 220, 235, 1);
  --color-brand-cerulean-800: rgba(0, 67, 82, 1);
  --color-gray-200: rgba(240, 240, 240, 1);
  --color-status-yellow-500: rgba(255, 196, 0, 1);
  --color-brand-cerulean-100: rgba(242, 251, 252, 1);
  --corner-radius-radius-round: 9999px;
  --corner-radius-radius-big: 32px;
  --spacing-spacing-03: 8px;
  --spacing-spacing-06: 32px;
  --spacing-spacing-04: 16px;
  --spacing-spacing-05: 24px;
  --spacing-spacing-08: 48px;
}

.unauthenticated-resource {
  background: linear-gradient(180deg, rgb(242, 251, 252) 0%, rgb(255, 255, 255) 11.5%);
  background-color: var(--color-brand-cerulean-100);

  &__container {
    padding: 40px 50px 72px 50px;
    @media (max-width: 585px) {
      padding: 32px 20px 40px 20px;
    }
    @media (min-width: 586px) and (max-width: 900px) {
      padding: 32px 32px 56px 32px;
    }
  }

  &__title {
    font-size: 48px;
    font-weight: 800;
    text-align: center;
    margin-bottom: 40px;

    @media (min-width: 586px) and (max-width: 900px) {
      margin-bottom: 32px;
    }

    @media (max-width: 585px) {
      margin-bottom: 24px;
    }
  }

  &__body {
    margin: 0 auto;
    max-width: 700px;

    p, p strong, p span, b, p em, a, li, li em, i, u {
      font-size:1.8rem;
      line-height: 1.6;
    }
    h2:not(.graf), h3:not(.graf), h4:not(.graf) {
      color: #262626;
    }
    p, ul, ol {
      padding: 1.3rem 0rem;
      margin-bottom: 0;
      margin-left:0;
      @include bp-phone{
        padding: 1.3rem 2rem;
      }
    }
    ul, ol {
      margin-left: 3.5rem;
    }
    blockquote {
      margin: 3rem 0 5rem 0;
      font-size: 1.6rem;
      line-height: 1.8;
      padding: 0 0 0 1rem;
    }
  }
}
