.light-modal {

  background: rgba(60, 64, 67, .8);
  text-align: center;

  .modal-content {
    background: rgb(255,255,255);
    text-align: left;
  }

  .modal-dialog {
    display: inline-block;
    width: auto;
  }

  .close {
    position: absolute;
    top: 3rem;
    right: 3rem;
    color: #000;
    z-index: 1;
  }

  a {
    color: #000;
    text-decoration: underline;
    font-weight: bold;
  }

}
