.tag {
  min-width: 5rem;
  width: auto;
  height: 2.4rem;
  text-align: center;
  display: inline-block;
  padding: .2rem 1rem;
  border-radius: 30px;
  border: 1px solid;
  color: #7b8a97;
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 1.5;
  text-transform: capitalize;
  letter-spacing: 0rem;
  opacity: 1;
  z-index: 1;
  margin-right: .5rem;
}
