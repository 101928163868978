//avatar, section icons, profile badges, utest logo thumb

.profile-thumb, .icon-thumb {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  display:inline-block;
  margin: 0;
  position: relative;
  z-index: 1;
}
.profile-thumb {
  &.large {
    width: 50px;
    height: 50px;
  }
  &.xlarge {
    width: 92px;
    height: 92px;
    border: 2px #fff solid;
    margin:0 35px;
  }
  &.xxlarge {
    width: 120px;
    height: 120px;
  }
}

.profile-thumb-edit-overlay {
  cursor: pointer;
  width: 100%;
  height: 100%;
  opacity: 0;
  @include transition(.3s all ease-in-out);
  &:hover {
    opacity: 1;
  }
  &:before {
    content:'';
    background: rgba(0,0,0,.5);
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 2px solid #fff;
    position: absolute;
    z-index: 10;
  }
  &:after {
    content: 'Edit Avatar (300x300)';
    font-size: 12px;
    font-weight: 700;
    color: #fff;
    z-index: 11;
    position: relative;
    top: 27.5%;
  }
}

.icon-thumb {
  font-size: 16px;
  border:1px #000 solid;
  text-align:center;
  padding: 3px 1px 0 1px;
  vertical-align:text-bottom;
  top:-1px;
}
.profile-thumb-badge{
  width:19px;
  height:19px;
  border-radius: 50%;
  display: block;
  font-size:12px;
  font-weight: 800;
  float: right;
  margin-top: 10px;
  padding: 1px 0 0 1px;
  &.employee {
    background: #00a8cc;
    color:#fff;
  }
  &.moderator {
    background: #fdb900;
    color:#000;
  }
}
.profile-thumb-logo{
  @extend .profile-thumb;
  padding-left:8px;
  font-size:25px;
  line-height: 1.4;
  font-weight: 800;
  background: #00a8cc;
  color:#fff;
  vertical-align: super;
}
.msg-list {
  .profile-thumb {
    margin: 0 7.5px 0 0;
  }
}

[avatar-badges] {
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border:2px solid #fff;
  box-sizing: content-box;
}

button[avatar-badges] {
  background-color:transparent;
  padding:0;
}

[ntux-avatar-badges] {
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  box-sizing: content-box;
}

.avatar-badges-wrapper {
  position: absolute;
  top:3px;
  right:0;
  text-align: right;
  font-size:0;
  transform:translateX(25%);
}

.user-tooltip .user-tooltip-inner .user-tooltip-inner-1 .avatar-badge,
.avatar-badge {
  border-radius: 50%;
  width:16px;
  height:16px;
  border:2px solid #ffffff;
  color:#ffffff;
  right:0;
  font-size:10px;
  display:inline-block;
  text-align: center;
  margin:0 1px;
  line-height:12px;
  &-u {
    background:#00a8cc;
    &::after {
      content: 'U';
      font-weight: bold;
    }
  }
  &-m {
    background:#fdb900;
    color:#262626;
    &::after {
      content: 'M';
      font-weight: bold;
    }
  }
  &-pm {
    background:#22c064;
    &::after {
      content: 'PM';
      font-size:7px;
      vertical-align: middle;
      font-weight: bold;
    }
  }
  &-ttl {
    background:#fa8513;
     &::after {
      content: 'TTL';
      font-size:7px;
      vertical-align: middle;
      font-weight: bold;
    }
  }
  &-check {
    color:white;
    //font-size: 1.7rem;\
    background:$brand-success;
    border-color:$brand-success;
    i {
      margin-left:-1px;
    }
  }
  &-a {
    background:#05A88B;
     &::after {
      content: 'A';
      font-weight: bold;
    }
  }
}

.feed-header-info .profile-thumb,
.section-single-comment-content .feed-header-info-icon a,
.feed-item-header .profile-thumb {
  .avatar-badges-wrapper {
    line-height: 0;
  }
  .avatar-badge {
    width:11px;
    height:11px;
    margin:0;
    &::after {
      content: '';
    }
  }
}

.section-single-comment-content .feed-header-info-icon a {
  .avatar-badges-wrapper {
    top:0;
  }
}

.ut-navbar-easy-write .profile-thumb,
.easy-write-content > div > .profile-thumb,
.easy-write > div > .profile-thumb {
  box-sizing:border-box;
  .avatar-badges-wrapper {
    line-height: 0;
  }
  .avatar-badge {
    width:13px;
    height:13px;
    margin:0;
    &::after {
      content: '';
    }
  }
}
