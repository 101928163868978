.section-submit-cover {
  padding-bottom: 3rem;

  .section-heading {
    font-size:3rem;
  }
  .section-desc {
    font-style:italic;
    width: 80%;
  }
  .func-btns {
    line-height: 2rem;
    @include bp-phone {
      width: 100%;
      text-align: center;
      margin: 2rem 0 0 0;
    }
    a {
      line-height: 2rem;
    }
  }
  .feed-content {
    @include bp-phone {
      text-align: center;
      h2 {
        margin-bottom: .5rem;
      }
    }
  }
  .feed-content-footer span {
    @include bp-phone {
      text-align: center;
      width: 100%;
    }
  }
}
.section-submit-body-content {
  border-top: 1px #ccc solid;
  padding-top: 3rem;
  text-align: center;
  .c4, .c6 {
    text-align: left;
    @include bp-tablet {
      padding: 1rem 1rem 0 0;
      &:first-child {
        padding-left:0;
      }
    }
  }
  .c4:nth-child(3) {
    padding-right:0;
  }
  #summary {
    height: 8rem;
  }
  #description {
    height: 14rem;
  }
  .date-picker-input {
    height: auto;
    input {
      padding: 1rem;
    }
    .icon-events {
      font-size: 1.8rem;
      top: .5rem;
    }
  }
  .c6 {
    .btn {
      width: 100%;
      text-transform: none;
    }
    .btn-desc {
      font-size: 1.2rem;
      width: 90%;
      text-align: center;
      margin: 1rem auto;
    }
    @include bp-tablet {
      &:first-child {
        padding-right:1rem;
      }
      &:nth-child(2n) {
        padding-left:1rem;
        padding-right:0rem;
      }
    }
  }
  label {
    font-size: 1.2rem;
    color: #262626;
  }
  .map-container {
    position:relative;
    input {
      position: absolute;
      top:0;
      left:0;
      background: rgba(255,255,255, 0.8)
    }
    .angular-google-map-container {
      margin: 0;
      height: 30rem;
      .gmnoprint {
        display: none;
      }
    }
  }
}
form input[type="submit"] {
  margin-top: 3rem;
}