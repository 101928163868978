//mixin for transition
@mixin transition($args...) {
  -webkit-transition: $args;
  -moz-transition: $args;
  -ms-transition: $args;
  -o-transition: $args;
  transition: $args;
}

//mixin transform
@mixin transform($args...) {
  -webkit-transform: $args;
  -moz-transform: $args;
  -o-transform: $args;
  transform: $args;
}

//mixin transform-origin
@mixin transform-origin ($origin) {
  moz-transform-origin: $origin;
  -o-transform-origin: $origin;
  -ms-transform-origin: $origin;
  -webkit-transform-origin: $origin;
  transform-origin: $origin;
}

//mixin animation
@mixin keyframes($animation-name) {
  @-webkit-keyframes #{$animation-name} {
    @content;
  }
  @-moz-keyframes #{$animation-name} {
    @content;
  }
  @-ms-keyframes #{$animation-name} {
    @content;
  }
  @-o-keyframes #{$animation-name} {
    @content;
  }
  @keyframes #{$animation-name} {
    @content;
  }
 }

@mixin animation($str) {
  -webkit-animation: #{$str};
  -moz-animation: #{$str};
  -ms-animation: #{$str};
  -o-animation: #{$str};
  animation: #{$str};
}

//mixin for media query
@mixin bp-largeScreen {
  @media only screen and (min-width: 1240px) {
    @content;
  }
}

@mixin bp-desktop {
  @media only screen and (min-width: 992px) {
    @content;
  }
}

@mixin bp-mobile {
  @media only screen and (max-width: 1024px) {
    @content;
  }
}

@mixin bp-tablet {
  @media only screen and (min-width: 768px) {
    @content;
  }
}

@mixin bp-phone {
  @media only screen and (max-width: 768px) {
    @content;
  }
}

@mixin bp-thin {
  @media only screen and (max-width: 390px) {
    @content;
  }
}

@mixin bp-xs {
  @media only screen and (max-width: $screen-xs) {
    @content;
  }
}
