//number of item in circle style
.circ-item-num {
  height:2.2rem;
  width:2.2rem;
  line-height: 1.9rem;
  border-radius: 50%;
  border: 1px #262626 solid;
  position:relative;
  vertical-align: text-bottom;
  color:#262626;
  display:inline-block;
  &:after {
    content: attr(data-num);
    font-size: 1.2rem;
    font-weight:600;
    position:absolute;
    text-align: center;
    width:100%;
    height:100%;
  }
  &.right {
    float:right;
  }
  &.greenBg {
    background:rgba(34,192,100, 1) !important;
    color:#fff;
    border:none;
    height: 2.1rem;
    width: 2.1rem;
    line-height:2.1rem;
  }
}