/*!
 * Copyright (C) 2023 to Present Applause App Quality, Inc. All rights reserved.
 */

// starting from Angular 14
// <mat-icon inline> alignment with text is broken
// to fix it we have to override @angular/material native class
.mat-icon-inline {
  vertical-align: top;
  height: unset !important;
}
