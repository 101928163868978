.project-filter-container {
  display: flex;
  justify-content: flex-end;
}

@media (max-width: 615px) {
  .project-filter-container {
    flex-direction: column; /* Stack filters vertically */
  }
}
