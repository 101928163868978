/*!
 * Copyright (C) 2023 to Present Applause App Quality, Inc. All rights reserved.
 */

@use "sass:list";

$duration--short: 100ms;
$duration--medium: 300ms;
$duration--long: 500ms;
$duration--extra-long: 700ms;

$easing--linear: cubic-bezier(0, 0, 1, 1);
$easing--standard: cubic-bezier(0.2, 0, 0, 1);
$easing--accelerate: cubic-bezier(0.3, 0, 1, 1);
$easing--decelerate: cubic-bezier(0, 0, 0, 1);
$easing--emphasized: cubic-bezier(0.2, 0, 0, 1);
$easing--emphasized-decelerate: cubic-bezier(0.05, 0.7, 0.1, 1);
$easing--emphasized-accelerate: cubic-bezier(0.3, 0, 0.8, 0.15);

@mixin of($properties, $duration, $easing) {
  $transition: ();
  @each $property in $properties {
    $transition: list.append($transition, ($property $duration $easing), $separator: comma);
  };

  transition: $transition;
}

@mixin short-linear($properties...) {
  @include of($properties, $duration--short, $easing--linear);
}

@mixin short-emphasized($properties...) {
  @include of($properties, $duration--short, $easing--emphasized);
}

@mixin medium($properties...) {
  @include of($properties, $duration--medium, $easing--standard);
}
