%community-card-shared {
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.25);
  margin-bottom: 12px;
  padding-left: 36px;
  padding-right: 36px;
  padding-top: 30px;
  padding-bottom: 24px;
}

%community-card-header {
  font-size: 16px;
  text-transform: uppercase;
}

%community-modal {
  .modal-content {
    background: $white;
    color: $gray-base;
    font-size: 16px;
    text-align: center;
    .icon-cancel {
      color: $gray-base;
      font-size: 1.8rem;
    }
  }
  .modal-header {
    padding: 0 0 14px 0;
    .modal-title {
      color: $gray-base;
      text-align: left;
      padding: 2rem 3rem 1rem 3rem;
    }
  }

  .modal-body {
    padding: 0 28px 28px 28px;
  }

  .community-cta__button {
    @extend %ntux-button;
    display: inline-block;
  }
  .community-cta__button.unfilled {
    @extend %ntux-hollow-button-on-white;
    display: inline-block;
  }
}

.community {
  small {
    color: $gray-700;
    font-size: 14px;
    line-height: 20px;
  }

  .community-header {
    margin-top: 20px;
    font-size: 24px;
    font-weight: 600;
    padding-bottom: 24px;
  }

  .community-featured {
    @extend %community-card-shared;
    padding-left: 6px;
    padding-right: 6px;
    position: relative;
  }

  .community-featured-fade {
    content:'';
    top: 78px;
    bottom: 30px;
    width: 60px;
    position: absolute;
    pointer-events: none;
  }

  .community-featured-fade--left {
    left: 0;
    background: linear-gradient(270deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.8) 100%);
  }

  .community-featured-fade--right {
    right: 0;
    background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.8) 100%);
  }

  .community-featured-header {
    @extend %community-card-header;
    padding-bottom: 24px;
    padding-left: 30px;
    font-weight: inherit;
    line-height: inherit;
    margin: auto;
  }

  .community-featured-list {
    display: flex;
    align-items: stretch;
    overflow-x: hidden;
    scroll-behavior: smooth;
    min-height: 140px;
  }

  .community-featured-list-item-stats {
    display: block;
  }

  .community-featured-list-scroll {
    position: absolute;
  }

  .community-featured-list-scroll--left {
    top: 101px;
    left: -19px;
  }

  .community-featured-list-scroll--right {
    top: 101px;
    right: -19px;
  }

  .community-featured-list-scroll__button {
    position: relative;
    background-color: $blue;
    height: 38px;
    width: 38px;
    color: #fff;
    border-radius: 50%;
    border: none;
    box-shadow: 0 1px 4px 0 rgba(0,0,0,0.2);

    &:hover, &:focus {
      background-color: $dark-blue;
      i {
        background: none;
      }
    }
  }

  .community-featured-list-scroll__button--disabled {
    background-color: #eee;
    border: 1px solid #c8c8c8;
    color: $gray-700;
    pointer-events: none;
    &:hover, &:focus {
      background-color: #eee;
    }
  }

  .community-featured-list-scroll__arrow {
    position: absolute;
    top: 0;
    left: 0;
    padding-top: 6px;
  }

  .community-featured-list-scroll__arrow--left {
    padding-left: 5px;
  }

  .community-featured-list-scroll__arrow--right {
    padding-left: 7px;
  }

  .community-featured-list-item-skeleton {
    min-width: 260px;
    height: 139px;
    padding-left: 30px;
    padding-right: 30px;
    border-right: 1px solid #F3F4F5;
    background-repeat: no-repeat;
    background-size: 170px 75px,
      98px 24px,
      128px 16px,
      100% 100%;
    background-position:
      30px 0,
      30px 99px,
      30px 122px,
      30px 0;
    background-image: linear-gradient(#F3F4F5 75px, transparent 0),
      linear-gradient(#F3F4F5 20px, transparent 0),
      linear-gradient(#F3F4F5 16px, transparent 0),
      linear-gradient(white 100%, transparent 0);
  }

  .community-featured-list-item {
    padding-left: 30px;
    padding-right: 30px;
    border-right: 1px solid #F3F4F5;
  }

  .community-featured-list-item__title {
    font-size: 21px;
    font-weight: 600;
    line-height: 25px;
    height: 75px;
    margin-bottom: 25px;
    width: 200px;
    overflow: hidden;

    a {
      color: #262626;

      &:hover {
        color: #262626;
      }
    }
  }

  .community-featured-list-item__author {
    font-size: 16px;
    line-height: 18px;

    a {
      color: #262626;

      &:hover {
        color: #262626;
      }
    }
  }

  .community-light-tester-upgrade {
    @extend %community-card-shared;
    display: flex;
    justify-content: center;
    border-left: 8px solid #0272B4;
    border-radius: 4px;
    padding: 20px 24px 20px 24px;
    gap: 12px;
    @media screen and (max-width: 574px) {
      flex-direction: column;
    }
  }

  .community-light-tester-upgrade__cta {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .community-light-tester-upgrade__button {
    margin: 0;
    @extend %ntux-button;
    @extend %ntux-button-small;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    text-align: center;
    white-space: nowrap;
  }

  .community-light-tester-upgrade__title {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
  }

  .community-status-update-fullscreen {
    z-index: 2000;
    position: fixed;
    top: 70px;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #F3F4F5;
    padding: 16px;
  }

  .community-status-update-fullscreen__character-limit {
    color: #666666;
    font-size: 12px;
  }

  .community-status-update-fullscreen__submit, .community-feed-item-comment-form-fullscreen__submit {
    font-size: 16px;
    border-radius: 100px;
    padding: 10px 24px;
    float: right;
    margin-bottom: 16px;
    font-weight: 600;
    @extend %ntux-button;
  }

  .community-status-update-fullscreen__cancel, .community-feed-item-comment-form-fullscreen__cancel {
    font-size: 16px;
    padding: 10px 0;
    margin-bottom: 16px;
    @extend %ntux-button;
    @extend %ntux-hollow-button-on-white;
  }

  .community-status-update-fullscreen__form {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    background-color: #fff;
    margin-bottom: 16px;
  }

  .community-status-update-fullscreen__input {
    border: none;
    background: none;
    resize: none;
    height: 200px;
    display: block;

    &:focus {
      background: none;
    }
  }

  .community-status-update-fullscreen__attachment-chooser {
    display: flex;
    position: relative;
    padding: 10px;
    justify-content: flex-end;
  }

  .community-status-update__fullscreen-button {
    flex: 1;
    border: none;
    background: none;
    text-align: left;
    color: inherit;
  }

  .community-status-update-body {
    margin-top: 16px;
    margin-bottom: 4px;
    display: flex;
    align-items: center;
    width: 100%;
    height: 60px;
    font-size: 16px;
  }

  .community-status-update__form {
    display: flex;
    align-self: stretch;
    align-items: stretch;
    flex: 1;
    min-width: 200px;
    border-radius: 4px 4px 4px 4px;
    background-color: #fff;
    box-shadow: 0 5px 5px rgba(0,0,0,0.1);
    ul {
      min-width: 0px;
      max-width: 75%;
      width: -webkit-fill-available;
      column-width: 300px;
      .feed-item-info {
        overflow: auto;
        small {
          display: none;
        }
      }
    }
  }

  .community-status-update__avatar {
    height: 48px;
    width: 48px;
    border-radius: 50%;
    flex-shrink: 0;
    margin-right: 12px;
  }

  .community-status-update__input {
    flex: 1;
    border: none;
    min-width: 30px;
    text-overflow: ellipsis;
    padding: 14px;

    &::placeholder {
      color: $gray-600;
    }
  }

  .community-status-update__input:focus {
    background-color: #fff;
  }

  .community-status-update__arrow {
  	width: 0;
    height: 0;
    border-top: 12px solid transparent;
    border-bottom: 12px solid transparent;
    border-right: 12px solid #fff;
    z-index: 1;
  }

  .community-status-update-attachment {
    display: flex;
    align-self: center;
    position: relative;
    align-items: center;
    border: 1px solid #d2d6d9;
    border-radius: .25em;
    max-width: 160px;
    min-width: 60px;
  }

  .community-status-update-attachment__label {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .community-status-update-attachment__remove {
    border: none;
    background: none;
    padding: 0;
    color: $black;
  }

  .community-status-update__attachment-chooser {
    display: flex;
    position: relative;
  }

  .community-status-update__image-button, .community-status-update__video-button, .community-feed-status-update__image-button, .community-feed-status-update__video-button {
    border: none;
    color: #666;
    background-color: #fff;
    padding: 0 9px;

    &:hover {
      color: $blue;
    }
    &:focus {
      color: $blue;
      outline: 1px dashed $dark-blue !important;
      z-index: 999;
    }
  }
  .community-status-update__image-button, .community-status-update__video-button {
    margin-right: 1px;
  }

  .community-status-update__submit {
    background-color: $blue;
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    border: none;
    border-radius: 0 4px 4px 0;
    padding: 0 24px;
    flex-shrink: 0;
    &:hover, &:focus {
      background-color: $dark-blue;
    }
  }

  .community-status-update-popover {
    position: absolute;
    top: 55px;
    max-width: 276px;
    z-index: 1000;
    transform: translateX(-50%);
  }

  .community-status-update-fullscreen-popover {
    position: absolute;
    top: 35px;
    max-width: 276px;
    z-index: 1000;
    transform: translateX(-50%);

    .community-status-update-popover__body {
      transform: translateX(30%);
    }
  }

  .community-status-update-popover__body {
    padding: 1px;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  }

  .community-status-update-popover__close {
    color: $black;
    background: none;
    border: none;
    padding: 0;
    &:hover, &:focus {
      color: $white;
      background: $blue;
    }
  }

  .community-status-update-popover__arrow {
    border-width: 11px;
    left: 50%;
    margin-left: -11px;
    border-top-width: 0;
    border-color: transparent;
    border-bottom-color: #999999;
    top: -10px;
    position: absolute;
    display: block;
    width: 0;
    height: 0;
    border-style: solid;

    &::after {
      position: absolute;
      display: block;
      width: 0;
      height: 0;
      border-color: transparent;
      border-bottom-color: #fff;
      border-style: solid;
      border-width: 10px;
      content: ' ';
      top: 1px;
      margin-left: -10px;
      border-top-width: 0;
    }
  }

  .community-status-update-popover__title {
    padding: 1.5rem 2rem;
    background: #fff;
    font-size: 1.6rem;
    font-weight: 700;
    border-bottom: 1px solid #ebebeb;
    display: flex;
    justify-content: space-between;
  }

  .community-status-update-popover__content {
    padding: 2rem;
    text-align: center;

    p {
      font-size: 1.3rem;
      font-weight: 600;
    }
  }

  .community-status-update__character-limit {
    padding-left: 78px;
    padding-bottom: 24px;
    color: #666666;
    font-size: 14px;
  }

  .community-status-update__footer {
    font-size: 16px;
    line-height: 19px;
    color: #666;
    text-align: center;
    padding-bottom: 24px;

    a {
      color: darken($blue, 5%);
      text-decoration: underline;
    }
  }

  .community-feed-filter {
    padding-bottom: 4px;
    font-size: 14px;
    text-align: right;
    color: $gray-700;
  }

  .community-feed {
    padding-bottom: 16px;
  }

  .community-feed-item {
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.25);
    margin-bottom: 12px;
  }

  .community-feed-item-main {
    padding-left: 36px;
    padding-right: 36px;
    padding-top: 26px;
    padding-bottom: 18px;
  }

  .community-feed-item-header {
    padding-bottom: 15px;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .community-feed-item-header-info {
    width: 100%;
    &:hover, &:focus {
      text-decoration: none;
      .community-feed-item-header__author {
        text-decoration: underline;
      }
    }
  }

  .community-feed-item-header__avatar {
    display: block;
    float: left;
    height: 36px;
    width: 36px;
    border-radius: 50%;
    margin-right: 16px;
    flex-shrink: 0;
  }

  .community-feed-item-header__avatar--glyph {
    color: $icon-color;
    background-color: $gray-lighter;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .community-feed-item-header-author {
    display: inline-block;
  }

  .community-feed-item-header__actions {
    width: 150px;
    text-align: right;
    white-space: nowrap;
    .hidden-menu-trigger {
      color: $gray-base;
      border: none;
      background: none;
      padding: 0 2px;
      .icon-pencil {
        font-size: 18px;
      }
      &:hover, &:active {
        .material-icons {
          color: $blue;
        }
      }
      &:focus {
        .material-icons {
          color: $blue;
          outline: 1px dashed $dark-blue !important;
        }
      }
    }
    i {
      margin-left: 0.2em;
      font-size: 18px;
      color: $gray-base;
    }

  }
  ul.active {
    display: block;
    width: 125px;
    border: 1px solid #C8C8C8;
    border-radius: 4px;
    background-color: $white;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.25);
    transition: none;
    padding: 1.5rem 2rem;
    max-height: 40rem;
    transition: none;
    overflow: visible;
    li {
        text-align: left;
        color: $gray-base;
        font-size: 15px;
        cursor: pointer;
        width: max-content;
        opacity: 1;
        &:focus, &:hover, &:active {
          outline: none;
          text-decoration: underline;
        }
    }
    li:last-of-type {
      padding-top: 10px;
    }
    &:before {
      content: '';
      width: 0;
      height: 0;
      border-left: .7rem solid transparent;
      border-right: .7rem solid transparent;
      border-bottom: .7rem solid $gray-lighter;
      position: absolute;
      top:-.7rem;
      right:0.8rem;
    }
    &:after {
      content: '';
      width: 0;
      height: 0;
      border-left: .5rem solid transparent;
      border-right: .5rem solid transparent;
      border-bottom: .5rem solid white;
      position: absolute;
      top:-.5rem;
      right:1rem;
    }
  }

  .community-feed-item-header__author {
    color: $black;
    font-size: 16px;
    line-height: 19px;

    &:hover {
      text-decoration: none;
    }
  }

  .community-feed-item-body {
    border-bottom: 1px solid #F3F4F5;
    padding-bottom: 12px;
    margin-bottom: 12px;
  }

  .community-feed-item-body__title{
    font-size: 21px;
    font-weight: 600;
    line-height: 25px;
    margin-bottom: 15px;
    color: $black;
  }

  .community-feed-item-body__content{
    font-size: 15px;
    color: $black;
    line-height: 22px;
    max-height: 44px;
    overflow: hidden;
    word-break: break-word;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;

    &:hover {
      text-decoration: none;
    }
  }

  .community-feed-item-body__content--only{
    font-size: 21px;
    line-height: 25px;
    max-height: 50px;
  }


  .community-feed-item-show-more{
    position: absolute;
    line-height: 25px;
    top: -24px;
    width: 109px;
    right: 0px;
    background-color: white;
  }

  .community-feed-item-show-more__content--only{
    font-size: 14px;
    top: -23px;
    width: 115px;
    right: -10px;
    cursor: pointer;
    transition: none;
  }

  .community-feed-item-position{
    position: relative;
  }

  .hide-text-gradient{
    position: absolute;
    background-image: linear-gradient(to right, rgba(255,255,255,0), rgb(255, 255, 255));
    top: -25px;
    right: 102px;
    width: 130px;
    height: 25px;
    cursor: pointer;
  }

  .community-feed-item-body__stats {
    padding-top: 6px;

    span {
      padding-right: 12px;
    }
    button.community-feed-item-comments {
      padding: 0;
    }
  }

  .community-feed-status-update {
    .community-feed-status-update__form {
      display: flex;
      align-self: stretch;
      align-items: stretch;
      flex: 1;
      border: 1px solid $gray;
      ul {
        min-width: 0px;
        max-width: 75%;
        width: -webkit-fill-available;
        column-width: 300px;
        .feed-item-info {
          overflow: auto;
          small {
            display: none;
          }
        }
      }
    }
    .community-feed-status-update__form:focus {
      border-color: $blue;
    }

    .community-cta__button {
      @extend %ntux-button;
      @extend %ntux-button-small;
      margin-left: 0;
      margin-right: 0;
      display: inline-block;
    }
    .community-cta__button.unfilled {
      @extend %ntux-hollow-button-on-white;
      @extend %ntux-button-small;
      margin-left: 0;
      margin-right: 4px;
      display: inline-block;
    }
  }

  .community-feed-item-footer {
    font-size: 16px;
    line-height: 18px;
    color: $gray-700;
    display: flex;
    align-items: stretch;
    a {
      color: inherit;
    }
    .community-feed-item__like-button {
      color: inherit;
      &:hover {
        color: $icon-color-red;
      }
      &:focus {
          i {
          outline: black;
          outline-width: 1px;
          outline-style: dashed;
        }
      }
    }
    .community-feed-item-footer__text {
      position: relative;
    }
  }

  .community-feed-item__load-more {
    margin-left: auto;
    margin-right: auto;
    background: none;
    border: none;
    text-align: center;
    color: $blue;
    padding: 10px 0;
    display: block;
    cursor: pointer;
    &:hover {
      opacity: 0.7;
    }
  }

  .community-feed-item__add-comment-button {
    padding-left: 12px;

    &:hover {
      color: $blue;
    }
  }

  .community-feed-item-comment-section {
    background-color: #F3F4F5;
  }

  .community-feed-item-comment {
    background-color: #F3F4F5;
    padding: 12px 0;
    margin: 0 36px;
    border-bottom: 1px solid #D8D8D8;
  }

  .community-feed-item-comment__content {
    p {
      font-size: 14px;
      margin: 0;
    }

    a {
      color: $blue;
    }

    code {
      background-color: #C7E3EE;
      color: $blue;
    }

    position: relative;
    margin-left: 47px;
  }

  .community-feed-item-comment__header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  .community-feed-item-comment__avatar {
    position: absolute;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    margin-right: 16px;
    flex-shrink: 0;
  }

  .community-feed-item-comment__body {
    word-wrap: break-word;
    overflow-wrap: break-word;
  }

  .community-feed-item-comment__date {
    margin-right: 0;
    margin-left: auto;
    white-space: nowrap;
  }

  .community-feed-item-comment__user {
    font-weight: 600;
  }

  .community-feed-item-comment-form {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #F3F4F5;
    min-height: 80px;
    margin-top: -10px;
    border-radius: 4px;
    padding-left: 36px;
    padding-right: 36px;
    padding-top: 16px;
    padding-bottom: 16px;
  }

  .community-feed-item-comment-form-fullscreen {
    z-index: 2000;
    position: fixed;
    top: 70px;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #F3F4F5;
    padding: 16px;
  }

  .community-feed-item-comment-form__avatar {
    height: 48px;
    width: 48px;
    border-radius: 50%;
    margin-right: 16px;
    flex-shrink: 0;
  }

  .community-feed-item-comment-form__avatar--glyph {
    color: $icon-color;
    background-color: $gray-lighter;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .community-feed-item-comment-form__input {
    flex: 1;
    padding-right: 10px;

    input {
      height: 45px;
      width: 100%;
      padding-left: 20px;
      border-radius: 2px;
      border: 1px solid #C8C8C8;
    }

    .CodeMirror {
      min-height: 50px;
    }

    .CodeMirror-scroll {
      min-height: 0;
    }

    .editor-toolbar:before, .editor-toolbar:after {
      display: inline;
    }
  }

  .community-feed-item-comment-form__button {
    @extend %ntux-button;
    @extend %ntux-button-small;
    margin-left: 8px;
    margin-right: 0;
    margin-top: 0;
  }

  .community-feed-item-comment-form__fullscreen-button {
    flex: 1;
    border-radius: 4px;
    text-align: left;
    min-height: 50px;
    display: flex;
    align-items: center;
    padding-left: 12px;
    @extend %ntux-button;
    @extend %ntux-hollow-button-on-white;
  }

  .community-trending {
    @extend %community-card-shared;
  }

  .trending-container{
    overflow-x: hidden;
    text-overflow: ellipsis;
  }
  .community-trending__header {
    @extend %community-card-header;
    font-weight: inherit;
    line-height: inherit;
    margin: auto;
  }

  .community-trending-list {
    min-height: 524px;
  }

  .community-trending-list-item-skeleton {
    height: 130px;
    border-bottom: 1px solid #F3F4F5;
    background-repeat: no-repeat;
    background-size: 260px 42px,
      20px 20px,
      44% 18px,
      50% 16px,
      25% 16px,
      100% 100%;
    background-position:
      0 20px,
      0 74px,
      30px 74px,
      30px 95px,
      150px 95px;
    background-image: linear-gradient(#F3F4F5 75px, transparent 0),
      radial-gradient(circle 10px, #F3F4F5 99%, transparent 0),
      linear-gradient(#F3F4F5 18px, transparent 0),
      linear-gradient(#F3F4F5 16px, transparent 0),
      linear-gradient(#F3F4F5 16px, transparent 0),
      linear-gradient(white 100%, transparent 0);
  }

  .community-trending-list-item {
    border-bottom: 1px solid #F3F4F5;
    padding-bottom: 20px;
    padding-top: 20px;
  }

  .community-trending-list-item-details {
    display: flex;
    align-items: stretch;
  }

  .community-trending-list-item__avatar {
    height: 36px;
    width: 36px;
    border-radius: 50%;
    margin-right: 10px;
  }

  .community-trending-list-item__avatar--project {
    height: 36px;
    width: 36px;
    border-radius: 50%;
    margin-right: 10px;
    color: #888;
    background-color: #eee;
    font-size: 24px;
    line-height: 36px;
    padding: 0;
    text-align: center;
  }

  .community-trending-list-item__title {
    font-size: 18px;
    font-weight: 600;
    line-height: 21px;
    margin-bottom: 6px;
    padding-top: 1px;
    max-height: 87px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;

    .emojione{
      max-height: 21px;
    }

    a {
      color: #262626;

      &:hover {
        color: #262626;
      }
    }
  }

  .community-trending-list-item__icon {
    font-size: 16px;
    vertical-align: middle;
  }

  .community-trending-list-item-details__author {
    font-size: 16px;
    line-height: 18px;
    padding-bottom: 4px;
    overflow-x: hidden;
    text-overflow: inherit;
    white-space: nowrap;

    a {
      text-overflow: inherit;
      color: #262626;

      &:hover {
        color: #262626;
      }
    }
  }

  .community-trending-list-item__details {
    padding-right: 24px;
    min-width: 16px;
    display: block;
    text-overflow: ellipsis;
    span {
      white-space: nowrap;
      color: $gray-700;
    }
  }

  @media screen and (max-width: 1244px) {
    .icon-toggle {
      display: none;
    }
  }

  .community-trending-list-item__likes {
    padding-right: 12px;
  }

  .community-trending-list-item-stats {
    display: flex;
  }

  #thumbnailLink.status-link-cover {
    display: block;
    width: fit-content;
    &:focus {
      outline: auto !important;
      outline: -webkit-focus-ring-color auto 5px !important;
    }
    .community-trending-list-item__thumbnail {
      pointer-events: none;
    }
  }

  .community-trending-list-item__thumbnail {
    height: 100px;
    max-width: max-content;

    img {
      height: -webkit-fill-available;
      max-width: fit-content;
    }
    iframe {
      height: 100px;
      max-width: 135px;
    }
    a.img-crop, div.img-crop {
      height: 100px;
      max-width: -webkit-fill-available;
      border: 0px;
    }
  }
  .community-trending__show-more {
    @extend %show-more-button;
  }

  .community-cta {
    @extend %community-card-shared;
    text-align: center;
    min-height: 200px;
  }

  .community-cta__title {
    font-size: 18px;
    font-weight: 600;
    line-height: 21px;
  }

  .community-cta__icon {
    padding-top: 16px;
    pointer-events: none;
  }

  .community-cta__footer {
    display: flex;
  }

  .community-cta__button {
    margin-top: 16px;
    @extend %ntux-button;
    @extend %ntux-button-small;
  }

  .community-feed-filter-followed-only {
    background-color: $gray-lighter;
    border-radius: 12px;
    line-height: 24px;
    font-size: 15px;
    vertical-align: bottom;
    padding: 0px 8px;
    display: inline-block;
  }

  .emojione {
    max-height: 22px;
    margin: 0;
  }

  .skip-featured {
    font-size: 16px;
    width: auto;
    display: block;
    position: fixed;
    left: -999px;
    top: -999px;

      &:focus {
        left: 0;
        top: 0;
        padding: 3px;
        background: $blue;
        border:1px solid $dark-blue;
        color: white;
      }
    }
  @media screen and (max-width: 769px) {
    .skip-featured {

    }
  }
}

.community--unauthenticated {
  padding-top: 30px;
}

.func-link {
  cursor: pointer;
  transition: none;
  color: $gray-700;
  text-decoration: none;
  background: none;
  border: none;
  &:focus {
    color: $gray-base;
    text-decoration: underline;
  }
}

.community-feed-status-delete-modal {
  @extend %community-modal;

  .modal-dialog {
    width: 400px;
  }

  .feed-item-delete-message {
    padding-bottom: 10px;
  }

  .modal-body {
    padding-bottom: 16px;
  }
}

.community-feed-status-flag-modal {
  @extend %community-modal;
  textarea {
    resize: none;
  }
  .feed-item-flag-message {
    text-align: left;
    padding-bottom: 10px;
    display: block;
    font-weight: 400;
  }
  .modal-title {
    font-weight: 400;
  }
}

.community-feed-item-hide-modal {
  @extend %community-modal;

  .modal-dialog {
    width: 400px;
  }

  .feed-item-hide-message {
    margin-bottom: 14px;
  }
}

.toggle-link {
  i {
    font-size: 18px;
    color: $gray-base;
  }
  &.active, &:hover, &:focus {
    text-decoration: none;
    i {
      color: $brand-danger;
    }
  }
}

.format-quote-icon {
  font-size: 18px;
  color: $gray-base;
  &:hover, &.active {
    color:$brand-primary;
  }
}

.community-modal {
  .modal-content {
    background: $white;
  }
  .modal-header {
    padding: 30px;
  }
  .modal-body {
    padding: 0 30px 30px 30px;
  }

  .close .icon-cancel {
    color: $gray-base;
    font-size: 2.8em;
  }

  .modal-title {
    font-size: 34px;
    line-height: 41px;
    color: $gray-base;
    font-weight: 400;
  }

  .modal a {
    font-size: 16px;
    color: $gray-base;
  }

  h5 {
    a {
      font-size: 20px;
      color: $gray-base;
      font-weight: 400;
    }
  }

  .user-list article {
    border: none;
    .profile-thumb {
      width: 30px;
      height: 30px;
      margin-right: 1rem;
    }
    .article-user {
      float: left;
      padding-top: 5px;
      .profile-slug {
          font-size: 20px;
          color: $blue;
          font-weight: 400;
          display: inline-block;
          vertical-align: middle;
      }
      .profile-name{
        color: $black;
        vertical-align: middle;
        padding:10px;
      }
      &:focus, &:hover, &:active {
        .profile-slug {
          color: $dark-blue;
          text-decoration: underline;
        }
      }
    }
  }

.community-follow-btn {
  float: right;
  label {
    margin: 0;
  }
}

  .user-list article:last-child {
    border: none;
  }

  .view-more-btn {
    text-align: center;
    button {
      @extend %ntux-button;
    }
  }
}
