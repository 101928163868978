/*!
 * Copyright (C) 2023. to Present Applause App Quality, Inc. All rights reserved.
 */

// Atomic Design
$black: #000000;
$ngf-grey-1000: #1B1B1B; // modal screen bg 30% opacity
$ngf-grey-900: #282828; // label color
$ngf-grey-800: #4A4A4A; // value color - user selected, user entered, and value-only(no input:label/value combos)
$ngf-grey-700: #595959; // AAA compliance on white: description text under fields, darker icon color when on #e6e6e6 bg,
$ngf-grey-600: #767676; // AA compliance on white: placeholder text, icon default, *lightest font color for default text*
$ngf-grey-500: #999999; // not used yet, but added for additional greyscale options
$ngf-grey-400: #C6C6C6; // copyright text, & Progress "not started" hourglass color
$ngf-grey-300: #D3D3D3; // light icons/unselected icons (checkboxes, icons within text field, unavailable pagination arrows)
$ngf-grey-200: #E6E6E6; // 1px borders, pills, card header bg color
$ngf-grey-100: #F0F0F0; // alternate bg color - limited use: disabled fields, bg on removable rows, callout bg(Internal SRS)
$ngf-grey-50: #FAFAFA; // row zebra-striping
$white: #FFFFFF;


// Primary Colors
$applause-blue-60: #025B90;  // blue button hover color
$applause-blue-50: #0272B4;  // blue button color
$applause-blue-40: #489DCF;
$applause-blue-30: #94CBEB;  // blue button disabled color
$applause-blue-20: #CFE9FB;
$applause-blue-10: #E6F3FA;  // blue selection highlight color
$applause-blue-5: #F7FBFE;  // subtab background color

// TODO -- this colors are deprecated and will be removed
// use ngf instead
$applause-grey-600: $ngf-grey-700;
$applause-grey-500: #999999;
$applause-grey-400: #BABABA;
$applause-grey-300: #CCCCCC;
$applause-grey-200: #D3D3D3;
$applause-grey-100: #E6E6E6;
$applause-grey-50: #F0F0F0;

$white: #FFFFFF;


// Secondary Colors
$applause-green-60: #0E7415;
$applause-green-50: #0F8919;
$applause-green-20: #DDF4DF;

$applause-red-60: #AA240A;
$applause-red-50: #D42D0E;
$applause-red-20: #F8DBD6;

$applause-yellow-50: #FFC400;
$applause-yellow-20: #FFF6D9;
$applause-yellow-10: #FFFCF2;

$applause-orange-50: #ED7200;
$applause-orange-20: #FDE7D4;

// Specialty Colors
$utest-teal: #00A8CC;
$applause-accent-green: #01B78B;


// Color Use
$ngf-label: $ngf-grey-900;
$ngf-text: $ngf-grey-800;
$ngf-border: $ngf-grey-200;
$ngf-link: $applause-blue-50;

$ngf-label-color: $ngf-grey-900;
$ngf-hint-color: $ngf-grey-700;
$ngf-error-color: $applause-red-50;
$ngf-blue: $applause-blue-50;
$ngf-placeholder-color: $ngf-grey-600;
$ngf-field-value-color: $ngf-grey-800;

// Color States
$ngf-error: $applause-red-50;
$ngf-warning: $applause-orange-50;
$ngf-pending: $applause-yellow-50;
$ngf-success: $applause-green-50;
