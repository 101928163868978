.msg-list {
  h2 {
    padding-bottom: 2rem;
    margin-bottom:0;
    border-bottom: 1px solid #d2d6d9;
    height: 5rem;
  }
  .circ-item-num {
    vertical-align: middle;
    margin-left: 1rem;
  }
  .msg-list-item {
    border-bottom: 1px solid #d2d6d9;
    border-right:.8rem transparent solid;
    padding-top:2rem;
    cursor: pointer;
    @include transition(all .2s ease-in-out);
    &:hover {
      background:rgba(235,235,235, .1);
    }
    &.active {
      height: 100%;
      @include bp-tablet{
        border-right:.8rem #2dc3e3 solid;
      }
    }

    .msg-list-item-details{
      width: calc(100% - 50px);
      display: inline-block;
      vertical-align: top;
      padding-bottom:2rem;
      .time-stamp {
        position:absolute;
        right:2rem;
      }
      h5 {
        font-size:1.4rem;
        font-weight: 600;
        margin:.1rem 0 0;
      }
      .details-preview {
        color: #7b8a97;
        width: 100%;
      }
    }

  }
}
.msg-content {
  background:#fff;
  position: absolute;
  top:2rem;
  width: calc(100% - 4rem);
  .load-more-btn .btn {
    margin:0;
  }
  @include transform(translate(110%,0) !important);
  @include transition(all .2s ease-out);
  @include bp-tablet{
    padding-left: 4rem;
    right:0;
    position: relative;
    width: 58.33%;
    display: block;
    @include transform(translate(0,0) !important);
  }
  .msg-content-header {
    margin: 2rem 0;
    height: 5rem;
    border-bottom: 1px solid #d2d6d9;
    line-height: 3rem;
    h4 {
      font-weight: 600;
      float: right;
      margin-top:.6rem;
    }
  }
  .msg-content-block {
    width:100%;
    .time-stamp {
      font-weight: 600;
      text-transform: uppercase;
    }
    .msg-content-block-bubble {
      margin-top: .5rem;
      .block-bubble-content {
        width: calc(100% - 45px);
        font-size: 1.2rem;
        color: #262626;
        padding: 1rem;
        border-radius:4px;
        background: #f6f8f9;
        float: left;
        @include bp-tablet{
          padding: 2rem;
        }
      }
      .profile-thumb {
        margin:0;
        float: right;
      }
    }
    &.self {
      .time-stamp {
        text-align: right;
      }
      .profile-thumb {
        margin:0;
        float: left;
      }
      .block-bubble-content {
        float: right;
        background:none;
        border: 1px #d2d6d9 solid;
      }
    }
  }
  .msg-content-reply {
    margin-top:2rem;
    padding-top: 2rem;
    border-top: 1px #d2d6d9 solid;
    @include transition(all .3s ease-in-out);
    .reply-container {
      border-radius: 4px;
      width: calc(100% - 4.9rem);
      display: inline-block;
      vertical-align: middle;
      input {
        width:calc(100% - 6rem);
        padding-left:1rem;
        padding-right: 1rem;
        height:4.2rem;
        border-radius: 4px 0px 0px 4px;
        border: 1px #d2d6d9 solid;
        @include transition(all .3s ease-in-out);
        outline:none;
        &:focus {
          border-color: #00a8cc;
          background: none;
        }
      }
    }
  }
  @include bp-phone {
    @include transform(translate(0,0) !important);
    position: relative;
    width:100%;
    .msg-content-header {
      margin-top: 0px;
    }
  }
}

@keyframes messageNavAnimation {
  0% {
    top: 0px;
    opacity:1;
  }
  100% {
    top: -10px;
    opacity:0;
  }
}

@include bp-phone {
  .msg-wrapper {
    .msg-list {
      position: relative;
    }
    .msg-content {
      position:absolute;
      height:0;
      overflow:hidden;
    }
    &.msg-wrapper-open {
      .msg-list {
        position: absolute;
        @include animation(messageNavAnimation 500ms 1 forwards);
      }
      .msg-content {
        position: relative;
        height:auto;
        @include animation(messageNavAnimation reverse 500ms 1 forwards);
      }
    }
  }
}
