//date-picker 
.date-picker{
  .date-picker-input{
    width:100%;
    background:#fff;
    height:2.8rem;
    border: 1px #d2d6d9 solid;
    border-radius:4px;
    margin-bottom:1rem;
    position:relative;
    input {
      border: 0px;
      outline: none;
      height: 100%;
      padding: 0 1.3rem;
      background:none;
    }
    .icon {
      position:absolute;
      right: .3rem;
      top:.1rem;
      cursor:pointer;
    }
  }
  //date-picker override bootstrap
  .dropdown-menu {
    table {
      outline:none;
      .h6 em{
        font-size: 1rem !important;
      }
    }
    .btn {
      width:100%;
      padding:.5rem;
      min-width: 1rem;
      border:1px #eee solid;
    }
    .btn-group {
      width: 50%;
      .btn{
        color:#fff;
        width:50%;
        font-size: 1rem;
      }
    }
    .btn-success {
      color:#fff;
      width:30%;
      font-size: 1rem;
    }

  }
}