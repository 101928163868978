.main-ui-view {
  @include transition(transform .2s cubic-bezier(0.440, 0.080, 0.310, 0.985), opacity .1s cubic-bezier(0.440, 0.080, 0.310, 0.985));
  .section-home-cover-random {
    @include transition(all .3s cubic-bezier(0.440, 0.080, 0.310, 0.985));
  }
  h3 {
    @include transition(opacity .3s cubic-bezier(0.440, 0.080, 0.310, 0.985));
  }
  &.ng-enter {
    .section-home-cover-random {
      opacity: 0;
      @include transform(translate3d(-50px, 0, 0));
      &:last-of-type {
        -webkit-transition-delay: .05s;
        transition-delay: .05s;
      }
    }
    h3 {opacity:0}
  }
  &.ng-enter-active {
    .section-home-cover-random {
      opacity: 1;
      @include transform(translate3d(0, 0, 0));
    }
    h3 {opacity:1}
  }
  &.ng-leave {
    @include transition(all .2s ease-in-out);
    opacity: 1;
    @include transform(translate3d(0, 0, 0));
    .section-home-cover-random {
      opacity: 1;
      @include transform(translate3d(0, 0, 0));
    }
    h3 {opacity:1}
  }
  &.ng-leave-active {
    opacity: 0;
    @include transform(translate3d(50px, 0, 0));
    .section-home-cover-random {
      opacity: 0;
      @include transform(translate3d(100px, 0, 0));
    }
    h3 {opacity:0}
  }
}

//buttons
.btn {
  &:hover {
    opacity: 1;
  }
  &:after {
    position: absolute;
    transition:all .3s ease-in-out;
    display: inline-block;
    z-index: 2;
  }
  &:before {
    position: absolute;
    transition:all .3s ease-in-out;
    display: inline-block;
    z-index: 1;
  }
}
.btn-text {
  position: absolute;
  z-index: 3;
}

//article button
.easy-write {
  .write-article {
    .btn {
      overflow: hidden;
      position: relative;
      padding: 1rem 2.7rem 1rem 5.1rem;
      font-size: 1.2rem;
      & * {
        @include transition(all .3s ease-in-out);
      }
      &:hover {
        .icon-write {
          &:after {
            margin-top: 0.5rem;
          }
          &:before {
            margin-top: -3rem;
          }
        }
      }
      .icon-write {
        &:after {
          font-size: 0.8rem;
          font-weight: 100;
          content:'\e822';
          font-family: "utestfont";
          margin: 3rem 0 0 -1.65rem;
          position: absolute;
          float: left;
          @include transition(all .3s ease-in-out);
        }
        &:before {
          margin: .3rem -2.2rem;
          position: absolute;
          float: left;
          @include transition(all .2s ease-in-out);
        }
      }
    }
  }
  .write-status {
    input {
      background: #FDFFFD;
      border-right: none;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      @include transition(all .2s ease-in-out);
      .btn-text:after {
        left: -1.5rem;
      }
      &.hover,
      &:focus {
        border: 1px #00a8cc solid;
        background: #ffffff;
        color: #262626;
      }
      &.ng-invalid.ng-touched {
        border-color:  #ef5454;
      }
    }
    .icon-write-status {
      z-index: 4;
    }
  }
}
//blue post button
.btn.blue-tab {
  position: relative;
  border: 1px solid #00a8cc;
  border-left: none;
  @include transition(all .6s ease-in-out);
  &:before {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    content: '';
    background: #00a8cc;
  }
  &:after {
    width: 0%;
    height: 100%;
    top: 0;
    left: 0;
    content: '';
    background: #fff;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
  }
  .btn-text {
    left: 1.3rem;
    top: .9rem;
    font-size: 1.2rem;
    position: absolute;
    &:after {
      font-size: .6rem;
      content:'\e822';
      font-family: "utestfont";
      position: absolute;
      left: -2.2rem;
      @include transition(all .3s ease-in-out);
      opacity: 0;
    }
  }
  &:hover {
    color: #00a8cc;
    @include transition(all .1s ease-in-out);
    border: 1px #00a8cc solid;
    border-left: none;
    &:after {
      width: 100%;
    }
    .btn-text:after {
      left: -1rem;
      opacity: 1;
    }
  }
}
//tags
.tags {
  .small {
    border: .1rem #bac1c6 solid;
    padding: 0.3rem 1rem;
    @include transition(all .2s ease-in-out);
  }
}


//follow tags
.cus-checkbox-follow {
  .checkbox-toggle {
    overflow:hidden;
    &:before {
      position: absolute;
      top: 0rem;
      line-height: 2rem;
      @include transition(all .2s ease-in-out);
    }
    &:after {
      @include bp-tablet {
        content: '+';
        position: absolute;
        text-align: center;
        width: 100%;
        left: 0;
        top: 2rem;
        position: absolute;
        @include transition(all .3s ease-in-out);
      }
    }
    &:hover {
      &:before {
        @include bp-tablet {
          top: -2rem;
        }
      }
      &:after {
        @include bp-tablet {
          top: 0rem;
        }
      }
    }
  }
  input[type='checkbox']:checked ~ .checkbox-toggle {
    font-weight: 600;
    &:hover {
      &:before {
        top: 0rem;
      }
      &:after {
        top: 2rem;
      }
    }
  }
}

//Add to Calendar
.btn.small.add-to-calendar {
  overflow:hidden;
  position: relative;
  border: none;
  width: 126px;
  &:before {
    content: '+ Add To Calendar';
    line-height: 2.3rem;
    position: absolute;
    top: 0rem;
    text-align: center;
    width: 100%;
    left: 0;
    @include transition(all .2s ease-in-out);
  }
  &:after {
    content: '+';
    line-height: 2.3rem;
    position: absolute;
    text-align: center;
    width: 100%;
    left: 0;
    top: 2rem;
    @include transition(all .3s ease-in-out);
  }
  &:hover {
    &:before {
      top: -2rem;
    }
    &:after {
      top: 0rem;
    }
  }
}

//edit profile
.edit {
  @extend %ntux-button;
  @extend %ntux-hollow-button-on-white;
  overflow: hidden;
  position: relative;
  &.btn-grey {
    margin-top: 0px;
  }
  &:before {
    background-color: $white;
    font-size: 12px;
    position: absolute;
    width: 100%;
    text-align: center;
    line-height: 0rem;
    left:0;
    top: 1.2rem;
    @include transition(all .2s ease-in-out);
  }
  &:before {
    content: 'Edit';
  }
  &.profile:before {
    content: 'Edit Profile';
  }
  &.banner:before {
    content: 'Edit Banner';
  }
  &.username:before {
    content: 'Choose Username';
  }
  &:after {
    content: 'Edit';
    position: absolute;
    font-size: 12px;
    text-align: center;
    line-height: 0rem;
    width: 100%;
    left: 0;
    top: 4rem;
    @include transition(all .2s ease-in-out);
  }
  &.username:after {
    content: 'Choose';
  }
  &:hover, &:focus {
    background-color: $white !important;
    &:after {
      top: 1.2rem;
    }
    &:before {
      top: -2rem;
    }
  }
}

.edit-article {
  height: 2.5rem;
  overflow: hidden;
  @include transition(all .3s);
  position: relative;
  min-width: 2rem;
  &:hover {
    border-color: #fdb900 !important;
    color: #fdb900 !important;
  }
}
//heartbeat
.icon-heart {
  &:after {
    @include transition(all .2s ease-in-out);
  }
  &.clickable:hover {
    &:before {
      @include animation (heartFrames ease-in-out 4s);
      animation-iteration-count: 1;
      @include transform-origin(50% 50%);
    }
  }
}
@include keyframes(heartFrames) {
  0% {
    @include transform(scaleX(1.00) scaleY(1.00)) ;
  }
  2% {
    @include transform(scaleX(1.10) scaleY(1.10)) ;
  }
  4% {
    @include transform(scaleX(1.05) scaleY(1.05)) ;
  }
  6% {
    @include transform(scaleX(1.20) scaleY(1.20)) ;
  }
  9% {
    @include transform(scaleX(1.00) scaleY(1.00)) ;
  }
  100% {
    @include transform(scaleX(1.00) scaleY(1.00)) ;
  }
}

//comment dialogue box
.icon-chat {
  &:after {
    @include transition(all .1s ease-in-out);
  }
  &:hover {
    &:before {
      @include animation (chatFrames ease-in-out 4s);
      animation-iteration-count: 1;
      @include transform-origin(50% 50%);
    }
  }
}
@include keyframes(chatFrames) {
  0% {
    @include transform(translate(0px,0px)  scaleX(1.00)) ;
  }
  2% {
    @include transform(translate(0px,5px)  scaleX(0.80)) ;
  }
  4% {
    @include transform(translate(0px,-5px)  scaleX(0.87)) ;
  }
  7% {
    @include transform(translate(0px,0px)  scaleX(1.00)) ;
  }
  100% {
    @include transform(translate(0px,0px)  scaleX(1.00)) ;
  }
}

//eye blink
.icon-views {
  &:after {
    @include transition(all .2s ease-in-out);
  }
  &:hover{
    &:before {
      @include animation (eyeFrames ease-in-out .5s);
      animation-iteration-count: 2;
      @include transform-origin(50% 50%);
    }
  }
}
@include keyframes(eyeFrames) {
  0% {
    @include transform(scaleY(1.00)) ;
  }
  10% {
    @include transform(scaleY(0.50)) ;
  }
  20% {
    @include transform(scaleY(0.0)) ;
  }
  30% {
    @include transform(scaleY(0.50)) ;
  }
  40% {
    @include transform(scaleY(1)) ;
  }
  100% {
    @include transform(scaleY(1.00)) ;
  }
}

//icon clicks
.icon-heart.clickable:active:before, .icon-chat:active:before, .icon-views:active:before, .delete-btn:active:before, a:active .profile-item-icon:before, .func-btns a:active:before, .icon-at:active a, .icon-at i:active, .checkbox-toggle:active:before, .checkbox-toggle:active:after, .edit-profile:active:before, .edit-profile:active:after, .write-article .btn:active .icon-write:before, .write-article .btn:active .icon-write:after, .write-status a:active .btn-text {
  @include animation (clickFrames ease-in-out 4s);
  animation-iteration-count: 1;
  @include transform-origin(50% 50%);
}
@include keyframes(clickFrames) {
  0% {
    @include transform(scaleX(1.00) scaleY(1.00)) ;
  }
  3% {
    @include transform(scaleX(1.15) scaleY(1.15)) ;
  }
  5% {
    @include transform(scaleX(1.00) scaleY(1.00)) ;
  }
  100% {
    @include transform(scaleX(1.00) scaleY(1.00)) ;
  }
}
.cover-content-btn.idle:active:before {
    @include animation (none);
}


//icon hover colors
.profile-item-icon {
  &:before {
    @include transition(all .1s ease-in-out);
  }
}

a:hover {
  .icon-avatar:before {
    color: #32c0ce;
  }
  .icon-inbox:before {
    color: #fdb900;
  }
  .icon-notifications:before {
    color: #fa8513;
  }
  .icon-write:before {
    color: #ef5454;
  }
  .icon-write:before {
    color: #9675cc;
  }
  .icon-articles:before {
    color: #ef5454;
  }
  .icon-heart:before {
    color: #ff017e;
  }
  .icon-follow:before {
    color: #1599e7;
  }
  .icon-leaderboard:before {
    color: #fa8513;
  }
  .icon-uPoints:before {
    color: #1599e7;
  }
}

//filter dropdowns
.filter, .filter.to-do-filter{
  min-height: 32px;
  background: #edf0f2;
  @include transition (all .3s ease-in-out);
  .filter-Toggle {
    @include transition (all .3s ease-in-out);
  }
}

//Search Animation
.search-field:focus ~ label {
  @include animation (searchGlass ease-in-out .5s);
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  @include transform-origin(50% 50%);
}
@include keyframes(searchGlass) {
  0% {
    @include transform(rotate(0deg) scale(1, 1)) ;
  }
  100% {
    @include transform(rotate(360deg) scale(1.3,1.3)) ;
  }
}

//alert animations
.alert {
  @include transition(all .3s ease-in-out);
  overflow: hidden;
  &.ng-enter {
    max-height: 0;
    opacity: 0;
    padding: 2.5rem 3rem;
  }
  &.ng-enter-active {
    max-height: 30rem;
    opacity: 1;
    padding: 0rem
  }
  &.ng-leave {
    max-height: 30rem;
    opacity: 1;
    padding: 2.5rem 3rem;
  }
  &.ng-leave-active {
    max-height: 0;
    opacity: 0;
    padding: 0rem;
  }
  .close {
    top: -18px;
    opacity: .7;
    &:hover {
      opacity: 1;
    }
  }
}

//to do animations
.to-do-item {
  @include transition(all .2s ease-in-out);
  padding: 1.5rem 0;
  overflow: hidden;
  & * {
    @include transition(all .2s ease-in-out);
  }
  &.ng-enter {
    height: 0px;
    opacity: 0;
    padding: 0;
    & * {
      opacity: 0;
    }
  }
  &.ng-enter-active {
    height: 90px;
    opacity: 1;
    padding: 1.5rem 0;
    & * {
      opacity: 1;
    }
  }
  &.ng-leave {
    height: 90px;
    opacity: 1;
    padding: 1.5rem 0;
    & * {
      opacity: 1;
    }
  }
  &.ng-leave-active {
    height: 0rem;
    opacity: 0px;
    padding: 0;
    & * {
      opacity: 0;
    }
  }
}


//topnav animations
.side-panel-btn {
  @include transition(all .2s ease-in-out !important);
  background: rgba(255, 255, 255, 0);
}

//progress bars
.to-do-item{
  .progress-info .bar-container .bar {
    @include transition(all .2s ease-in-out);
  }
  &.ng-enter .progress-info .bar-container .bar{
    width: 0% !important;
  }
  &.ng-enter-active .progress-info .bar-container .bar{
    width: 33% !important;
  }
  &.ng-leave .progress-info .bar-container .bar{
    width: 0 !important;
  }
  &.ng-leave-active .progress-info .bar-container .bar{
    opacity: 0;
    @include transform(translate3d(100px, 0, 0));
  }
}

//delete confirmation
.func-btns, .to-do-item-func {
  height: 2.8rem;
  &.deleted {
    -webkit-perspective: 1000px;
    -moz-perspective: 1000px;
    perspective: 2000px;
    .delete-confirmation {
      background: transparent;
      margin-top: .2rem;
      @include transform(rotateX(0deg) translateY(-25px));
    }
    .main-btns {
      @include transform(rotateX(90deg) translateY(-12px));
    }
  }

  &.normal {
    .main-btns {
      @include transform(rotateX(0deg) translateY(0px));
    }
    .delete-confirmation {
      background: #f4f4f4;
      @include transform(rotateX(-90deg) translateY(-5px));
    }
  }
  .main-btns {
    @include transition(transform .3s);
    @include transform-origin(50% 0);
    .delete:hover {
      //box-shadow: inset 0 0 0 0.1rem #ef5454;
      color: $icon-color-red;
    }
    .edit:hover {
      color: #bac1c6;
    }
    .publish {
      width: 64px;
      position: relative;
      overflow: hidden;
      @include transition(all .3s);
      text-align: left;
      &.done {
        width: 96px;
        &:after {
          right: 0.8rem;
        }
      }
      span {
        @include transition(all .2s);
        position: absolute;
        top: .2rem;
        &.ng-enter {
          opacity: 0;
        }
        &.ng-enter-active {
          opacity: 1;
        }
        &.ng-leave {
          opacity: 1;
        }
        &.ng-leave-active {
          opacity: 0;
        }
      }
      &:hover {
        border: 1px solid #22c064;
        color:#22c064;
      }
      &:after {
        @include transition(all .4s);
        position: absolute;
        line-height: 1rem;
        top: 0.5rem;
        right: -2rem;
      }
    }
  }
  .delete-confirmation, .reject-confirmation, .publish-confirmation  {
    @include transition(transform .3s);
    @include transform(rotateX(-90deg));
    @include transform-origin(50% 0);
    background: #f4f4f4;
    -webkit-transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;
    transform-style: preserve-3d;
  }
}

//overlay effect
.overlay-scale {
  visibility: hidden;
  opacity: 0;
  height: 100vh;
  @include transition(opacity 0.2s ease-out, height 0.2s ease-out, visibility 0.2s ease-out);
  &.open {
    visibility: visible;
    opacity: 1;
    @include transition(opacity 0.2s ease-in, height 0.2s ease-in, visibility 0.2s ease-in);
  }
}

dialog.overlay-scale {
  &[open] {
    visibility: visible;
    opacity: 1;
    @include transition(opacity 0.2s ease-in, height 0.2s ease-in, visibility 0.2s ease-in);
  }
}

//slide up effect
.slide-up-move {
  visibility: hidden;
  opacity: 0;
  @include transform(translateY(100%));
  &.open {
    visibility: visible;
    opacity: 1;
    @include transform(translateY(0));
  }
}

//Form animations
input, textarea {
  @include transition(transform .3s);
}

.dropdown-menu {
  min-width: 100%;
  float: none;
  @include transition(all .3s);
  &.ng-enter {
    height: 0px;
    opacity: 0;
    padding: 0;
  }
  &.ng-enter-active {
    height: 320px;
    opacity: 1;
    padding: 5px 0;
  }
  &.ng-leave {
    height: 320px;
    opacity: 1;
  }
  &.ng-leave-active {
    height: 0px;
    opacity: 0;
  }
  table {
    display: inline-block;
  }
  }

//loading
$animation-duration: 1.75s;
.load-more-btn .btn.loading {
  width: 10rem;
  opacity: 1;
  min-width: min-content !important;
  @extend %ntux-button;
  .static {
      display: inline;
    }
  .scale {
    display: none;
    font-size: 1.2rem;
    vertical-align: middle;
  }
  &.started {
    opacity: 0;
    display:none;
    .static {
      display: none;
    }
    .scale {
      display: inline;
    }
  }
  span.l {
    animation: shrinkgrow $animation-duration ease-in-out infinite;
    animation-delay: $animation-duration * 0;
  }
  span.o {
    animation: shrinkgrow $animation-duration ease-in-out infinite;
    animation-delay: $animation-duration * 0.125;
  }
  span.a {
    animation: shrinkgrow $animation-duration ease-in-out infinite;
    animation-delay: $animation-duration * 0.25;
  }
  span.d {
    animation: shrinkgrow $animation-duration ease-in-out infinite;
    animation-delay: $animation-duration * 0.375;
  }
  span.i {
    animation: shrinkgrow $animation-duration ease-in-out infinite;
    animation-delay: $animation-duration * 0.5;
  }
  span.n {
    animation: shrinkgrow $animation-duration ease-in-out infinite;
    animation-delay: $animation-duration * 0.625;
  }
  span.g {
    animation: shrinkgrow $animation-duration ease-in-out infinite;
    animation-delay: $animation-duration * 0.75;
  }
}
@media (max-width: 525px) {
  .load-more-btn {
    float: left;
  }
}
@media (max-width: 345px) {
  .load-more-btn {
    float: unset;
  }
}

@keyframes shrinkgrow {
  0% { transform: scale(0); }
  12.5% { transform: scale(1); }
  25% { transform: scale(1); }
  33% { transform: scale(0); }
  100% { transform: scale(0); }
}

//fade-out during load
footer{
  opacity: 1;
  @include transition(all .3s ease-in-out);
  &.gone {
    opacity: 0;
    transform:translate(0px, 5rem);
  }
}



//project verification
.verify-checkbox {
  height: 5rem;
  &.cus-checkbox {
    margin: 1rem 0 0 0;
  }
  * {
    display: inline-block;
    text-align: center;
  }
  .applyBtn {
    display: block !important;
    width: 20rem;

    &.ng-hide:not(.ng-hide-animate) {
      display: block !important;
    }
  }
}


//slide up submit
.slide-up-content {
  .easy-write {
    height: 5.5rem;
    padding: 0 .3rem;
    overflow-x: visible;
    -webkit-perspective: 1000px;
    -moz-perspective: 1000px;
    perspective: 1000px;
    .profile-thumb {
      margin-right: .5rem;
    }
    .easy-write-content {
      @include transition(all .3s ease-in);
      @include transform(rotateX(0deg) translateY(0px));
      @include transform-origin(50% 0);
    }
    .easy-write-status {
      @include transform-origin(50% 100%);
      -webkit-transform-style: preserve-3d;
      -moz-transform-style: preserve-3d;
      transform-style: preserve-3d;
      @include transition(all .3s ease-in);
      @include transform(rotateX(-90deg) translateY(0px));
    }
    &.sent .easy-write-status {
      @include transform(rotateX(0deg) translateY(-5px));
    }
    &.sent .easy-write-content {
      height: 0;
      margin-top: -3px;

      @include transform(rotateX(90deg) translateY(-25px));
    }

  }
}

//404 Page
.tremble {
  animation-name: tremble-transform-keyframes;
  animation-duration: 10ms;
  animation-delay: 0ms;
  animation-fill-mode: forwards;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  transform-origin: 0 0;
}
@keyframes tremble-transform-keyframes {
  0% {transform:translate(0px, 0px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) translate(0, -50%);}
  10% {transform:translate(0px, 0px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) translate(0, -50%);animation-timing-function: cubic-bezier(.25,.25,.75,.75);}
  100% {transform:translate(0px, 4px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) translate(0, -50%);}
}

.tear.left {
  animation-name: tear-transform-keyframes;
  animation-duration: 2000ms;
  animation-delay: 0ms;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in;
  animation-iteration-count: infinite;
  transform-origin: 0 0;
}
@keyframes tear-transform-keyframes {
  0% {transform:translate(0px, 0vw) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);  opacity: 1;}
  50% {transform:translate(0px, 35vw) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);animation-timing-function: ease-in-out; opacity: 1;}
  50.01% {transform:translate(0px, 0px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg); opacity: 0;}
  100% {transform:translate(0px, 0px) scale(1) opacity(0) rotateX(0deg) rotateY(0deg) rotateZ(0deg); opacity: 0;}
}

.tear.right {
  animation-name: tear-transform-keyframes;
  animation-duration: 2000ms;
  animation-delay: 750ms;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in;
  animation-iteration-count: infinite;
  transform-origin: 0 0;
}
@keyframes tear-transform-keyframes {
  0% {transform:translate(0px, 0vw) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);  opacity: 1;}
  50% {transform:translate(0px, 35vw) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);animation-timing-function: ease-in-out; opacity: 1;}
  50.01% {transform:translate(0px, 0px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg); opacity: 0;}
  100% {transform:translate(0px, 0px) scale(1) opacity(0) rotateX(0deg) rotateY(0deg) rotateZ(0deg); opacity: 0;}
}

#fourOhFace .oh, #fourOhFace{
  animation-name: oh-transform-keyframes;
  animation-duration: 9999ms;
  animation-delay: 0ms;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  transform-origin: 0 0;
}
@keyframes oh-transform-keyframes {
  0% {transform:translate(0px, 0vw) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);animation-timing-function: ease-in-out;}
  2.50% {transform:translate(0px, 2vw) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);animation-timing-function: ease-in-out;}
  3.00% {transform:translate(0px, 0vw) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);animation-timing-function: ease-in-out;}
  5.50% {transform:translate(0px, -2vw) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);animation-timing-function: ease-in-out;}
  6.00% {transform:translate(0px, 0vw) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  100% {transform:translate(0px, 0vw) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
}

#fourOhFace .tags .btn:last-child {
  animation-name: rightbrow-transform-keyframes;
  animation-duration: 1000ms;
  animation-delay: 500ms;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 1;
  transform-origin: 50% 50%;
}
@keyframes rightbrow-transform-keyframes {
  0% {transform:translate(0px, 0px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);animation-timing-function: ease-in-out;}
  100% {transform:translate(0px, 0px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(40deg);}
}

#fourOhFace .tags .btn:first-child {
  animation-name: leftbrow-transform-keyframes;
  animation-duration: 1000ms;
  animation-delay: 500ms;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 1;
  transform-origin: 50% 50%;
}
@keyframes leftbrow-transform-keyframes {
  0% {transform:translate(0px, 0px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);animation-timing-function: ease-in-out;}
  100% {transform:translate(0px, 0px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(-40deg);}
}

.number.four h1 {
  animation-name: four-transform-keyframes;
  animation-duration: 7500ms;
  animation-delay: 0ms;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  transform-origin: 50% 50%;
}
@keyframes four-transform-keyframes {
  0% {transform:translate(0px, 0px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  1.07% {transform:translate(0px, 0px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);animation-timing-function: ease-in-out;}
  2.67% {transform:translate(0px, 0px) scale(1) rotateX(90deg) rotateY(0deg) rotateZ(0deg);animation-timing-function: ease-in-out;}
  4% {transform:translate(0px, 0px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  8% {transform:translate(0px, 0px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);animation-timing-function: ease-in-out;}
  10.67% {transform:translate(0px, 0px) scale(1) rotateX(90deg) rotateY(0deg) rotateZ(0deg);animation-timing-function: ease-in-out;}
  12% {transform:translate(0px, 0px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  100% {transform:translate(0px, 0px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
}

//staggered feed animations
event-feed-item, article-feed-item, project-feed-item {
  float: left;
  width: 100%;
}

event-feed-item, article-feed-item, project-feed-item, tool-feed-item {
  -webkit-transition: 0.5s ease-out all;
  transition: 0.5s ease-out all;
  &.ng-move,  &.ng-enter {
    opacity: 0;
    background: #edf0f2;
  }

  &.ng-leave.ng-leave-active {
    opacity: 0;
  }

  &.ng-enter.ng-enter-active {
    opacity:1;
    transform: translateX(0rem);
    background: #ffffff;
  }
  &.ng-enter-stagger {
    -webkit-transition-delay: 0.2s;
    transition-delay: 0.2s;
    -webkit-transition-duration: 0s;
    transition-duration: 0s;
  }
}

.blurable {
  -webkit-filter:blur(0rem);
  transition:-webkit-filter 2s;
  &.blur {
    -webkit-filter:blur(1rem);
    transition:-webkit-filter 2s;
  }
}
