.community-registration {
  height: 100%;
  overflow: auto;
  .container-fluid{
    margin-right: auto;
    margin-left: auto;
    padding: 20px 80px;
    max-width: 840px;
  }
  .row {
    margin-bottom: 0px !important;
  }
  @media only screen and (min-width: 992px) {
    #side_banner {
      height: 100%;
      min-height: 100%;
      background-size: contain !important;
      padding: 30px 40px;
      position: relative;
      display: table;
      .brand{
        position: absolute;
        left: 60px;
        top: 30px;
        float: left;
        img{
          max-height: 30px;
          width: 88px;
        }
      }
      .left-content{
        color: #000;
        text-shadow:1px 1px 10px #fff, 1px 1px 10px #ccc;
        display: block;
        vertical-align: middle;
        .h1{
          color: #000;
          padding-bottom: 2rem;
          font-size: 3.5rem;
          font-weight: 700;
        }
        .h4{
          color: #000;
          font-weight: 600;
          font-size: 1.8rem;
        }
        hr{
          border: 2px solid #000;
          width: 15%;
          float: left;
        }
      }
    }
    .utest-icon-container {
      display: table-row;
      text-align: center;
      height: 28px;
      #side-banner-utest-icon{
        display: table-cell;
        width: 100px;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
  @media only screen and (max-width: 991px) {
    .utest-icon-container {
      display: block;
      text-align: center;
      height: 28px;
      #subform-utest-icon{
        display: table-cell;
        width: 100px;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 20px;
      }
    }
  }
  @media only screen and (max-width: 767px) {
    .container-fluid{
      padding: 20px;
    }
  }
  .sign-up-form-container .user-form .form-group .strength-meter ~ .validation-icon {
      bottom: 40px;
  }
  .grey-text{
    color: $gray-700;
  }
  .container-fluid{
    .sign-in-box{
      margin-top: 28px;
      margin-bottom: 75px;
      .sign-in{
        span{
          color: $gray-700;
        }
        a{
          color: #000;
          text-decoration: underline;
        }
        @include bp-phone{
          padding: 0;
        }
      }
    }
  }
  .sign-up-form-container .signup-consent {
    width: 90%;
    margin-bottom: 30px;
  }
  .submit-button {
    margin-top: 10px;
  }
  .user-form {
    &.invalid {
      select.ng-invalid:not(.ng-pending) {
        border-color: $brand-error;
      }
    }
    select {
      padding-left: 5px !important;
      height: 43px;
      background-color: $white;
      border-radius: .5px;
      border-color: #ccc;
      color: #262626;
      box-shadow: none;
      width: -moz-available;
      width: -webkit-fill-available;
      &.ng-pristine {
        color: #75767A;
      }
      &:focus {
        outline: none;
        background: #eeeeee;
      }
    }
    a.underlined-on-focus-only {
      text-decoration: none;
      &:focus {
        text-decoration: underline;
      }
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
