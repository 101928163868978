.lp {

	.main-ui-view {
		width: 100% !important;
		margin-top: 0;
	}

	.inline-logo {
		display: inline-block;
	    max-width: 7rem;
	    margin: 0.2rem 0 0 .4rem;
	    vertical-align: top;
	}

	.section-home-cover {
		padding: 7rem 0;
    position: relative;
	}

	.grid {
		height: auto;
	}

	.section-home-cover-random iframe {
		margin: 0 auto;
		display: block;
	}

}


.lp-paid-testing-japan,
.lp-paid-testing-france {
	input[type="checkbox"] {
	  float: left;
	  width: auto;
	}
	ul {
	  margin-left: 40px;
	}
}

.lp-tester-monials {
	.testermonialList {
	  padding: 10px
	}
	.testermonialItem {
	  padding: 10px;
	  height: 264px;
	}
	.testermonialItem p, .testermonialItem h3 {
	  color: #ffffff;
	  margin: 0.6rem;
	}
	.testermonialItem p {
	  text-transform: uppercase;
	}
	.testermonialBox {
	  background-color: #001e23;
	  color: #fff;
	  text-align: center;
	  cursor: pointer;
	  height: 244px;
	  padding: 90px 10px 50px;
	  overflow: hidden
	}
	.videoDiv {
	  opacity: 0;
	}
	@media handheld, only screen and (max-width:767px), only screen and (max-device-width:767px) and (orientation:portrait) {
		.testermonialItem {
		  width: 100%
		}
	}
}

.lp-welcome-testers-sweden,
.lp-welcome-testers-france {
	input[type="checkbox"] {
	  float: left;
	  margin-right: 10px;
	  width: auto;
	}
	ul ul {
	  margin-left: 40px;
	}
}

.hall-of-fame {
  background: url(/static/assets/images/party-concert-fan.jpg) no-repeat bottom 40rem center fixed;
  background-size: cover;
  text-align: center;
  .inner {
    padding:6rem 0;
    background:rgba(0,0,0,.3);
  }
  select {
    background:none;
    border:.1rem solid #fff;
    color:#fff;
    font-size: 1.2rem;
    margin:0 .5rem;
    text-transform: capitalize;
    option {
      color:#262626;
    }
    &:focus {
      outline: none;
      color:#fff;
      border-color:#fff;
    }
  }
  h1 {
    font-size: 2rem;
    vertical-align: middle;
    color:#ffffff;
    img {
      display:inline-block;
      vertical-align: middle;
      margin:0;
      margin:-0.4rem 0.5rem 0 0;
    }
  }
  h2 {
    padding-top: 8rem;
    margin-bottom: 3rem;
    color:#2d3234;
  }
}

.hall-of-fame-content {
  text-align: center;
  li {
    width:22rem;
    display:inline-block;
    margin:1.2rem;
    vertical-align: top;
  }
  nav {
    width:100%;
    padding: 6rem 0;
    a.up, a.down {
      display:inline-block;
      width:6.5rem;
      height:6.5rem;
      border-radius: 50%;
      margin:0 1.3rem;
      background:transparent url('/static/assets/images/icon-arrow-circle-down.png') center center no-repeat;
      background-size:contain;
      &.up {
        background-image:url('/static/assets/images/icon-arrow-circle.png');
      }
    }
    a {
      vertical-align: middle;
      color:#000;
      font-weight: 600;
    }
  }
  @media only screen and (max-width: 73.2rem) {
    h2 {
      padding-top: 3rem;
    }
  }
  @include bp-phone {
    nav {
      a {
        display:none;
        &.up, &.down {
          display:inline-block;
        }
      }
    }
  }
}

.user-card {
  position: relative;
  &-content {
    border:1px solid #d2d6d9;
    border-top:0;
    padding-bottom: 4rem;
    border-radius: 0 0 1.5rem 1.5rem;
  }
  &-avatar {
    position: absolute;
    top:3rem;
    left:50%;
    transform: translateX(-50%);
  }
  .user-card-cover {
    background-color:#d2d6d9;
    border-radius:1.5rem 1.5rem 0 0;
    height:10rem;
    padding:1rem;
    font-weight: bold;
    text-align: left;
    line-height: 1.1em;
    font-size: 1rem;
    color:#ffffff;
    text-shadow: 1px 1px 1px rgba(0,0,0,.1);
    span {
      text-shadow:none;
      color:#fa8513;
      display:block;
      font-size: 130%;
    }
  }
  h4 {
    margin:0;
    padding: 3rem 0.5rem 0;
    line-height: 1em;
    a, span {
      color:#00a8cc;
      font-weight: bold;
      text-transform: uppercase;
      font-size: 1.7rem;
    }
    & + p {
      margin-top: 0;
      strong {
        display:block;
        color:#7b8a97;
      }
    }
  }
}

.lp-row-h {
  text-align: center;
  text-transform: uppercase;
  position: absolute;
  bottom:0;
  width:100%;
  p {
    margin: 0 0 0.5rem 0;
    font-size: 1.4rem;
    color:#ffffff;
  }
}

.lp-img-row {
  background:#000002;
  margin-top: -.5rem;
  ul {
    width:100%;
    padding:0;
    margin:0;
    list-style-type:none;
    overflow: hidden;
    text-align:justify;
    margin-bottom:-1.9rem;
  }
  li {
    display:inline;
    vertical-align: middle;
    &.break {
      margin-left:100%;
    }
  }
  img {
    height:2.6rem;
    width:auto;
    margin: 0;
    display:inline;
  }
  @include bp-phone {
    ul {
      text-align: center;
    }
    img {
      height:2.2rem;
      margin: 1.5rem;
    }
  }
}

.lp-header {
  display:none;
  img {
    height:3rem;
    margin-top: 2rem;
  }
}
//hide normal navigation
.lp {
  .wrapper-all {
    transform: none !important;
    width: 100% !important;
  }
  .lp-hide,
  .ut-hamburger,
  .ut-aside {
    display:none;
  }
  .lp-header {
    display:block;
  }
}
