
.dynamic-tooltip {
  position: absolute;
  top:100%;
  left:0;
  z-index: 99999;
  font-family: "proxima-nova", sans-serif;
  opacity:0;
  transition: opacity ease-in 100ms;
  &.dynamic-tooltip-position-bottom {
    opacity:1;
  }
  &.dynamic-tooltip-position-top {
    opacity:1;
    top:auto;
    bottom:100%;
  }

  .community-follow-btn {
    float: right;
  }
}


@keyframes userTooltipWidthIn {
  0% {
    max-width: 0;
    min-width: 0;
  }
  100% {
    min-width: 18rem;
    max-width: 40rem;
  }
}

@keyframes userTooltipContentSet {
  0% {
    max-height:0;
    opacity:0;
  }
  10% {
    max-height:0;
    opacity:0;
  }
  100% {
    max-height:100rem;
    opacity:0;
  }
}

@keyframes userTooltipContentGo {
  0% {
    margin-top:-0.5rem;
    opacity:0;
  }
  100% {
    margin-top:0;
    opacity:1;
  }
}

.user-tooltip {
  padding:1rem 0 1rem;
  &-caret {
    margin-top: 1rem;
    border-left: .6rem solid transparent;
    border-right: .6rem solid transparent;
    border-bottom: .4rem solid #3c4042;
    position: absolute;
    bottom:100%;
    left:50%;
    margin-left: -.3rem;
    .dynamic-tooltip-position-top & {
      border-left: .6rem solid transparent;
      border-right: .6rem solid transparent;
      border-top: .4rem solid #3c4042;
      border-bottom:0;
      bottom:auto;
      margin-top:0;
      margin-bottom:1rem;
      top:100%;
    }
  }
  &-inner {
    position: relative;
    border-radius: .5rem;
    box-shadow: 0 0 1.2rem 0 rgba(0, 0, 0, 0.5);
    .profile-thumb {
      margin: 0 auto 0 auto;
      position: relative;
      border:.1rem solid white;
      width: 5.8rem !important;
      height: 5.8rem !important;
    }
    &-1 {
      position: absolute;
      left:0;
      top:0;
      bottom:0;
      padding:1.8rem 1rem;
      background:#3c4042;
      text-align: center;
      border-radius: .3rem 0 0 .3rem;
      width:9.8rem;
      p {
        font-weight: 800;
        font-size: 2rem !important;
        line-height: 1em;
        color:#ffffff;
        margin:0;
        padding-top: .5rem;
        small {
          color:#ffffff;
          font-weight: 600;
          font-size: .9rem;
          line-height: 1em;
          display:block;
        }
      }
      .user-tooltip-info {
        font-size:0.9rem !important;
        font-weight:normal;
        padding: .5rem 0 0 0;
        .icon-check {
          color:$brand-success;
        }
      }
    }
    &-2 {
      padding: 2rem 1.4rem 0.6rem;
      margin-left:9.7rem;
      background:#262626;
      min-width: 18rem;
      border-radius: 0 .3rem .3rem 0;
      min-height:14.3rem;
      overflow:hidden;
      max-width:0;
      min-width:0;
      @include animation(userTooltipWidthIn ease-in 200ms 1 forwards);
      p {
        margin: 0  0 1rem 0;
        color:#ffffff;
        font-size: 1.2rem;
        line-height:1.7rem;
        a, i {
          color:#8f8f8f;
          font-size: 1.2rem;
          padding:0;
        }
        &:only-of-type {
          margin-bottom:2rem;
        }
      }
      &-content {
        max-height:0;
        overflow: hidden;
        animation: userTooltipContentSet 0ms 200ms 1 forwards, userTooltipContentGo 300ms 200ms 1 forwards;
      }
      .user-slug {
        a {
          color: $light-blue;
        }
      }
    }
  }
  h6, h6 a, .tooltip-user-name {
    color:#ffffff;
    text-transform: none;
    font-size:1.5rem;
    margin:0;
    letter-spacing: normal;
    border:0;
    padding-bottom:0;
  }
  .checkbox-toggle {
    margin:0;
  }
  .cus-checkbox-follow {
    margin-top: 14px;
    line-height:2.1rem;
  }
}

.feed-item-info, .feed-header-info {
  .user-tooltip {
    padding-left: 4.3rem;
  }
  .feed-header-info-name[dynamic-tooltip] .user-tooltip {
    padding-left:0;
  }
}

.sidePanelWrapper {
  .dynamic-tooltip {
    left:-3rem;
  }
  .user-tooltip-caret {
    margin-left:2rem;
  }
}
