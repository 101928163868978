$small-max-width: 769px;

.unauth-section-history {
  .unauth-section-margin {
    margin-top: 0;
    padding: 0;
    .scrollable-header {
      height: 450px;
      box-shadow: 8px 0 8px -8px rgba(0,0,0,0.3);
      padding: 75px 25px 150px 25px;
      @media (max-width: $small-max-width) {
        height: 200px;
        box-shadow: 0 8px 8px -8px rgba(0, 0, 0, 0.3);
        padding: 25px;
      }
      h2 {
        font-size: 31px;
        font-weight: bold;
        letter-spacing: 0.4px;
        line-height: 34px;
      }
      p {
        font-size: 16px;
        letter-spacing: 0.2px;
        line-height: 19px;
      }
    }
  }
  ul {
    display: inline-flex;
    padding: 50px 50px 0 50px;
    @media (max-width: $small-max-width) {
      padding: 50px 50px 0 70px;
    }
    li {
      overflow: visible;
      position: relative;
      height: 400px;
      width: 250px;
      text-decoration: none;
      color: #262626;
      display: block;
      text-align: center;
      padding: 16px 0;
      h2 {
        margin-top: 0;
        font-size: 16px;
        font-weight: 600;
        letter-spacing: 0.2px;
        line-height: 19px;
        text-align: center;
      }
      p {
        width: 350px;
        position: relative;
        left: -50px;
        overflow: visible;
        font-size: 14px;
        letter-spacing: 0.2px;
        line-height: 16px;
        text-align: center;
        hyphens: none;
      }
      .year {
        background-color: #00A8CC;
        color: $white;
        border-radius: 50%;
        padding: 7px;
        width: 33px;
        height: 33px;
        position: relative;
        z-index: 2;
        display: block;
        margin: auto;
        font-size: 12px;
        font-weight: 600;
        letter-spacing: 0.2px;
      }
      .line {
        position: relative;
        z-index: 1;
        top: -17px;
        left: -75px;
        overflow: visible;
        &:before {
          display: block;
          content: '';
          border-top: 1px solid #ac9b9b;
          width: 400px;
        }
      }
      .info-container {
        height: 150px;
        width: auto;
        overflow: visible;
        padding-top: 25px;
      }
    }
  }
}
.history-container {
  padding-top: 55px;
}