input.ng-touched.ng-invalid {
	border-color: $brand-error;
}

.input-w-icon {
	.glyphicon {
	    @extend .icon-check;
    	position: absolute;
    	bottom: 1rem;
    	right: 2rem;
    	top:auto;
    	line-height: inherit;
    	color: $green-base;
  	}
  	input.ng-untouched + .glyphicon {
  		display:none;
  	}
  	input.ng-touched + .glyphicon {
  		display:block;
  	}
	input.ng-touched.ng-invalid + .glyphicon {
		@extend .icon-cancel;
		color: $brand-error;
	}
}


.input-row-invisible {
  margin: 2rem 0;
  border: 1px transparent solid;
  border-radius: 5px;
  input, textarea {
    color:#262626;
    &:focus {
      background:none;
    }
  }
  input {
    font-size: 2.6rem;
    font-weight: 800;
  }
  textarea {
    font-size: 1.6rem;
    padding: 1.5rem;
    height: 25rem;
    resize:none;
  }
  &.error {
    border: 1px #ef5454 solid;
  }
}

/* Dante fix */
.dante-wrapper {
  padding: 0rem 7rem;
  max-width: 89rem + 14rem;
  margin:0 auto;
  > article {
    padding:3rem 1rem 1rem 1rem;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  &:hover {
    border-color: #d2d6d9;
  }
  &.error {
    border: 1px #ef5454 solid;
  }
}

.dante-menu-input:focus {
  background:none;
}
/* select 2 overwrites */
.ui-select-bootstrap .ui-select-choices-row.active > span {
	background: $blue;
}

.ui-select-match.btn-default-focus {
  box-shadow: none;
}

.ui-select-multiple {
  .ui-select-search {
    padding:0 1rem;
    height:2.7rem !important;
    margin-bottom: 0 !important;
  }
}

.select2-theme-form {
  .ui-select-search,
  .btn.btn-default {
    border-color:#ccc;
    border-radius: 0.5rem;
    font-weight: normal;
    color:rgb(51,51,51);
    padding:1rem;
    height:auto;
    line-height: 2.1rem;
    box-shadow: none;
  }
  .btn.btn-xs {
    border:0;
    padding: 0 .2rem;
    margin-top: -.1rem;
    color:#262626;
    &:hover {
      color:#00a8cc;
    }
  }

  // default font-size is 1.4rem (~14px), which is too small
  // for WCAG 2.2 Checkpoint: 2.5.8 Target Size (Minimum)	
  i.glyphicon.glyphicon-remove {
    font-size: 2.4rem;
  }
}

.select2-theme-minimal {
  display:inline-block;
  min-width:13.5rem;
  min-height:3rem;
  .btn.btn-default {
    border:none;
    padding-right:2.5rem;
    color:$gray-base;
    font-size: 1.2rem;
    text-align: center !important;
    .pull-left {
      float:none !important;
    }
  }
  .dropdown-menu {
    box-shadow: none;
    padding:0;
    border:none;
  }
  .ui-select-choices-row > a {
    padding:.3rem 2.5rem .3rem .7rem;
    font-size: 1.2rem;
    color:$gray-base;
    text-align: center;
    font-weight:600;
  }
  .ui-select-choices-row > a:hover {
    background:none;
  }
  .ui-select-choices-row.active {
    display:none;
  }
  .ui-select-choices-row:hover,
  .btn-default-focus,
  .btn-default:focus,
  .btn-default:hover {
    background:none;
  }
  .form-control {
    height:auto;
    box-shadow: none;
  }
  .ui-select-match,
  .ui-select-match.ng-hide {
    display:block !important;
  }
}

.btn.btn-filter {
  padding: 0.6rem 0.9rem 0.6rem 0.9rem;
  font-size: 1.2rem;
  font-weight: 600;
  color:#262626;
  letter-spacing: 0.4px;
  min-width:0;
  line-height:1.8rem;
  text-transform: none;
  margin-bottom:1rem;
}

.dropzone.dropzone-btn {
  min-height:0;
  text-align: center;
  padding:0;
	transition: none;
  .dz-default.dz-message {
    border:1px solid $blue;
    color:$blue;
    font-size:1.4rem;
    line-height: 3rem;
    padding:0 2rem;
    border-radius: 50px;
    transition: all 300ms;
    margin:0 0rem 2rem;
    display:inline-block;
  }
  .dz-drag-hover {
    border-style:none;
  }
  &:hover,&:focus {
		.dz-default {
			color:$white;
			background: $blue;
		}
	}
}

.dropzone.dz-clickable {
	.dz-default.dz-message {
		span {
			color: $gray-dark;
		}
	}
}

@mixin dropzone-style {
  border:1px solid $gray-light;
  background:$gray-lightest;
  border-radius:.1rem;
  &:hover {
    color:$brand-primary;
    border-color: $brand-primary;
  }
}

.dropzone.form-control {
  @include dropzone-style;
  box-shadow: none;
}

.dropzone.dropzone-icon {
  @include dropzone-style;
  margin:1rem 1.5rem 0 0;
  width:auto;
  height:auto;
  padding:0;
  min-height:12.7rem;
}

.slug-input {
  margin-top:1rem;
  margin-bottom: 2rem;
  .alert {
    margin-bottom:2rem;
  }
  .input-group-addon {
    padding-left:0;
    padding-right:0;
    padding-bottom:.4rem;
    border:0;
    background:none;
    color:$gray-cc-dark;
  }
  .input-group:hover {
    .form-control {
      text-decoration: underline;
    }
  }
  .form-control {
    padding-left:0;
    border-color:transparent;
    box-shadow: none;
    cursor:pointer;
    border-radius:0;
  }
  &.has-error {
    .form-control {
      color:$brand-danger;
    }
  }
  &.slug-input-edit {
    .input-group:hover {
      .form-control {
        text-decoration: none;
      }
    }
    .input-group:hover {
      border-color:transparent;
    }
    .input-group-addon {
      color:$gray-cc-dark;
      padding-right:.5rem;
    }
    .form-control + .input-group-addon {
      padding-left:.5rem;
    }
    .form-control {
      padding-left:1rem;
      cursor: auto;
      border-color:$gray;
      border-radius:.3rem !important;
      color:$gray-base;
      &:focus {
        background:#fff;
      }
    }
    &.has-error {
      .form-control {
        border-color:$brand-danger;
        color:$gray-base;
      }
    }
  }
}

.instruction-message {
  a {
    color: #006FDF;
  }
}

.autosave-status {
  text-align: right;
  position:relative;
  overflow:hidden;
  height:2.4rem;
  color:$gray;
  margin-top: 1rem;
  > span {
    float:right;
    width:12.5rem;
    height:1rem;
  }
  small {
    font-size: 1.1rem;
    position:absolute;
    top:0;
    right:0;
    bottom:0;
    &.ng-animate {
      transition:all ease-in-out 0.5s;
      opacity:1;
    }
    &.ng-leave.ng-leave-active {
      top:-5rem;
      opacity:0;
    }
    &.ng-enter {
      top:+5rem;
    }
    &.ng-leave,
    &.ng-enter.ng-enter-active {
      top:0;
      opacity:1;
    }
  }
}

.sign-up-embed {
  text-align:center;
  background:rgba(60, 64, 66, 0.95);
  padding-bottom:30px;
  height:100vh;
  overflow:auto;
  color:white;
  h1, .overlay-small-type, p {
    color:#fff;
  }
}

.master-checkbox {
  display:block;
  border-color:$gray;
  background:$gray-lighter;
  font-weight: normal;
  font-size:1.8rem;
  cursor:pointer;
  .cus-checkbox.inline {
    margin-top: -.1rem;
    padding-right: 2.5rem;
  }
}

.checkbox-form-field {
  padding-left:25px;
  position: relative;
  float:left;
  width:100%;
  margin-bottom:10px;
  .cus-checkbox {
    position: absolute;
    left:0;
    top:-2px;
  }
}

.form-hero {
  padding:3rem 0;
  label {
    color:#fff;
  }
  .input-group {
    margin-bottom:2rem;
    width:100%;
  }
  input {
    background:rgba(255,255,255,.9);
    border:none;
  }
}
.clear{
  clear: both;
}
.input-check-box{
  display: block;
  position: relative;
  padding-left: 30px;
  margin: 10px 0 12px 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-weight: 500 !important;
  a{
    color: #000;
    text-decoration: underline;
    text-decoration-color: #888;
  }
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    &:focus ~ .checkmark {
			border-color: #A1A2A4;
      outline: auto 2px;
    }
  }
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: $white;
    border: 1px solid #ccc;
    border-radius: 3px;
  }
  &:hover input ~ .checkmark {
    border-color: #A1A2A4;
  }
  input:checked ~ .checkmark {
    background-color: $blue;
    border: none !important;
  }
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  input:checked ~ .checkmark:after {
    display: block;
  }
  .checkmark:after {
    left: 7px;
    top: 3px;
    width: 6px;
    height: 12px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  .error-msg{
    margin-left: -35px;
    margin-top: -3px;
  }
}
@media only screen and (max-width: $screen-xs) {
  .input-check-box{
    margin: 25px 0;
  }
}

.ui-select-placeholder.text-muted {
  color: #333;
}
