.pagination > .active > a {
	background:#00819E;
	border-color:#00819E;
	&:hover, &:focus {
		background:#00819E;
		border-color:#00819E;
	}
}
.pagination > li > a {
	color:#00819E;
	&:hover, &:focus {
		color:#1372AA;
	}
}
