activity-list-item:last-of-type .activity-list-item {
  border-bottom: 1px solid $gray-lighter;
}

.activity-list-item {
  display: flex;
  flex-wrap: nowrap;
  border-top: 1px solid $gray-lighter;
  padding-top: 10px;
  padding-bottom: 10px;

  .activity-list-item__icon {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    flex-shrink: 0;
  }

  .activity-list-item__icon--glyph {
    color: $icon-color;
    background-color: $gray-lighter;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .activity-list-item__icon--red {
    border: 2px solid $icon-border-red;
  }

  .activity-list-item__icon--yellow {
    border: 2px solid $icon-border-yellow;
  }

  .activity-list-item__icon--green {
    border: 2px solid $icon-border-green;
  }

  .activity-list-item__description {
    margin-left: 10px;
    flex-grow: 1;
    align-self: center;
  }

  .activity-list-item__time {
    align-self: center;
    flex-basis: 30%;
    flex-shrink: 0;
    margin-left: 10px;
    text-align: right;
  }
}

.activity-feed-header {
  display: flex;
  justify-content: space-between;
}

.ntux-load-more-button {
  @extend %ntux-button;
  display: block;
}

.selected-comment {
  border-left: 5px #027CC2 solid !important;
}
