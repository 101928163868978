.progress-info {
  padding:.4rem 0;
  .desc {
    color: #7b8a97;
    font-size:1.2rem;
    margin-bottom:.5rem;
  }
  .bar-container {
    width:90%;
    height:5px;
    background:#eaedef;
    border-radius:2px;
    position:relative;
    .bar {
      content:'';
      width: 60%;
      height:100%;
      border-radius:2px;
      background:#22c064;
      display: block;
    }
  }
  &.small {
    width:15rem;
    margin:0 auto;
    .desc {
      margin-top:.5rem;
      text-transform: uppercase;
    }
  }
}