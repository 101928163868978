//my & their profile cover
.reverse-follow {
  position: absolute;
  padding: 1.5rem 0 1rem;
  font-size: 1rem;
  font-weight: 600;
  color:rgba(255,255,255,.7);
  text-transform:uppercase;
  left: 0;
  right: 0;
  top: .5rem;
  margin: 0 auto;
  &:before {
    content: '\e806';
    font-family: utestfont;
    font-size: 1.3rem;
    color: #22c064;
    vertical-align: middle;
    position:relative;
    top:-.1rem;
    margin-right: .2rem;
  }
}
.profile-cover {
  width:100%;
  position:relative;
  text-align:center;
  &:before {
    content:'';
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, .8);
    display: block;
    position:absolute;
    top:0;
  }
  .profile-cover-content {
    position:relative;
    z-index:1;
    padding: 5rem 0;
    .cover-content-btn {
      width:3.5rem;
      height:3.5rem;
      border-radius:50%;
      display: inline-block;
      vertical-align: top;
      margin-top:3rem;
      cursor: pointer;
      color:#fff;
      background-color: $blue;
      &.idle {
        opacity: 0.25;
        cursor: auto;
        box-shadow: none;
        &:hover {
          background: #00a8cc;
        }
      }
      &.icon-at {
        border: none;
        i {
          font-size: 2rem;
          line-height: 3.2rem;
        }
        &:hover, &:focus {
          background-color: $blue;
          box-shadow: inset 0 0 0 0.1rem #ffffff;
        }
      }
      &.spacer {
        background-color: transparent;
        cursor: auto;
      }
    }
    .cover-content-name {
      color:#fff;
      font-weight: 600;
      margin: 1.3rem 0 -.2rem;
    }
    .cover-content-title {
      color:#bac1c6;
      font-weight: 600;
      margin: .5rem 0 -.2rem;
    }
    .cover-content-desc {
      color: #bac1c6;
      font-size: 1.4rem;
      padding-bottom:1.5rem;
      .icon-location:before {
        margin-left: 0;
      }
    }
    .cover-content-social {
      margin-top:.8rem;
      color: rgba(255, 255, 255, 1);
      font-size: 1.2rem;
      line-height: 1.4em;
      * {
        font-size: inherit;
        line-height: inherit;
      }
      a {
        color: rgba(255, 255, 255, 1);
        &:hover {
          color: rgba(255, 255, 255, 0.6);
        }
        &:last-child:before {
          margin-right: 0;
        }
      }
      .icon-twitter-material, .icon-linkedin-material {
        &:before {
          color: white;
        }
      }
      .icon-twitter-material {
        &:hover:before, &:focus:before {
          color: $twitter-main;
        }
      }
      .icon-linkedin-material {
        &:hover:before, &:focus:before {
          color: $linkedin-main;
        }
      }
    }
    .cover-content-referral {
      margin-top:.8rem;
      color: rgba(255, 255, 255, 1);
      font-size: 1.2rem;
      line-height: 1.4em;
      * {
        font-size: inherit;
        line-height: inherit;
      }
      a {
        &:hover {
          color: $lightblue;
        }
      }
    }
    .cus-checkbox-follow {
      margin-top: 2.4rem;
    }
    .mid {
      @extend %ntux-button;
      @extend %ntux-hollow-button-on-white;
      width:12rem;
      min-width:12rem;
      min-height: 3rem;
      padding:.3rem;
      display: inline-block;
      border-radius:3rem;
      text-transform: capitalize;
      font-size: 1.2rem;
      letter-spacing: 0;
      opacity: 1;
      vertical-align: top;
      margin-top: 2.4rem;
    }
  }
  .profile-cover-figures {
    width:100%;
    height: 5.9rem;
    position:relative;
    background: rgba(0,0,0, .67);
    padding: 1.5rem 0;

    .urank,
    .points {
      color: #fdb900;
      &:hover {
        color: #fdb900 !important;
      }
    }
    .followers, .following {
      color: #00a8cc;
    }
    a {
      font-size: 1.8rem;
      font-weight: 800;
      width: 18rem;
      text-align: center;
      display: inline-block;
      position:relative;
      line-height: 1;
      &:hover {
        color: #fff;
      }
      @media(max-width: $screen-lg) {
        width: auto;
        padding: 0 5%;
      }
      &:after {
        font-size:1rem;
        font-weight: 600;
        color: rgba(191, 197, 202, .8);
        bottom: 0;
        display: block;
        line-height: 1.4;
      }
      small {
        font-weight: 600;
        color: rgba(191, 197, 202, .8);
      }
    }
    .urank {
      border-left: 1px #bfc5ca solid;
      &:after {
        content:'uPoints Ranking';
      }
    }
    .points {
      &:after {
        content:'uPOINTS';
      }
    }
    .followers {
      border-left: 1px #bfc5ca solid;
      border-right: 1px #bfc5ca solid;
      &:after {
        content:'FOLLOWERS';
      }
    }
    .following {
      &:after {
        content:'FOLLOWING';
      }
    }

    @media(max-width: $screen-xs-max) {
      height:auto;
      padding:0;
      .container {
        padding:0;
      }
      a {
        float:left;
        width:50%;
        padding:1.5rem 0;
        &:first-child, &:nth-child(2) {
          border:none;
          border-bottom:1px solid #bfc5ca;
        }
        &:nth-child(2n+1) {
          border-left:0;
          border-right:1px solid #bfc5ca;
        }
      }
    }
  }
  .profile-setting {
    width: 12rem;
    min-width: 12rem;
    min-height: 3rem;
  }
}

//profile about page
.profile-about {
  padding: 0 3rem;
  h4 {
    font-weight: 600;
    margin: 2rem 0;
  }
  .about {
    p {
      font-size: 1.4rem;
      line-height: 1.6;
    }
  }
}
.total-point {
  text-align:center;
  font-weight: 800;
  font-size: 5rem;
  margin-top: 3rem;
  &:after {
    content: "Total uPoints";
    font-size:1.6rem;
    font-weight: 600;
    color: #262626;
    display: block;
    margin-top: -1.4rem;
  }
}
.social-badges, .behavior-badges {
  .social-badges-title, .behavior-badges-title {
    text-align: center;

    margin: 3rem auto;
    position: relative;
    &:before, &:after {
      content:'';
      border-top: 1px #ccc solid;
      display: inline-block;
      vertical-align: middle;
      width: 9rem;;
    }
    &:before {margin-right: 1.5rem;}
    &:after {margin-left: 1.5rem;}
  }
  .badge-item {
    text-align: center;
    margin: 2rem 0 3rem;
    .badge-img {
      width:11rem;
      height: 11rem;
      border-radius:50%;
      margin: 0 auto;
    }
    .badge-item-title {
      font-weight: 600;
      margin: 1.5rem 0 0;
      font-size: 1.5rem;
    }
    .badge-item-point {
      font-size: 1.2rem;
    }
  }
}
.behavior-badges .badge-item .badge-img:after {
  display: none !important;
}

.user-list {
  article{
    border-top: 1px #d2d6d9 solid;
    padding: 1.5rem;
    text-align: left;
    &:last-child {
      border-bottom: 1px #d2d6d9 solid;
    }
    .profile-thumb, h5 {
      display: inline-block;
      vertical-align: middle;
      line-height: 1.2em;
    }
    h5 small {
      display:block;
      font-weight: normal;
      color: #7b8a97;
      font-size:90%;
    }
    .cus-checkbox-follow {
      float:right;
      position: relative;
      top: 12px;
    }
    .community-follow-btn {
      float: right;
    }
    .checkbox-toggle:before {
      position: relative;
    }
    .profile-thumb {
      margin-right: 1.5rem;
    }
  }
}

.overlay .user-list {

  a {
    color:#ffffff;
  }

  h5 small {
    color:#bac1c6;
  }

  article {
    border-color: rgba(210, 214, 217, 0.5);
  }

}
div.profile-overlay {
  .overlay {
    overflow-y: hidden;
  }
}


.stat-item {
  font-size: 4rem;
  color:$gray-base;
  font-weight: 300;
  text-align: center;
  display:inline-block;
  padding:3rem 0;
  small {
    display:block;
    font-size: 1.2rem;
    color: #737678;
    font-weight: 600;
  }
}

@keyframes statItemLegendAnimateIn {
  from {
    width:0;
  }
  to {
    width:2.9rem;
  }
}

$stat-colors: (status_count: #00A8CC, article_count: #FDB900, post_count: #18A78B, comment_count: #FF017E);

.stat-item-legend {
  &:after {
    content:'';
    display:block;
    position: relative;
    height:.4rem;
    width:0;
    background:orange;
    margin:1.5rem auto 0;
    animation: statItemLegendAnimateIn ease-in 300ms 500ms forwards;
  }
  @each $current-color-key, $current-color in $stat-colors {
    &--type-#{$current-color-key}:after {
      background:$current-color;
    }
  }
}

.stat-items-grid {
  font-size: 0;
  .stat-item {
    width:50%;
    @media(max-width:$screen-sm) and (min-width: $screen-xs) {
      width:25%;
    }
  }
}

.stat-items-row {
  text-align: center;
  .stat-item {
    padding-left:2rem;
    padding-right: 2rem;
  }
}

.stat-date-section {
  padding: 6rem 0;
  margin:0 -4rem;
  text-align: center;
  span {
    top: 10px;
    position: relative;
    white-space: nowrap;
  }
}

.stat-date {
  display:inline-block;
  position: relative;
  height:15rem;
  width:15rem;
  margin:0 4rem 6rem 4rem;
}

.stat-date_bg {
  fill: transparent;
  position: absolute;
  width:100%;
  height:100%;
  stroke: #7B8A97;
}
.stat-date_label {
  position: absolute;
  top:2.5rem;
  right:60%;
  width:40%;
  text-align: center;
  white-space: nowrap;
  overflow: visible;
  font-size: 1.2rem;
  line-height:2em;
  font-weight: 600;
  background:white;
}
.stat-date_month {
  position: absolute;
  top:50%;
  left:50%;
  transform:translate(-50%, -50%);
  font-size:2rem;
  font-weight: 300;
  color:#7B8A97;
  text-transform: uppercase;
}
.stat-date_year {
  position: absolute;
  right:55%;
  bottom:0rem;
  font-size: 1.4rem;
  line-height:2.5em;
  color:#7B8A97;
  background:white;
  transform:rotate(-90deg);
}
.stat-date_day {
  position: absolute;
  top:50%;
  left:47%;
  font-size:10rem;
  line-height:1em;
  font-weight:800;
}

$stat-date-colors: #00A8CC #FDB900 #18A78B #FF017E;

.stat-date {
  @each $current-color in $stat-date-colors {
    $i: index($stat-date-colors, $current-color) - 1;
    &--#{$i} {
      .stat-date_day,
      .stat-date_label {
        color:$current-color;
      }
    }
  }
}

.stat-line-chart {
  padding-top:3rem;
  vertical-align: bottom;
  @media(max-width: $screen-sm) {
    width:50%;
    margin:0 auto;
  }
  @media(max-width: $screen-xs-max) {
    width:80%;
  }
}
.stat-line-chart_value {
  width:.4rem;
  background:$gray;
  margin: 0 auto;
  height: 0;
  transition:height ease-in-out 600ms;
  @each $current-color-key, $current-color in $stat-colors {
    &--type-#{$current-color-key} {
      background:$current-color;
    }
  }
}
.stat-line-chart_item {
  height:25rem;
  position: relative;
}
.stat-line-chart_item_inner {
  position: absolute;
  bottom:0;
  left:50%;
  margin-left:-.2rem;
  height: 0;
  transition: height ease-in-out 1s;
}
.stat-line-chart_item--12 {
  display:inline-block;
  width: 8.33%;
}
.stat-line-chart_label {
  font-size: 1rem;
  text-align: center;
  padding-top: 1rem;
}

.stats-tab {
  padding:0 7rem;
  hr {
    border-color:#D2D6D9;
    width:60%;
    clear: both;
  }
  @media (max-width: $screen-sm) {
    padding:0;
  }
    h2 {
      font-family: "proxima-nova", sans-serif;
      font-weight: 200 !important;
    }
}
.platform-badges-section {
  padding:4.5rem 0;
}
.platform-badges-item {
  display:inline-block;
  position: relative;
  font-size: 0;
  padding:2.5rem 1rem 0 0;
  position: relative;
  img {
    width:8.5rem;
  }
  .platform-badges-item_top {
    position: absolute;
    top:0;
    right:0;
    width:4rem;
  }
  .platform-badges-item__details {
    font-size: 14px;
    display: grid;
    justify-content: center;
    .platform-badges-item__details-rating {
      text-transform: uppercase;
      word-break: normal;
      max-width: 70px;
    }
  }
}
.platform-badges-item-block {
  display:block;
  margin:0 auto;
}
.community-follow-btn {
  padding-top: 5px;
}
